import React from 'react';
import { Trans } from '@lingui/react';
import GenericContactForm from './GenericContactForm';

const Service = (props) => {
    return (
        <GenericContactForm
            message={<Trans id={'serviceDescription'} />}
            type={GenericContactForm.TYPE_SERVICE}
            onCloseCallback={props.onCloseCallback}
            inventoryItemId={props.inventoryItemId}
        />
    );
}

export default Service;