import React from 'react';
import * as WebBrowser from 'expo-web-browser';
import Requester from './Requester';

const PdfView = {
    show: (pathToPdf:string = '')  => {
        const path = encodeURI(global.url.backend + pathToPdf);
        console.log('opening path in web mode:', path);

        Requester
            .getBlobFromURL(path)
            .then((c)=> {
                const file    = new Blob([c], { type: 'application/pdf;base64' });
                const fileURL = URL.createObjectURL(file);
                WebBrowser.openBrowserAsync(fileURL);
            });
    }
};

export default PdfView;