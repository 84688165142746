global.url = {
    backend: 'https://backend.meit.de',
    medical: 'https://www.me-spicker.de'
};

global.keepAliveIntervalInSeconds = 50;

global.logging = {
    keepAlive: false,
    appStateChange: false,
    sessionLoader: false,
}
