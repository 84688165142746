import React, {
    useRef,
} from 'react';
import {
    StyleSheet,
    View,
} from 'react-native';
import WebButton from '../ui/WebButton';
import {
    t,
    Trans
} from '@lingui/macro';
import * as Styles from '../styles';
import { Paragraph } from '../components/StyledText';
import EmailInput from '../ui/EmailInput';
import Requester from '../components/Requester';
import Toast from '../interfaces/Toast';
import { useNavigation } from "@react-navigation/native";

const PasswordResetRequestScreen = (props) => {
    const emailInput = useRef();
    const navigation = useNavigation();

    const _sendPasswordResetRequestEmail = async () => {
        let networkError = false;
        const response   = await Requester.post(
            '/user/requestPasswordReset',
            {
                email: emailInput.current.props.value
            },
        ).catch(() => {
            networkError = true;
        });

        if (networkError) {
            Toast.showError(t`error`);
            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);

        Toast.showSuccess(t`passwordResetMailSent`);
        navigation.navigate('LoginScreen');
    }

    async function handleKeyUp(e) {
        e.which = e.which || e.keyCode;

        // If the key press is Enter
        if (e.which === 13) {
            await _sendPasswordResetRequestEmail();
        }
    }

    return (
        <View
            style={[{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignSelf: 'center',
                width: '50%',
            }]}
        >
            <View>
                <Paragraph style={styles.passwordForgotten}><Trans id={'shitHappensKeepCalm'} /></Paragraph>
                <EmailInput
                    ref={emailInput}
                    autoCompleteType={'off'}
                    onKeyPress={handleKeyUp}
                    noLabel
                />
                <View>
                    <WebButton
                        onPress={() => _sendPasswordResetRequestEmail()}
                    >{t`sendPasswordResetRequestEmail`}</WebButton>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    background: {
        ...Styles.Layouts.backgroundImage,
    },
    passwordForgotten: {
        marginBottom: 20,
    }
});

export default PasswordResetRequestScreen;