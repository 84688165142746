export function getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
}

export const debugBorder = {
    borderWidth: 5,
    borderStyle: 'solid',
    borderColor: getRandomColor(),
    backgroundColor: getRandomColor(),
};