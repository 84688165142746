import React, {
    useState,
    useRef,
} from 'react';
import {
    t
} from '@lingui/macro';
import DefaultInput from './DefaultInput';

const PasswordInput = React.forwardRef((
    {
        children,
        nativeID, password, minLength, isNewPassword,
        onChange, onSubmitEditing, onKeyPress, isPasswordHidden,
        noLabel, label, placeholder, errorMessage, mandatory,
        ...props
    },
    ref
) => {
    const [_isPasswordHidden, setPasswordHidden] = useState(isPasswordHidden || true);

    return (
        <DefaultInput
            nativeID={nativeID}
            ref={(ref !== null ? ref : useRef())}
            mandatory={mandatory}
            value={password}
            minLength={minLength}
            errorMessage={errorMessage}
            onChange={onChange}
            onSubmitEditing={onSubmitEditing}
            onKeyPress={onKeyPress}
            placeholder={placeholder ?? t`password`}
            autoCompleteType={!isNewPassword ? 'password' : 'new-password'}
            autoCapitalize={'none'}
            leftIconType='lock'
            {...(!noLabel ? {label: (label ?? t`password`)} : undefined)}
            secureTextEntry={_isPasswordHidden}
            rightIconName={_isPasswordHidden ? 'eye-slash' : 'eye'}
            rightIconAction={() => setPasswordHidden(!_isPasswordHidden)}
        />
    );
});

export default PasswordInput;