import React, {
    useEffect,
    useState,
} from 'react';
import {
    View,
} from 'react-native';
import WebButton from './WebButton';
import NumericInput from './NumericInput';

const TabbableInput = ({
    size,
    disabled, onChange,
    min, max,
    ...props
}) => {
    const [value, setValue]         = useState(props.value);
    const [isPressed, setIsPressed] = useState(false);
    // const [singleChange, setSingleChange] = useState(true);
    const [step, setStep]           = useState(0);

    let onPressedInterval = null;
    let saveDelay         = null;

    if (!isPressed && props.value !== value) {
        // an external change occurred
        setValue(props.value)
    }

    useEffect(
        () => {
            if (isPressed === true) {
                onPressedInterval = setTimeout(
                    () => _changeBy(),
                    100
                );
            } else {
                clearInterval(onPressedInterval);
            }

            return function cleanup() {
                clearInterval(onPressedInterval);
            }
        },
        [isPressed, value]
    );

    const _onBlur = (_value) => {
        setValue(_value);

        if (typeof props.onChangeText === 'function') {
            props.onChangeText(props.item, _value);
        }
    }

    const _changeBy = () => {
        setValue(currentValue => {
            const newValue = parseInt(currentValue) + step;
            return newValue > min ? newValue : min;
        });
    }

    const singleChangeBy = (_step) => {
        clearInterval(saveDelay);

        setValue(currentValue => {
            let newValue = parseInt(currentValue) + _step;
            newValue = newValue > min ? newValue : min;

            if (typeof props.onChangeText === 'function') {
                saveDelay = setTimeout(
                    () => props.onChangeText(props.item, newValue),
                    300
                );
            }

            return newValue;
        });
    }

    const changeBy = (_step) => {
        clearInterval(saveDelay);
        setStep(_step);
        setIsPressed(true);
    }

    const updateValue = () => {
        clearInterval(onPressedInterval);
        setIsPressed(false);

        // @TODO evaluate if true - if needed, add code block. Last tests also worked fine without.
        // most likely the user releases the button when he sees he hit his target. But that would lead to
        // overshooting by 1. So we take back the last change.
        // if (singleChange === false) {
        //     setValue(currentValue => {
        //         const newValue = parseInt(currentValue) - step;
        //         return newValue > min ? newValue : min;
        //     });
        // }

        // setSingleChange(true);

        if (typeof props.onChangeText === 'function') {
            saveDelay = setTimeout(
                () => props.onChangeText(props.item, value),
                300
            );
        }
    }

    return (
        <View style={{
            width: (size === 'auto') ? '100%' : size,
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 2,
        }}>
            <WebButton
                disabled={disabled}
                onPress={() => singleChangeBy(-1)}
                // onLongPress={() => setSingleChange(false)}
                onPressIn={() => changeBy(-1)}
                onPressOut={() => updateValue()}
                size={45}
                height={'small'}
            >-</WebButton>
            <View style={{
                height: 40
            }}>
                <NumericInput
                    editable={!disabled}
                    value={/^(0|[1-9]\d*)$/.test(value) ? value.toString() : ''}
                    min={min}
                    max={max}
                    onBlur={_onBlur}
                    inputContainerStyle={[
                        props.inputContainerStyle,
                        {
                            marginVertical: 0,
                            borderBottomWidth: 2,
                        }
                    ]}
                    inputStyle={[
                        props.inputStyle,
                    ]}
                />
            </View>
            <WebButton
                disabled={disabled}
                onPress={() => singleChangeBy(1)}
                // onLongPress={() => setSingleChange(false)}
                onPressIn={() => changeBy(1)}
                onPressOut={() => updateValue()}
                size={45}
                height={'small'}
            >+</WebButton>
        </View>
    );
}

TabbableInput.defaultProps = {
    size: 175,
    disabled: false,
}

export default TabbableInput;