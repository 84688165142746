import React from 'react';
import Svg, {
    G,
    Defs,
    Path,
} from 'react-native-svg';

const IconHome = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="Home" fill={color}>
                <Path id={"roof"}  d="M289.5 127v5c-1.1 5.4-7 11.3-14.8 9.7-5.4-1-11.5-7-16.4-10.5-34.8-25.4-69.4-50.6-104.5-76l-3.8-2.6c-1 0-5.7 4.2-7.8 5.6-36.3 26.3-72 52-108.5 78.7-2.8 2-5.6 4.2-8.3 4.8-7.6 1.7-13.8-3.9-15.1-9.7v-5c1.4-5.7 5.9-8.2 10.2-11.4 8.5-6.2 97-70.6 129-93.4h.8c20.1 14.7 40.3 29.3 60.6 43.9V52.9c0-4.2-.2-10 .8-12.2 2.5-4.4 36.7-4.5 40.4-1.6 2.2 1.5 2 4.2 2 9 0 12.2 0 32.3.2 42.7 0 3-.2 5.3 0 6 .6 2 6 5.1 8.8 7.2 6.7 5 11.6 8.4 18.3 13.2 4 2.9 6.9 5 8.1 9.7"/>
                <Path id={"house"} d="M253.4 204.2c0 12.3 1.5 22.6-8 26.1-4.3 1.6-10 1-15.7 1h-49.2v-85.8h-61.2v85.9H69.8c-10.9 0-18.3.3-21.8-6.8-2.1-4.2-1.6-12.3-1.6-19.3v-52.5c0-3.6-.7-7.9 0-10.3.4-1.5 4-3.6 6.2-5l45.2-32.7c17.5-12.7 36-26.6 52.2-37.7C184.6 92 219 117 253.4 142v62.2z"/>
            </G>
        </Svg>
    );
}

IconHome.defaultProps = {
    color: '#FFF',
};

export default IconHome;