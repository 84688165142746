import React, {
    useContext,
    useState,
} from 'react';
import { Paragraph } from './StyledText';
import * as Styles from '../styles/index'
import {
    View,
    TouchableHighlight,
    ScrollView,
    StyleSheet,
} from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import Overlay from '../ui/Overlay';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { AuthContext } from './AuthContext';

const Footer = (props) => {
    const [authState] = useContext(AuthContext);
    const safeAreaInsets = useSafeArea();
    const [isImprintVisible,       setImprintVisible]       = useState(false);
    const [isTnCVisible,           setTnCVisible]           = useState(false);
    const [isPrivacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);

    // Even with an empty footer: DO NOT REMOVE THE KEYBOARD AVOIDANCE FUNCTIONALITY
    if (authState.isAuthenticated) {
        return (
            <View style={[
                {
                    paddingBottom: safeAreaInsets.bottom,
                    paddingLeft: safeAreaInsets.left,
                    paddingRight: safeAreaInsets.right,
                }]}>
            </View>
        )
    }

    return (
        <View style={[
            Styles.Footer.container,
            {
                paddingBottom: safeAreaInsets.bottom,
                paddingLeft: safeAreaInsets.left,
                paddingRight: safeAreaInsets.right,
        }]}>
            <Overlay
                isVisible={isImprintVisible}
                setVisibility={setImprintVisible}
                titleText={t`imprint`}
                width={"75%"}
                height={"85%"}
            >
                <ScrollView
                    style={[{
                        flex: 1,
                    }]}
                >
                    <Paragraph style={Styles.Footer.overlayText}>
                        <Trans id={'imprintContent'}/>
                    </Paragraph>
                </ScrollView>
            </Overlay>
            <Overlay
                isVisible={isTnCVisible}
                setVisibility={setTnCVisible}
                titleText={t`termsAndConditions`}
                width={"75%"}
                height={"85%"}
            >
                <ScrollView
                    style={[{
                        flex: 1,
                    }]}
                >
                    <Paragraph style={Styles.Footer.overlayText}>
                        <Trans id={'termsAndConditionsContent'}/>
                    </Paragraph>
                </ScrollView>
            </Overlay>
            <Overlay
                isVisible={isPrivacyPolicyVisible}
                setVisibility={setPrivacyPolicyVisible}
                titleText={t`privacyPolicy`}
                width={"75%"}
                height={"85%"}
            >
                <ScrollView
                    style={[{
                        flex: 1,
                    }]}
                >
                    <Paragraph style={Styles.Footer.overlayText}>
                        <Paragraph style={styles.privacyPolicyContent}><Trans id={'privacyPolicyContent'}/></Paragraph>
                    </Paragraph>
                </ScrollView>
            </Overlay>

            <View
                style={{flexDirection: 'row'}}
            >
                <Paragraph style={Styles.Footer.text}>{'\u00A9'} 2019 Medical Equipment IT (UG) - </Paragraph>
                <TouchableHighlight
                    onPress={() => {
                        setImprintVisible(true);
                    }}
                >
                    <View>
                        <Paragraph style={Styles.Footer.text}><Trans id={'imprint'}/></Paragraph>
                    </View>
                </TouchableHighlight>
                {/*<Paragraph style={Styles.Footer.text}> - </Paragraph>*/}
                {/*<TouchableHighlight*/}
                {/*    onPress={() => {*/}
                {/*        setTnCVisible(true);*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <View>*/}
                {/*        <Paragraph style={Styles.Footer.text}>AGB</Paragraph>*/}
                {/*    </View>*/}
                {/*</TouchableHighlight>*/}
                <Paragraph style={Styles.Footer.text}> - </Paragraph>
                <TouchableHighlight
                    onPress={() => {
                        setPrivacyPolicyVisible(true);
                    }}
                >
                    <View>
                        <Paragraph style={Styles.Footer.text}><Trans id={'privacyPolicy'}/></Paragraph>
                    </View>
                </TouchableHighlight>
                <DevelopmentModeNotice/>
            </View>
        </View>
    );
};

function DevelopmentModeNotice() {
    if (__DEV__) {
        return (
            <Paragraph style={[Styles.Footer.text, {color: 'red'}]}> - Development mode is enabled!</Paragraph>
        );
    }

    return null;
}

export default Footer;

const styles = StyleSheet.create({
    // imprint section
    imprintContent: {
        fontSize: 16,
        marginVertical: 10,
    },

    // privacy policy section
    privacyPolicyContent: {
        fontSize: 16,
        marginVertical: 10,
    },
});