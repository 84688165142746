import React, {
    useEffect,
    useState,
} from 'react';
import {
    t
} from '@lingui/macro';
import * as colors from '../styles/colors';
import {
    Icon,
    Input,
} from 'react-native-elements';
import * as UIStyles from './UIStyles'
import { TouchableOpacity } from 'react-native';

const DefaultInput = React.forwardRef((
    {
        children,
        nativeID, min, max,
        onChange, onBlur, onSubmitEditing, onKeyPress,
        autoCompleteType, editable, disabled, mandatory, secureTextEntry, multiline, growInHeight, autoCapitalize,
        label, placeholder, errorMessage,
        leftIconType, rightIconAction,rightIconName, keyboardType,
        ...props
    },
    ref
) => {
    const self = (ref !== null ? ref : React.createRef());
    const [heightAfterContentSizeChanged, setHeightAfterContentSizeChanged] = useState(null);
    const [initialHeight, setInitialHeight]                                 = useState(null);
    const [value, setValue]                                                 = useState(props.value);
    const [isFocused, setFocus]                                             = useState(false);
    const [hasError, setError]                                              = useState(false);
    const [internalErrorMessage, setErrorMessage]                           = useState('');

    let inputContainerStyle  = UIStyles.inputContainer;
    let placeholderTextColor = UIStyles.placeholderTextColor;

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const _onChange = (_value) => {
        if (_value !== '') {
            _value = _checkRules(_value);
        }

        if (typeof onChange === 'function') {
            onChange(_value);
        }

        setValue(_value);
    }

    const onFocus = () => {
        setFocus(true);
    }

    const _checkRules = (_value) => {
        let errors = false;

        if (mandatory && (_value === null || _value.length === 0)) {
            _value = null;
            setErrorMessage(t`mandatoryInputMissing`);
            self.current.shake();
            errors = true;
        } else if (mandatory && _value.length) {
            setErrorMessage(null);
        }

        if (min !== undefined && _value < min) {
            _value = min.toString();
            self.current.shake();
        }

        if (max !== undefined && _value > max) {
            _value = max.toString();
            self.current.shake();
        }

        if (_value?.length < props.minLength) {
            _value = null;
            self.current.shake();
            errors = true;
        }

        setError(errors);

        return _value;
    }

    const _onBlur = () => {
        setFocus(false);

        const _value = _checkRules(value);

        setValue(_value);

        if (typeof onBlur === 'function') {
            onBlur(_value);
        }
    }

    if (isFocused) {
        inputContainerStyle = UIStyles.inputContainerFocused;
    }

    if (editable === false) {
        inputContainerStyle  = UIStyles.inputContainerDisabled;
        placeholderTextColor = UIStyles.placeholderTextColorDisabled;
    }

    if (hasError || errorMessage?.length) {
        inputContainerStyle = UIStyles.inputContainerError;
    }

    if (hasError && (errorMessage === undefined || errorMessage.length === 0)) {
        errorMessage = internalErrorMessage;
    }

    let presetHeight = Math.max(props.inputStyle?.height ?? 0, props.inputStyle?.minHeight ?? 0);
    let _height = Math.max(heightAfterContentSizeChanged, initialHeight, presetHeight, null);

    return (
        <Input
            nativeID={nativeID}
            ref={self}
            value={value}
            {...(secureTextEntry ? {secureTextEntry: secureTextEntry} : undefined)}
            disabled={disabled}
            keyboardType={keyboardType ?? 'default'}
            autoCapitalize={autoCapitalize ?? 'sentences'}
            multiline={multiline}
            onChangeText={_onChange}
            onSubmitEditing={onSubmitEditing}
            onKeyPress={onKeyPress}
            placeholder={placeholder ?? ''}
            placeholderTextColor={placeholderTextColor}
            inputContainerStyle={[
                inputContainerStyle,
                props.inputContainerStyle,
                {...(_height > 0 ? {height: _height + 4} : undefined)}, // adding 4px seems to fix the web autofill blue background
            ]}
            inputStyle={[
                props.inputStyle,
                {
                    textAlignVertical: multiline ? 'top' : 'center',
                    color: !editable ? colors.colorText2 : colors.colorText0,
                    height: '100%',
                    borderWidth: 0,
                    borderColor: 'transparent',
                    // outline: 'none', // @TODO-web check if needed there, as outline is not a valid app property!
                }
            ]}
            onFocus={() => onFocus()}
            onBlur={_onBlur}
            editable={editable}
            {...(autoCompleteType ? {autoCompleteType: autoCompleteType} : undefined)}
            underlineColorAndroid='transparent'
            onLayout={(event) => {
                if (typeof event.persist === "function") {
                    event.persist();
                }

                setInitialHeight(currentHeight => {
                    if ((currentHeight === undefined || currentHeight === null) && event?.nativeEvent?.layout) {
                        return Math.ceil(event.nativeEvent.layout.height);
                    } else {
                        return currentHeight;
                    }
                });
            }}
            onContentSizeChange={event => {
                if (typeof event.persist === "function") {
                    event.persist();
                }

                if (growInHeight) {
                    const resizeHeight = event.nativeEvent.contentSize.height;

                    if (resizeHeight > 0) {
                        setHeightAfterContentSizeChanged(Math.ceil(resizeHeight));
                    }
                }
            }}
            {...(leftIconType ? {
                leftIcon: {
                    type: 'font-awesome',
                    name: leftIconType,
                    color: colors.colorText2,
            }} : undefined)}

            leftIconContainerStyle={{
                marginRight: 10,
                backgroundColor: '#000',
                width: 40,
                borderTopLeftRadius: 3,
                borderBottomLeftRadius: 3,
                marginTop: 0,
                marginBottom: 0,
                ...(_height !== null && _height > 0 ? {height: '100%'} : undefined),
            }}

            {...(rightIconName ? {
                rightIcon: (
                    <TouchableOpacity
                        onPress={rightIconAction}
                    >
                        <Icon
                            name={rightIconName}
                            type={'font-awesome'}
                            color={colors.colorText0}
                        />
                    </TouchableOpacity>
                )
            } : undefined)}
            rightIconContainerStyle={{
                marginRight: 10,
                marginLeft: 10,
                marginTop: 0,
                marginBottom: 0,
                ...(_height !== null && _height > 0 ? {height: '100%'} : undefined),
            }}

            {...(label ? {label: label} : undefined)}
            labelStyle={{color: colors.colorText0}}

            errorStyle={{ color: 'red' }}
            errorMessage={errorMessage}
        />
    )
});

DefaultInput.defaultProps = {
    value: '',
    minLength: 0,
    multiline: false,
    growInHeight: false,
    editable: true,
    disabled: false,
};

export default DefaultInput;