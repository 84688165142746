/**
 * reference: https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
 */
import React, {
    useState
} from 'react';

const MenuContext = React.createContext([{}, () => {}]);

const MenuProvider = (props) => {
    const [state, setState] = useState({
        isLoading: false,
        isLoaded: false,
        combMenu: null,
        sideMenu: null
    });

    return (
        <MenuContext.Provider value={[state, setState]}>
            {props.children}
        </MenuContext.Provider>
    );
};

export { MenuContext, MenuProvider };