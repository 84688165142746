import * as MediaLibrary from 'expo-media-library';
import { Camera } from 'expo-camera';
import { Platform } from 'react-native';

let MEIT = global.MEIT || function () {};

MEIT.init = () => {}

MEIT.getPermissions = () => {
    const getPermissionsAsync = async () => {
        // ask for PDFs as images in external area hack to get access to protected files with external pdf viewer
        await MediaLibrary.requestPermissionsAsync();
        // ask for QR code scanner functionality
        await Camera.requestPermissionsAsync();
    };

    if (Platform.OS !== 'web') {
        return getPermissionsAsync();
    }
}

global.MEIT = MEIT;

require('./helperDev');