import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
} from 'react-native-svg';

const IconStorage = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="LAGER" fill={color}>
                <Path id="Kiste" d="M110 97l59 121 102-51-58-120z"/>
                <Path id="Rad" d="M117 208a25 25 0 100 50 25 25 0 000-50zm0 38a13 13 0 110-26 13 13 0 010 26z"/>
                <Path id="Griff" fill="none" stroke={color} strokeWidth="9.9213" d="M223 207l-61 30L73 54 54 16s-15 1-29 15"/>
            </G>
        </Svg>
    );
}

IconStorage.defaultProps = {
    color: '#FFF',
};

export default IconStorage;