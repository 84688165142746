import React, {
    useState
} from 'react';
import {
    View,
    StyleSheet,
    TouchableWithoutFeedback,
} from 'react-native';
import { Paragraph } from './StyledText';
import * as Styles from '../styles/index';
import useCart from './useCart';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import WebButton from '../ui/WebButton';
import * as SVG from '../assets/images/svg/svg';
import ImageGallery from './ImageGallery';
import { Layouts } from '../styles/index';
import NumericInput from '../ui/NumericInput';
import * as colors from '../styles/colors';
import { Icon } from 'react-native-elements';

const ArticleView = (props) => {
    const { addToCart } = useCart();
    const [descriptionState, setDescriptionState] = useState('hidden');
    const [articleQuantity, setArticleQuantity] = useState(1);
    const article = props.article;

    return (
        <View style={styles.innerDeviceContainer}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: 10,
                paddingRight: 10,
            }}>
                <View style={{
                    width: 200,
                    marginLeft: 5,
                }}>
                    <ImageGallery imageList={article.images} />
                </View>
                <View style={[styles.details]}>
                    <View>
                        <Paragraph style={styles.innerDeviceHeadline}>{article.name}</Paragraph>
                        { article.pzn && <Paragraph><Trans id={'pzn'} /> {article.pzn}</Paragraph> }
                        <Paragraph><Trans id={'productionUnitShort'} /> {article.unit.unitQuantity} {article.unit.shortcut}</Paragraph>
                    </View>
                    <View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                            <Paragraph><Trans id={'quantity'} /></Paragraph>
                            <View style={{
                                flexGrow: 1,
                                flexDirection: 'row',
                            }}>
                                <View style={{
                                    width: 90,
                                    height: 55,
                                    paddingTop: 7,
                                }}>
                                    <NumericInput
                                        value={`${articleQuantity}`}
                                        onChange={setArticleQuantity}
                                    />
                                </View>
                                <WebButton
                                    size={'75%'}
                                    onPress={() => addToCart(article, articleQuantity)}
                                    icon={<SVG.IconCart/>}
                                >{t`addToCart`}</WebButton>
                            </View>
                        </View>
                    </View>
                    <TouchableWithoutFeedback
                        onPress={() => {
                            setDescriptionState(prevState => {
                                if (prevState === 'hidden') {
                                    return 'visible';
                                } else {
                                    return 'hidden';
                                }
                            });
                        }}
                    >
                        <View style={[Styles.Layouts.row, Layouts.grayHeadlineBar, {marginTop: 10}]}>
                            <View style={styles.descriptionExpander}>
                                <Icon
                                    name={descriptionState === 'visible' ? 'compress' : 'expand'}
                                    type={'font-awesome'}
                                    color={colors.colorPrimary0}
                                    underlayColor={'#ccc'}
                                    size={30}
                                />
                            </View>
                            <Paragraph
                                style={[
                                    Layouts.grayHeadlineBar,
                                    styles.tableHeader,
                                    {
                                        borderColor: colors.grayNormal,
                                        borderBottomWidth: 1,
                                    }]
                                }
                            ><Trans id={'productDescription'} /></Paragraph>
                        </View>
                    </TouchableWithoutFeedback>
                    <View
                        style={{
                            minHeight: '40%',
                            borderColor: colors.grayNormal,
                            borderWidth: 1,
                            borderTopWidth: 0,
                            display: descriptionState === 'hidden' ? 'none' : 'flex',
                            padding: 5,
                        }}
                    >
                        <Paragraph>{article.description}</Paragraph>
                    </View>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    innerDeviceContainer: {
        paddingRight: 5,
    },
    innerDeviceHeadline: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 7,
    },
    details: {
        flex: 1,
        paddingLeft: 20,
    },
    input: {
        ...Styles.Layouts.input,
    },
    descriptionExpander: {
        width: 40,
        alignSelf: 'center',
        paddingLeft: 10,
        marginRight: 10,
    },
    tableHeader: {
        fontWeight: 'bold',
    },
});

export default ArticleView;