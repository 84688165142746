import * as colors from '../styles/colors';

export const pageTitle = {
    fontSize: 30,
    fontFamily: 'openSansSemiBold',
    color: colors.grayStrong,
};

export const headline = {
    fontSize: 24,
};

export const subtitle = {
    fontSize: 18,
    fontFamily: 'openSansSemiBold',
};

export const content = {
    fontSize: 18,
    fontFamily: 'openSans',
};

export const tooltips = {
    fontSize: 18,
    fontFamily: 'openSans',
};
