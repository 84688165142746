import React from 'react';
import ArticleView from '../components/ArticleView';

const ArticleScreen = (props) => {
    const device         = route.params?.device ?? {deviceName: 'no device selected'};
    const showTotalPrice = route.params?.showTotalPrice ?? false;

    return (
        <ArticleView key={device.itemNumber} article={device} showTotalPrice={showTotalPrice} />
    )
};

export default ArticleScreen;