import React from 'react';
import {StyleSheet, Text, TouchableHighlight, View} from "react-native";
import * as Styles from "../styles";

export function ModalScreen(props) {
    return (
        <View style={Styles.Layouts.modal}>
            <View style={{backgroundColor: 'white', borderWidth: 1, borderColor: 'black', padding: 20}}>
                <Text style={styles.title}>{props.title}</Text>
                <View style={styles.text}>{props.content}</View>
                <TouchableHighlight
                    style={styles.button}
                    onPress={() => {
                        props.setVisibility(false);
                    }}
                >
                    <Text>OK</Text>
                </TouchableHighlight>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 24
    },
    text: {
        marginBottom: 10,
        marginTop: 10,
    },
    button: {
        ...Styles.Layouts.button,
        justifyContent: 'center',
        alignItems: 'center'
    }
});