import React from 'react';
import {
    StyleSheet,
    View,
    TouchableHighlight,
} from 'react-native';
import { Newsbox } from '../components/Newsbox';
import Comb from '../components/Comb';

import { Paragraph } from '../components/StyledText';
import { useNavigation } from '@react-navigation/native';
import useMenu from '../context/useMenu';

const HomeScreen = (props) => {
    const navigation = useNavigation();

    const { getCombMenu } = useMenu();

    const items = getCombMenu();

    if (items === null) {
        return null;
    }

    return (
        <View style={styles.container}>
            {/*<Newsbox showModal={true} />*/}
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignSelf: 'center',
                width: 5 * 195,
            }}>
                {items.map((item, itemKey) => (
                    <Comb
                        key={itemKey}
                        style={[
                            itemKey % 2 === 0 ? null : styles.comb2thRow,
                        ]}
                        atomic
                        item={item}
                    />
                ))}
            </View>
            { __DEV__ && <TouchableHighlight onPress={() => {navigation.navigate('UITest')}}><View><Paragraph>UI Test</Paragraph></View></TouchableHighlight> }
        </View>
    );
};

export default HomeScreen;

const styles = StyleSheet.create({
    container: {
        marginTop: 20,
    },
    comb2thRow: {
        marginTop: 91, //259*0.65,
        marginBottom: -84, //-259*0.65,
        marginLeft: -40, //-300*0.65,
        marginRight: -40, //-300*0.65,
        position: 'relative',
    }
});
