import React, {
    useState
} from 'react';
import {
    StyleSheet,
    View,
} from 'react-native';
import * as Styles from '../styles';
import {
    Icon,
    Input
} from 'react-native-elements';
import { Paragraph } from './StyledText';
import { t } from '@lingui/macro';

const Tab = (propsParam) => {
    const { scaleTabs, ...props } = propsParam;

    const [isInEditmode, setToEditMode] = useState(false);
    const [title, setTitle]             = useState(props.route.title);

    const save = () => {
        setToEditMode(false);
        props.updateTabName(title)
    }

    return (
        <View
            style={[
                styles.tabs,
                Styles.Layouts.row,
                {
                    flex: scaleTabs ? 5 : 0,
                },
                (
                    (props.navigationState.index === -1 && props.tab === 0)
                    ||
                    (props.navigationState.index === props.tab)
                ) ? styles.tabActive : null,
            ]}
        >
            <View style={{flex: 1}}>
                { !isInEditmode &&
                    <View style={[Styles.Layouts.row]}>
                        <View style={{width: 30}} />
                        <View style={{flex: 1}}>
                            <Paragraph style={[{width: '100%', textAlignVertical: "center", textAlign: 'center'}]}>{title}</Paragraph>
                        </View>
                        <View style={{width: 30}}>
                            {props.navigationState.index === props.tab && props.updateTabName &&
                                <Icon
                                    name={'edit'}
                                    type={'font-awesome'}
                                    color={'#000'}
                                    underlayColor={'#ccc'}
                                    size={20}
                                    containerStyle={{marginLeft: 5}}
                                    onPress={() => setToEditMode(true)}
                                />
                            }
                        </View>
                    </View>
                }
                { isInEditmode &&
                    <Input
                        value={title}
                        keyboardType={'default'}
                        onChangeText={(text) => setTitle(text)}
                        placeholder={t`newList`}
                        placeholderTextColor={'#aaa'}
                        inputContainerStyle={{
                            width: "100%",
                            height: 24,
                        }}
                        rightIcon={
                            <Icon
                                name={'save'}
                                type={'font-awesome'}
                                color={'#000'}
                                underlayColor={'#ccc'}
                                size={20}
                                style={{}}
                                onPress={() => save()}
                            />
                        }
                        onBlur={() => save()}

                        underlineColorAndroid='transparent'
                    />
                }
            </View>
        </View>
    );
};

Tab.defaultProps = {
    scaleTabs: false,
}

export default Tab;

const styles = StyleSheet.create({
    tabs: {
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
        borderTopWidth: 2,
        borderLeftWidth: 2,
        borderRightWidth: 2,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        height: 50,
        backgroundColor: '#ccc',
        borderColor: '#ccc',
        borderBottomWidth: 0,
    },
    tabActive: {
        fontWeight: 'bold',
        backgroundColor: '#efefef',
        borderBottomColor: '#efefef',
        borderBottomWidth: 2,
    },
});