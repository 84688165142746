import React from 'react';
import Svg, {
    G,
    Path,
    Defs, Rect,
} from 'react-native-svg';

const IconQR = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="QR" fill={color}>
                <Path d="M239 106V41h-65v65h65zm15 17h-97V26h97v97zM254 194v19h-19v-19h19zM254 137v19h-19v19h-39v-19h-19v76h-19v-94l1-1h37v19h38l1-19h19z"/>
                <Path d="M216 194v19h-20l1-19h19zM215 65v19h-19-1l1-19h19zM126 217v-65H61v65h65zm17 15H46v-97h97v97zM126 106V41H61v65h65zm17 17H46V26h97v97z"/>
                <Path d="M105 65v19H85l1-19h19zM104 174l1 19H85l1-19h18z"/>
            </G>
        </Svg>
    );
}

IconQR.defaultProps = {
    color: '#FFF',
};

export default IconQR;