/**
 * reference: https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
 */
import React from 'react';

const AuthContext = React.createContext([{}, () => {}]);

const AuthProvider = (props) => {
    const [state, setState] = React.useState({
        isLoading: true,
        isSignout: false,
        isAuthenticated: false,
        isUserLoaded: false,
        userToken: null,
        user: null,
    });

    return (
        <AuthContext.Provider value={[state, setState]}>
            {props.children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };