import React from 'react';
import {
    Dimensions,
    View
} from 'react-native';
import * as colors from '../styles/colors';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

const renderSeparator = () => {
  return (
      <View style={{
        height: 2,
        width: '100%',
        backgroundColor: colors.grayLight,
        marginVertical: 0,
      }}/>
  )
};

export default {
  window: {
    width,
    height,
  },
  isSmallDevice: width < 375,
  renderSeparator: renderSeparator,
};
