import React from 'react';

import FlagDE from './FlagDE';
import FlagEN from './FlagEN';

import IconHamburger from './IconHamburger';
import IconItemList from './IconItemList';
import IconSupport from './IconSupport';
import IconUVV from './IconUVV';
import IconShop from './IconShop';
import IconStorage from './IconStorage';
import IconISO from './IconISO';
import IconHome from './IconHome';
import IconHygiene from './IconHygiene';
import IconMyDevices from './IconMyDevices';
import IconBed from './IconBed';
import IconMEIT from './IconMEIT';
import IconCart from './IconCart';
import IconLogout from './IconLogout';
import IconInfo from './IconInfo';
import IconMasterData from './IconMasterData';
import IconUser from './IconUser';
import IconDocument from './IconDocument';
import IconService from './IconService';
import IconQR from './IconQR';
import IconLocation from './IconLocation';

const getComponent = (config) => {
    switch (config.iconName) {
        case 'IconHamburger':
            return <IconHamburger/>;

        case 'IconItemList':
            return <IconItemList/>;

        case 'IconSupport':
            return <IconSupport/>;

        case 'IconUVV':
            return <IconUVV/>;

        case 'IconShop':
            return <IconShop/>;

        case 'IconStorage':
            return <IconStorage/>;

        case 'IconISO':
            return <IconISO/>;

        case 'IconHome':
            return <IconHome/>;

        case 'IconHygiene':
            return <IconHygiene/>;

        case 'IconMyDevices':
            return <IconMyDevices/>;

        case 'IconBed':
            return <IconBed/>;

        case 'IconMEIT':
            return <IconMEIT/>;

        case 'IconCart':
            return <IconCart/>;

        case 'IconLogout':
            return <IconLogout/>;

        case 'IconInfo':
            return <IconInfo/>;

        case 'IconMasterData':
            return <IconMasterData/>;

        case 'IconUser':
            return <IconUser/>;

        case 'IconDocument':
            let text = null;

            if (config.hasOwnProperty('iconText')) {
                text = i18n._(config.iconText);
            }

            return <IconDocument
                {...(text ? {text: text} : undefined)}
            />;

        case 'IconLocation':
            return <IconLocation
                {...(text ? {text: text} : undefined)}
            />

        case 'IconService':
            return <IconService/>;

        case 'IconQR':
            return <IconQR/>;

        default:
            return null;
    }
}

export {
    //mapper function
    getComponent,

    //flags
    FlagDE,
    FlagEN,

    //icons
    IconHamburger,
    IconItemList,
    IconSupport,
    IconUVV,
    IconShop,
    IconStorage,
    IconISO,
    IconHome,
    IconHygiene,
    IconMyDevices,
    IconBed,
    IconMEIT,
    IconCart,
    IconLogout,
    IconInfo,
    IconMasterData,
    IconUser,
    IconDocument,
    IconService,
    IconQR,
    IconLocation,
}