import React from 'react';
import {
    default as ContentLoaderLib,
    Rect as RectLib,
} from 'react-content-loader/native';
import * as colors from '../styles/colors';

const ContentLoader = ({children, ...props}) => {
    return (
        <ContentLoaderLib
            animate={true}
            speed={1.2}
            interval={0.05}
            width={"100%"}
            height={"100%"}
            // viewBox={"0 0 1380 720"} need to be checked if it can be removed safely.
            foregroundColor={'#aaa'}
            backgroundColor={colors.colorPrimary1}
        >
            {children}
        </ContentLoaderLib>
    )
}

export default ContentLoader;
export { RectLib as Rect };