import {
    useContext,
} from 'react';
import { AuthContext } from './AuthContext';
import { StorageContext } from './StorageContext';
import Requester from './Requester';

const useStorage = () => {
    const [authState]       = useContext(AuthContext);
    const [state, setState] = useContext(StorageContext);

    let doubleClickDelayTimer = null;

    const updateStorage = async () => {
        await setState(currentState => ({
            ...currentState,
            isLoading: true,
            isLoaded: false
        }));

        let networkError = false;
        const response   = await Requester.get(
            '/facility/' + authState.user.facilityId + '/inventoryLists'
        ).catch(async (e) => {
            networkError = true;
        });

        if (networkError) {
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        const dataSource = await response.json();
        Requester.debug(response, dataSource);

        if (response.ok === false) {
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        let indexedStorage = [];

        Object.values(dataSource).map((storageList) => {
            storageList.items?.map((storage) => {
                storage.setpoint        = 100;
                storage.lowerLimitValue = 9;
                storage.upperLimitValue = 39;
            });

            indexedStorage[storageList.inventoryListId] = storageList;
        });

        await setState(currentState => ({
            ...currentState,
            isLoading: false,
            isLoaded: true,
            storage: indexedStorage
        }));

        return indexedStorage;
    }

    const reset = async () => {
        await setState(currentState => ({
            ...currentState,
            isLoaded: false,
            storage: []
        }));
    }

    const list = async (forceReload = false) => {
        if (forceReload || (!state.isLoading && !state.isLoaded)) {
            return await updateStorage();
        }

        return state.storage;
    }

    function updateStorageItem(storageListId, itemToUpdate, newQuantity) {
        clearTimeout(doubleClickDelayTimer);
        doubleClickDelayTimer = setTimeout(_updateStorageItem, 1000, storageListId, itemToUpdate, newQuantity);
    }

    function _updateStorageItem(storageListId, itemToUpdate, newQuantity) {
        itemToUpdate.quantity    = parseInt(newQuantity);
        itemToUpdate.actualValue = parseInt(newQuantity);

        if (isNaN(itemToUpdate.quantity)) {
            return false;
        }

        let currentProductsInItemList = state.storage[storageListId]['items'];
        let position;

        if (position = currentProductsInItemList.find(product => product.inventoryId === itemToUpdate.inventoryId)) {
            //@TODO-API requesting a PATCH route to only update the quantity
            Requester.put(
                '/inventoryItem/' + itemToUpdate.inventoryId,
                itemToUpdate
            );

            let lists                            = JSON.parse(JSON.stringify(state.storage));
            lists[storageListId].items[position] = itemToUpdate;
            setState(currentState => ({ ...currentState, storage: lists }));
        }
    }

    return {
        isLoaded: state.isLoaded,
        list,
        reset,
        updateStorageItem,
    }
};

export default useStorage;