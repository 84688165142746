import React, {
    useContext,
} from 'react';
import {
    View,
    StyleSheet,
    FlatList,
} from 'react-native';
import { Paragraph } from './StyledText';
import * as Styles from '../styles/index';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import WebButton from '../ui/WebButton';
import * as colors from '../styles/colors';
import Toast from '../interfaces/Toast';
import CartEntry from './CartEntry';
import useCart from './useCart';
import { CartContext } from './CartContext';
import ContentLoader, {
    Rect
} from '../ui/ContentLoader';

const Cart = (props) => {
    const {
        list: listCart,
        isLoaded,
        placeOrder
    } = useCart();

    const [cartContent] = useContext(CartContext);

    const MyLoader = () => (
        <ContentLoader>
            <Rect x="20" y="15" rx="0" ry="0" width="95%" height="30" />
            <Rect x="20" y="60" rx="0" ry="0" width="95%" height="30" />
            <Rect x="20" y="105" rx="0" ry="0" width="95%" height="30" />
            <Rect x="80%" y="90%" rx="0" ry="0" width="15%" height="30" />
        </ContentLoader>
    );

    if (!isLoaded) {
        return (
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <MyLoader />
            </View>
        )
    }

    const keyExtractor = (item, index) => item.productId.toString();
    const renderItem   = ({item, index}) => (
        <CartEntry
            key={index}
            item={item}
        />
    );

    const listHeaderComponent = () => {
        return (
            <View style={[
                Styles.Layouts.row,
                {
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderRadius: 5,
                    borderColor: colors.grayNormal,
                    backgroundColor: '#eee',
                    padding: 5,
                }
            ]}>
                <Paragraph style={[styles.tableHeader, styles.action]}/>
                <Paragraph style={[styles.tableHeader, styles.name]}><Trans id={'article'} /></Paragraph>
                <Paragraph style={[styles.tableHeader, styles.quantity]}><Trans id={'quantity'} /></Paragraph>
            </View>
        );
    }

    return (
        <View
            style={{display: 'flex', flex: 1}}
        >
            {cartContent.articles.length === 0 ?
                <View style={styles.empty}>
                    <Paragraph style={styles.title}><Trans id={'noCartEntriesAvailable'}/></Paragraph>
                </View>
                :
                <React.Fragment>
                    <FlatList
                        keyExtractor={keyExtractor}
                        data={cartContent.articles}
                        extraData={isLoaded}
                        renderItem={renderItem}
                        ListHeaderComponent={listHeaderComponent}
                        stickyHeaderIndices={[0]}
                        maxToRenderPerBatch={100}
                        initialNumToRender={100}
                    />
                    <View>
                        <View style={[
                            Styles.Layouts.row,
                            {
                                padding: 5,
                            }
                        ]}>
                            <Paragraph style={[styles.tableHeader, styles.action]}/>
                            <Paragraph style={[styles.tableHeader, styles.name]}/>
                            <Paragraph style={[styles.tableHeader, styles.quantity]}/>
                        </View>
                        <View style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'row'}}>
                            <WebButton
                                size={250}
                                onPress={async () => {
                                    const orderSuccessful = await placeOrder(props.locationId);

                                    if (orderSuccessful === false) {
                                        Toast.showError(t`error`);
                                    } else {
                                        Toast.showSuccess(t`cartOrderSuccess`);
                                        await listCart(true);
                                    }
                                }}
                            >{t`order`}</WebButton>
                        </View>
                    </View>
                </React.Fragment>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    empty: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },

    tableHeader: {
        fontWeight: 'bold'
    },
    name: {
        flex: 3,
        textAlign: 'left',
        marginBottom: 0,
        paddingHorizontal: 5,
    },
    quantity: {
        width: 200,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    action: {
        flexGrow: 0,
        width: 48,
        alignContent: 'center',
        paddingHorizontal: 10,
    },
});

export default Cart;