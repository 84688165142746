import React, {
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    ScrollView,
    StyleSheet,
    View,
} from 'react-native';
import { Paragraph } from '../components/StyledText';
import * as Styles from '../styles/index';
import Order from '../components/Order';
import { Trans } from '@lingui/react';
import { useNavigation } from '@react-navigation/native';
import Requester from '../components/Requester';
import ContentLoader, {
    Rect
} from '../ui/ContentLoader';
import * as colors from '../styles/colors';
import { AuthContext } from '../components/AuthContext';

const OfferBookScreen = (props) => {
    const [authState] = useContext(AuthContext);
    const navigation = useNavigation();
    const [nums, setNums] = useState(false);
    const [isOrderBookLoaded, setOrderBookLoaded] = useState(false);
    const [isOrderBookLoading, setOrderBookLoading] = useState(false);
    const [orderBook, setOrderBook] = useState([]);

    useEffect(() => {
            setNums(Math.floor(Math.random() * 10) + 5);
        },
        []
    );

    const loadOrderBook = async () => {
        setOrderBookLoading(true);
        let networkError = false;
        const response   = await Requester.get(
            '/facility/' + authState.user.facilityId + '/offers'
        ).catch((e) => {
            networkError = true;
        });

        if (networkError) {
            setOrderBookLoaded(false);
            navigation.goBack();

            return false;
        }

        let data = await response.json();
        Requester.debug(response, data);

        data = Object.values(data).filter(order => {
            return order.hasOwnProperty('offerId');
        });

        data = data.filter(entry => {
            if (entry.orderDate) {
                entry.orderDate = l18nDate(entry.orderDate);
            }
            return entry;
        })

        setOrderBook(data);
        setOrderBookLoaded(true);
        setOrderBookLoading(false);
    }

    const MyLoader = (_props) => {
        let rows    = [];
        const _nums = _props.nums;

        for (let i = 0; i < _nums; i++) {
            rows.push(<Rect key={i} x="20" y={`${15 + 45 * i}`} rx="0" ry="0" width="95%" height="30"/>);
        }

        return (
            <ContentLoader>
                {rows}
            </ContentLoader>
        );
    }

    if (!isOrderBookLoaded && !isOrderBookLoading) {
        loadOrderBook();
    }

    if (!isOrderBookLoaded) {
        return (
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <MyLoader nums={nums}/>
            </View>
        )
    }

    if (orderBook.length === 0) {
        return (
            <View style={styles.empty}>
                <Paragraph style={styles.title}><Trans id={'noEntriesAvailable'}/></Paragraph>
            </View>
        );
    }

    return (
        <View style={{
            display: 'flex',
            flex: 1,
            padding: 5,
        }}>
            <View style={[Styles.Layouts.row, styles.orderBox]}>
                <View style={styles.orderIcon} />
                {/*<Paragraph style={[styles.tableHeader, styles.orderStatus]}>*/}
                {/*    <Trans id={'orderStatus'} />*/}
                {/*</Paragraph>*/}
                <Paragraph style={[styles.tableHeader, styles.orderDate]}><Trans id={'orderDate'} /></Paragraph>
                <Paragraph style={[styles.tableHeader, styles.orderInfo]}><Trans id={'orderedBy'} /></Paragraph>
                {/*<Paragraph style={[styles.tableHeader, styles.orderAckedBy]}><Trans id={'orderAcknowledgedBy'} /></Paragraph>*/}
                {/*<Paragraph style={[styles.tableHeader, styles.orderAckedAt]} />*/}
            </View>
            <View style={{flex: 1}}>
                <ScrollView>
                    {orderBook.map((order) =>
                        <View
                            key={order.orderId}
                            style={{
                                marginBottom: 7,
                                borderColor: '#000',
                                borderWidth: 1,
                                borderRadius: 7,
                            }}
                        >
                            <Order
                                order={order}
                            />
                        </View>
                    )}
                </ScrollView>
            </View>
        </View>
    );
}

export default OfferBookScreen;

const styles = StyleSheet.create({
    empty: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableHeader: {
        fontWeight: 'bold'
    },
    orderBox: {
        ...Styles.Layouts.row,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: colors.grayNormal,
        backgroundColor: '#eee',
        marginVertical: 5,
        paddingVertical: 5,
    },
    orderDetails: {
        marginTop: 10,
        padding: 7,
        borderBottomLeftRadius: 7,
    },
    orderIcon: {
        width: 40,
        paddingLeft: 10,
        textAlign: 'center',
    },
    orderDate: {
        width: 160,
        paddingHorizontal: 10,
        textAlign: 'center',
    },
    orderSum: {
        width: 140,
        paddingHorizontal: 10,
        textAlign: 'center',
    },
    orderInfo: {
        width: 250,
        paddingHorizontal: 10,
        textAlign: 'center',
    },
});