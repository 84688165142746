import React from 'react';
import {
    t
} from '@lingui/macro';
import DefaultInput from './DefaultInput';

const EmailInput = React.forwardRef((
    {
        children,
        nativeID, email,
        onChange, onSubmitEditing, onKeyPress,
        noLabel, label, editable, placeholder, mandatory,
        ...props
    },
    ref
) => {
    return (
        <DefaultInput
            nativeID={nativeID}
            ref={(ref !== null ? ref : null)}
            mandatory={mandatory}
            value={email}
            keyboardType='email-address'
            onChange={onChange}
            onSubmitEditing={onSubmitEditing}
            onKeyPress={onKeyPress}
            placeholder={placeholder ?? t`emailAddressPlaceholderForUserLogin`}
            editable={editable}
            autoCompleteType={'email'}
            leftIconType='envelope'
            {...(!noLabel ? {label: (label ?? t`emailAddress`)} : undefined)}
        />
    );
});

export default EmailInput;