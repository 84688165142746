const tintColor = '#2f95dc';

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
};

export const debug = {
    Reset:           "font-weight: 600; text-decoration: none;",
    Bright:          "font-weight: 900",
    Dim:             "font-weight: 300",
    Underscore:      "text-decoration: underline",
    Blink:           "",
    Reverse:         "",
    Hidden:          "",

    FgBlack:         "rgb(0, 0, 0)",
    FgRed:           "rgb(170, 0, 0)",
    FgGreen:         "rgb(0, 170, 0)",
    FgYellow:        "rgb(170, 85, 0)",
    FgBlue:          "rgb(0, 0, 170)",
    FgMagenta:       "rgb(170, 0, 170)",
    FgCyan:          "rgb(0, 170, 170)",
    FgWhite:         "rgb(170, 170, 170)",
    FgBrightBlack:   "rgb(85, 85, 85)",
    FgBrightRed:     "rgb(255, 85, 85)",
    FgBrightGreen:   "rgb(85, 255, 85)",
    FgBrightYellow:  "rgb(255, 255, 85)",
    FgBrightBlue:    "rgb(85, 85, 255)",
    FgBrightMagenta: "rgb(255, 85, 255)",
    FgBrightCyan:    "rgb(85, 255, 255)",
    FgBrightWhite:   "rgb(255, 255, 255)",

    BgBlack:         "rgb(0, 0, 0)",
    BgRed:           "rgb(170, 0, 0)",
    BgGreen:         "rgb(0, 170, 0)",
    BgYellow:        "rgb(170, 85, 0)",
    BgBlue:          "rgb(0, 0, 170)",
    BgMagenta:       "rgb(170, 0, 170)",
    BgCyan:          "rgb(0, 170, 170)",
    BgWhite:         "rgb(170, 170, 170)",
    BgBrightBlack:   "rgb(85, 85, 85)",
    BgBrightRed:     "rgb(255, 85, 85)",
    BgBrightGreen:   "rgb(85, 255, 85)",
    BgBrightYellow:  "rgb(255, 255, 85)",
    BgBrightBlue:    "rgb(85, 85, 255)",
    BgBrightMagenta: "rgb(255, 85, 255)",
    BgBrightCyan:    "rgb(85, 255, 255)",
    BgBrightWhite:   "rgb(255, 255, 255)",
};