import { useContext } from 'react';
import { CatalogContext } from './CatalogContext';
import Requester from './Requester';

const useCatalog = () => {
    const [state, setState] = useContext(CatalogContext);

    const updateCatalog = async () => {
        await setState(currentState => ({
            ...currentState,
            isLoading: true,
            isLoaded: false
        }));

        let networkError = false;
        const response   = await Requester.get(
            '/shopItems'
        ).catch(async (e) => {
            networkError = true;
        });

        if (networkError) {
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        let dataSource = await response.json();
        Requester.debug(response, dataSource);

        if (response.ok === false) {
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        await setState(currentState => ({
            ...currentState,
            isLoading: false,
            isLoaded: true,
            articles: dataSource
        }));

        return dataSource;
    }

    const list = async (forceReload = false) => {
        if (forceReload || (!state.isLoading && !state.isLoaded)) {
            return await updateCatalog();
        }

        return state.articles;
    }

    return {
        updateCatalog,
        list,
    }
};

export default useCatalog;