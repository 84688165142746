import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
} from 'react-native-svg';

const IconISO = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="ISO" fill={color}>
                <Path id="Kugel_unten" d="M119 201h-1c-6-4-10-9-15-15-4-5-8-11-11-18l-1-1H69c12 16 28 28 50 34m26 4v-38H97c5 10 11 18 19 25 7 7 16 12 29 13m53-37v-1h-48v38c14-1 23-6 30-13 8-7 13-15 18-24m28-1s1 0 0 0h-22c-6 14-15 26-27 34 19-6 33-16 45-28l4-6m10-14h6a104 104 0 01-91 58h-6a103 103 0 01-56-18c-15-10-27-24-35-40h6l5 8 1 1h23l-3-9h5l4 9h50v-9h6v9h50l3-9h6l-3 8v1h23l6-9"/>
                <Path id="Kugel_oben" d="M119 11l-8 3c-17 7-31 18-41 31h-1 22c7-13 16-25 28-34m26-3V7l-9 2c-9 2-15 7-21 12-7 7-13 15-18 24h48V8m6 37h47c-4-9-10-18-18-24a49 49 0 00-29-14v38zm53 0h23a98 98 0 00-50-34c12 9 21 21 27 34m37 14h-5l-6-8h-23l3 8h-6l-3-8h-50v8h-6v-8H95l-4 8h-5l3-7v-1H65l-5 8h-6a105 105 0 0191-57h6a128 128 0 0116 2 104 104 0 0174 55"/>
                <Path id="_x39_001" d="M99 248c0 6 4 9 11 9 11 0 13-9 13-22 0-11-2-19-12-19-9 0-13 6-13 15 0 8 5 12 10 12 4 0 6-1 8-4 0 6 0 14-6 14-3 0-5-3-5-5h-6zm17-19c0 5-1 9-6 9-4 0-5-3-5-9 0-4 1-8 5-8s6 3 6 8m16 8c0-12 2-16 6-16s6 4 6 16-2 16-6 16-6-4-6-16m-6 0c0 15 4 20 12 20s12-5 12-20c0-16-4-21-12-21s-12 5-12 21m34 0c0-12 1-16 6-16s5 4 5 16-1 16-5 16-6-4-6-16m-7 0c0 15 5 20 13 20 7 0 12-5 12-20 0-16-5-21-12-21-8 0-13 5-13 21m45 20v-41h-5v1c-1 5-6 6-10 6v4h8v30h7z"/>
                <Path id="ISO" d="M48 145h13V67H48v78zm42-26c0 5 1 9 3 13 1 3 4 6 7 8 3 3 6 4 10 5a47 47 0 0024 1l10-5 7-7c2-3 3-7 3-11a21 21 0 00-4-12 21 21 0 00-11-8 316 316 0 00-23-6l-7-4c-2-2-2-4-2-6l1-5 3-4 5-1 5-1c5 0 9 1 12 3 3 3 4 6 5 11h14a23 23 0 00-9-18c-3-3-6-4-10-5a41 41 0 00-22 0c-3 0-6 2-9 4l-7 7-2 10 1 6 3 6 5 5 8 3a675 675 0 0028 9l2 7-1 4c0 2-1 3-3 4l-5 3-7 1-8-1-7-3-4-5-1-8H90zm136-40l8 7c2 2 3 6 4 9a44 44 0 010 22l-4 10a21 21 0 01-19 9c-4 0-7-1-10-3l-8-6-4-10a44 44 0 010-22c1-3 2-7 4-9a21 21 0 0118-10c5 0 8 1 11 3m-26-11l-12 9c-3 4-6 8-7 13a48 48 0 007 45 35 35 0 0027 12c6 0 12-1 16-3 5-2 9-5 12-9s6-8 7-13a48 48 0 00-7-45 35 35 0 00-28-12c-5 0-11 1-15 3"/>
            </G>
        </Svg>
    );
}

IconISO.defaultProps = {
    color: '#FFF',
};

export default IconISO;