import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
    ClipPath,
    Use,
} from 'react-native-svg';

const IconMasterData = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <Defs>
                <Path id="a" d="M9 45h283v176H9z"/>
            </Defs>
            <ClipPath id="b">
                <Use href="#a" overflow="visible"/>
            </ClipPath>
            <G id="info" fill={color}>
                <Path clipPath={"url(#b)"} d="M80 75a26 26 0 100-51 26 26 0 000 51"/>
                <Path clipPath={"url(#b)"} d="M221 75a26 26 0 100-51 26 26 0 000 51"/>
                <Path fill="none" stroke={color} strokeWidth="11.3386" d="M9 45h283v176H9z"/>
                <Path d="M114 193c0-11 2-47-39-47-42 0-40 36-40 47h79zm-41-55a20 20 0 100-40 20 20 0 000 40"/>
                <Path fill="none" stroke={color} strokeWidth="11.3386" d="M148 103h117"/>
                <Path fill="none" stroke={color} strokeWidth="11.3386" d="M148 138h117"/>
                <Path fill="none" stroke={color} strokeWidth="11.3386" d="M148 170h62"/>
            </G>
        </Svg>
    );
}

IconMasterData.defaultProps = {
    color: '#FFF',
};

export default IconMasterData;