import React, {
    useEffect,
    useState,
} from 'react';
import {
    View,
    StyleSheet,
    FlatList,
} from 'react-native';
import { Paragraph } from './StyledText';
import * as Styles from '../styles/index';
import useItemList from './useItemList';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import WebButton from '../ui/WebButton';
import * as colors from '../styles/colors';
import Toast from '../interfaces/Toast';
import ItemListEntry from './ItemListEntry';
import Requester from './Requester';

const ItemList = (props) => {
    const {
        isLoaded,
        placeOrder,
    } = useItemList();

    const [itemList, updateItemListContent]       = useState();
    const [activeItemListId, setActiveItemListId] = useState(props.activeItemListId);

    useEffect(() => {
            setActiveItemListId(props.activeItemListId);
            getItemsById(props.activeItemListId);
        },
        [props.activeItemListId]
    );

    if (!isLoaded) {
        return null;
    }

    const getItemsById = async (itemListId) => {
        const response   = await Requester.get(
            '/itemList/' + itemListId
        ).catch(() => {
            updateItemListContent(undefined);
            return false;
        });

        let dataSource = await response.json();
        Requester.debug(response, dataSource);

        if (response.ok === false) {
            updateItemListContent(undefined);
            return false;
        }

        updateItemListContent(dataSource);
    }

    if (itemList === undefined || itemList.items.length < 1 ) {
        return (
            <View style={[
                styles.container,
                styles.empty
            ]}>
                <View>
                    <Paragraph style={styles.title}><Trans id={'noEntriesAvailable'}/></Paragraph>
                </View>
            </View>
        );
    }

    const keyExtractor = (item, index) => item.productId.toString();

    const renderItem = ({item, index}) => (
        <ItemListEntry
            key={index}
            item={item}
            activeItemListId={activeItemListId}
        />
    );

    const listHeaderComponent = () => {
        return (
            <View style={[
                Styles.Layouts.row,
                {
                    borderWidth: 1,
                    borderRadius: 5,
                    borderColor: colors.grayNormal,
                    backgroundColor: '#eee',
                    padding: 5,
                }
            ]}>
                <Paragraph style={[styles.tableHeader, styles.action]}/>
                <Paragraph style={[styles.tableHeader, styles.name]}><Trans id={'article'} /></Paragraph>
                <Paragraph style={[styles.tableHeader, styles.productionUnit]}><Trans id={'productionUnitShort'} /></Paragraph>
                <Paragraph style={[styles.tableHeader, styles.quantity]}><Trans id={'quantity'} /></Paragraph>
            </View>
        );
    }

    return (
        <View
            style={{display: 'flex', flex: 1}}
            key={Math.random()}
        >
            <FlatList
                keyExtractor={keyExtractor}
                data={itemList.items}
                extraData={isLoaded}
                renderItem={renderItem}
                ListHeaderComponent={listHeaderComponent}
                stickyHeaderIndices={[0]}

                maxToRenderPerBatch={100}
                initialNumToRender={100}
            />
            <View>
                <View style={[
                    Styles.Layouts.row,
                    {
                        padding: 5,
                    }
                ]}>
                    <Paragraph style={[styles.tableHeader, styles.action]}/>
                    <Paragraph style={[styles.tableHeader, styles.name]}/>
                    <Paragraph style={[styles.tableHeader, styles.productionUnit]}/>
                    <Paragraph style={[styles.tableHeader, styles.factor]}/>
                    <Paragraph style={[styles.tableHeader, styles.quantity]}/>
                </View>
                <View style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'row'}}>
                    <Paragraph style={{
                        color: '#555',
                        fontSize: 14,
                        alignSelf: 'center'
                    }}>
                        { itemList.lastOffer && <Trans id={'itemListLastOrderInfo'} values={{name: '\n', date: l18nDate(itemList.lastOffer)}}/> }
                    </Paragraph>
                    <WebButton
                        size={250}
                        onPress={async () => {
                            const response = await placeOrder(activeItemListId);

                            if (response.ok) {
                                Toast.showSuccess(t`itemListOrderSuccess`);
                            } else {
                                Toast.showError(t`error`);
                            }
                        }}
                    >{t`order`}</WebButton>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 18,
    },
    empty: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableHeader: {
        fontWeight: 'bold'
    },
    name: {
        flex: 3,
        textAlign: 'left',
        marginBottom: 0,
        paddingHorizontal: 5,
    },
    quantity: {
        width: 200,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    productionUnit: {
        flexGrow: 0,
        width: 150,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    factor: {
        flexGrow: 0,
        width: 80,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    automatedOrder: {
        flex: 1,
        width: 50,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    minimalStock: {
        flex: 1,
        width: 50,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    action: {
        flexGrow: 0,
        width: 48,
        alignContent: 'center',
        paddingHorizontal: 10,
    },
});

export default ItemList;