import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
    Rect,
    RadialGradient,
    ClipPath,
    Use,
    Stop,
} from 'react-native-svg';

const IconService = (propsParam) => {
    const { color, text, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G>
                <Defs>
                    <Path id="SVGID_1_" d="M205.923,297.444c0,119.675,97.016,216.688,216.688,216.688c119.674,0,216.688-97.017,216.688-216.688 S542.281,80.757,422.611,80.757S205.923,177.771,205.923,297.444"/>
                </Defs>
                <ClipPath id="SVGID_2_">
                    <Use href="#SVGID_1_"  overflow="visible"/>
                </ClipPath>

                <RadialGradient id="SVGID_3_" cx="2.7766" cy="-0.9309" r="0.85" gradientTransform="matrix(376.068 0 0 478.3502 -621.614 688.1082)" gradientUnits="userSpaceOnUse">
                    <Stop offset="0"      stopColor={"#DA001A"} />
                    <Stop offset="0.8022" stopColor={"#8B0E13"} />
                    <Stop offset="0.967"  stopColor={"#510B0A"} />
                    <Stop offset="1"      stopColor={"#510B0A"} />
                </RadialGradient>
                <Rect x="205.923" y="80.757" clipPath="url(#SVGID_2_)" fill="url(#SVGID_3_)" width="433.377" height="433.377"/>
            </G>
            <G fill={color} id="pictogram">
                <Path fill="#FFFFFF" d="M367.16,290.39c12.898,3.615,13.408-23.522,6-26.644c-1.49-0.62-3.552-0.438-5.29-0.479
				c-0.28,0.12-0.54,0.26-0.71,0.479V290.39z M371.72,246.228c19.47-0.021,32.489,12.25,30.71,32.398
				c-0.78,8.972-6.21,15.188-11.75,19.455c5.71,13.651,12.1,27.894,17.271,41.281h-27.12c-4.479-14.16-8.95-28.33-13.67-42.243
				v42.243c-8.25-0.16-17.12,0.319-24.96-0.24v-87.376c6.34-3.421,14.648-4.87,23.75-5.521L371.72,246.228L371.72,246.228z"/>
                <Path fill="#FFFFFF" d="M608.35,320.164v19.2h-42.24v-90.978c-0.02-0.342-0.028-0.683,0.24-0.722h42v19.199h-17.039
				c-0.551,5.119-0.08,11.279-0.24,16.801c4.33,0.722,10.32-0.229,14.65,0.479v18.258c-4.41,0.55-9.841,0.08-14.65,0.24v17.52
				L608.35,320.164L608.35,320.164z"/>
                <Path fill="#FFFFFF" d="M558.189,330.967c0.371,1.33,1.44,3.34,1.2,4.317c-0.44,1.893-4.409,3.21-6.239,3.84
				c-2.84,0.98-5.391,1.271-8.16,1.683h-6.471c-1.72-0.521-4.479-0.643-6.72-1.439c-31.933-11.41-29.549-91.907,7.92-93.138h4.31
				c6.24,0.72,11.9,2.021,15.36,5.521c-1.44,5.439-2.96,10.79-4.319,16.319c-5.989-2.96-12.811-1.521-15.84,3.12
				c-6.511,10.01-5.591,39.008,2.409,46.098c3.38,3,8.601,2.603,13.2,0.479C556.02,321.945,556.868,326.155,558.189,330.967"/>
                <Path fill="#FFFFFF" d="M500.109,247.668v91.696H475.15v-90.978c-0.021-0.342-0.03-0.683,0.239-0.722L500.109,247.668
				L500.109,247.668z"/>
                <Path fill="#FFFFFF" d="M447.802,247.668h22.549c-6.94,30.729-14.239,61.129-21.36,91.696h-22.31
				c-7.021-30.42-14.647-61.526-21.12-91.696h24.96c2.53,19.789,6.479,38.148,8.159,58.816
				C441.529,286.678,444.591,267.098,447.802,247.668"/>
                <Path fill="#FFFFFF" d="M332.6,320.164v19.2h-42.25v-90.978c-0.021-0.342-0.03-0.683,0.25-0.722h42v19.199h-17.04
				c-0.561,5.119-0.09,11.279-0.25,16.801c4.33,0.562,9.68,0.079,14.41,0.239v18.737h-14.16c-0.561,5.359-0.09,11.76-0.25,17.52
				L332.6,320.164L332.6,320.164z"/>
                <Path fill="#FFFFFF" d="M265.87,283.907c8.158,7.534,17.938,15.086,17.04,31.218c-0.79,14.422-9.25,23.57-24.96,25.682h-7.2
				c-6.939-0.729-13.432-1.92-17.75-5.278v-0.722c1.95-6.05,3.5-12.5,5.271-18.72c4.102,3.327,15.37,6.5,18,0.237
				c2.67-6.317-3.5-11.18-6.479-14.16c-7.16-7.177-14.63-12.496-15.84-25.694c-1.53-16.712,6.948-28.45,24.479-30.24h4.09
				c6.88,0.8,13.38,1.979,17.521,5.521c-1.21,5.521-2.66,10.79-3.84,16.319c-6.21-3.54-20.432-4.6-17.521,6.96
				C259.56,278.558,263.07,281.317,265.87,283.907"/>
            </G>
        </Svg>
    );
}

IconService.defaultProps = {
    color: '#FFF',
    text: '',
};

export default IconService;