import {
    ScrollView,
    StyleSheet,
    TouchableHighlight,
    View,
} from 'react-native';
import * as SVG from '../assets/images/svg/svg';
import React, {
    useContext,
    useState,
    useEffect,
} from 'react';
import * as colors from '../styles/colors';
import { AuthContext } from './AuthContext';
import Requester from './Requester';
import { Paragraph } from './StyledText';
import {
    t,
    Trans
} from '@lingui/macro';
import useAuth from './useAuth';
import * as Styles from '../styles';
import Overlay from '../ui/Overlay';
import AsyncStorage from '../interfaces/AsyncStorage';

const HeaderLeft = ({navigation, ...props}) => {
    const [authState] = useContext(AuthContext);

    const {
        updateUser,
    } = useAuth();

    const [pressingOnHomeElement, setPressingOnHomeElement]           = useState(false);
    const [pressingOnLocationElement, setPressingOnLocationElement]   = useState(false);
    const [isLocationPickerVisible, setLocationPickerVisible]         = useState(false);
    const [locations, setLocations]                                   = useState();

    if (!authState.isUserLoaded) {
        return null;
    }

    if (authState.user.sideMenuPosition === 'right') {
        return null;
    }

    const setInitialLocation = async (_locations) => {
        let _user = await AsyncStorage.getJSONItem('userObject_' + authState.user.role + '_' + authState.user.userId);

        if (_user.location === undefined) {
            if (_locations.length > 1) {
                setLocationPickerVisible(true);
            }

            if (_locations.length === 1) {
                _user.location = _locations[0];
                updateUser(_user);
                AsyncStorage.setItem('userObject_' + _user.role + '_' + _user.userId, JSON.stringify(_user));
            }
        }
    }

    const loadFacilityData = async () => {
        let networkError = false;
        const response   = await Requester.get(
            '/facility/' + authState.user.facilityId
        ).catch((e) => {
            networkError = true;
        });

        if (networkError) {
            return false;
        }

        const _data = await response.json();

        _data.locations.sort(function(a, b) {
            if (a.name < b.name) {
                return -1;
            }

            if (a.name > b.name) {
                return 1;
            }

            return 0;
        });

        setLocations(_data.locations);
        setInitialLocation(_data.locations);
    }

    useEffect(
        () => {
            loadFacilityData();
        },
        []
    );

    let sideMenu = () => {
        navigation.toggleDrawer();
    };

    let selectLocation = (_locationId) => {
        let _user = authState.user;
        _user.location = locations.find(location => location.locationId === _locationId);
        updateUser(_user);
        AsyncStorage.setItem('userObject_' + _user.role + '_' + _user.userId, JSON.stringify(_user));
    }

    let renderLocationPicker = () => {
        if (locations === undefined) {
            return null;
        }

        return (
            <Overlay
                isVisible={isLocationPickerVisible}
                setVisibility={(authState.user.location === undefined) ? null : setLocationPickerVisible}
                titleText={t`areas`}
                width={450}
            >
                <ScrollView
                    style={[{
                        height: 450,
                        backgroundColor: 'lightgray',
                    }]}
                >
                    {
                        locations.map((prop, key) => {
                            return (
                                <TouchableHighlight
                                    key={key}
                                    onPress={() => selectLocation(prop.locationId)}
                                    underlayColor={'transparent'}
                                    style={[
                                        styles.menuEntry,
                                        (authState.user.location?.locationId === prop.locationId ? styles.active : undefined),
                                    ]}
                                >
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{
                                            width: 50,
                                            height: 35,
                                            alignSelf: 'center',
                                        }}>
                                            <SVG.IconLocation
                                                color={authState.user.location?.locationId === prop.locationId ? colors.colorText2 : colors.grayStrong}
                                                text={key + 1}
                                            />
                                        </View>
                                        <View style={{
                                            justifyContent: 'center',
                                            height: 35,
                                            width: 330,
                                        }}>
                                            <Paragraph
                                                style={{
                                                    lineHeight: 18,
                                                    textAlignVertical: 'center',
                                                    color: (authState.user.location?.locationId === prop.locationId)
                                                        ? colors.colorText2 : colors.colorText0
                                                }}
                                            >{prop.name}</Paragraph>
                                        </View>
                                    </View>
                                </TouchableHighlight>
                            )
                        })
                    }
                </ScrollView>
            </Overlay>
        );
    }

    return (
        <View style={[styles.headerContainer, {}]}>
            { renderLocationPicker() }
            <View style={[styles.logoutButton, {marginLeft: 15}]}>
                <TouchableHighlight
                    onPress={() => navigation.navigate('Home')}
                    onPressIn={() => setPressingOnHomeElement(true)}
                    onPressOut={() => setPressingOnHomeElement(false)}
                    underlayColor={'transparent'}
                    style={styles.icon}
                >
                    <SVG.IconHome
                        color={pressingOnHomeElement ? colors.grayNormal : colors.grayStrong}
                    />
                </TouchableHighlight>
                <View style={styles.divider} />
                {locations?.length > 1 && <React.Fragment>
                    <TouchableHighlight
                        onPress={() => setLocationPickerVisible(true)}
                        onPressIn={() => setPressingOnLocationElement(true)}
                        onPressOut={() => setPressingOnLocationElement(false)}
                        underlayColor={'transparent'}
                        style={styles.icon}
                    >
                        <SVG.IconLocation
                            color={pressingOnLocationElement ? colors.grayNormal : colors.grayStrong}
                            {...(!isNaN(parseInt(authState.user.location?.locationId)) ?
                                    {text: locations.findIndex(_location => _location.locationId === authState.user.location?.locationId) + 1} :
                                    undefined
                            )}
                        />
                    </TouchableHighlight>
                    <View style={styles.divider} />
                </React.Fragment>
                }
            </View>
        </View>
    )
}

export default HeaderLeft;

const styles = StyleSheet.create({
    icon: {
        width: 50,
        height: 50,
    },
    titleText: {
    },
    divider: {
        width: 2,
        backgroundColor: 'white',
        marginLeft: 15,
        marginRight: 15,
    },
    headerContainer: {
        height: 50,
    },
    logoutButton: {
        flexDirection: 'row',
    },
    menuEntry: {
        height: 44,
        marginHorizontal: 15,
        marginVertical: 2,
        paddingLeft: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.grayNormal,
        backgroundColor: '#fff',
        justifyContent: 'center',
    },
    active: {
        backgroundColor: colors.colorPrimary1,
    }
});