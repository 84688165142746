import React, {
    useContext,
    useEffect,
    useRef,
} from 'react';
import * as Linking               from 'expo-linking';
import { NavigationContainer }    from '@react-navigation/native';
import { createStackNavigator }   from '@react-navigation/stack';
import { navigationRef }          from '../components/RootNavigation';
import Mainmenu                   from './Mainmenu';
import LoginScreen                from '../screens/LoginScreen';
import { PageTitleText }          from '../components/StyledText';
import { t }                      from '@lingui/macro';
import { Trans }                  from '@lingui/react';
import { AuthContext }            from '../components/AuthContext';
import Log                        from '../components/Log';
import { debug }                  from '../constants/Colors';
import Requester                  from '../components/Requester';
import useAuth                    from '../components/useAuth';
import Footer                     from '../components/Footer';
import Toast                      from '../interfaces/Toast';
import useMenu                    from '../context/useMenu';
import useCart                    from '../components/useCart';
import useInventory               from '../components/useInventory';
import useStorage                 from '../components/useStorage';
import useItemList                from '../components/useItemList';
import moment                     from 'moment';
import PasswordResetScreen        from '../screens/PasswordResetScreen';
import AccountActivationScreen    from '../screens/AccountActivationScreen';
import PasswordResetRequestScreen from '../screens/PasswordResetRequestScreen';
import LoadingScreen              from '../screens/LoadingScreen';

const headerMode  = 'screen';
const headerStyle = {
    headerTintColor: '#333',
    headerStyle: {
        backgroundColor: 'lightgray'
    },
}

const Auth = createStackNavigator();
function AuthStack() {
    const [authState] = useContext(AuthContext);

    const passwordResetOptions = () => ({
        headerTitle: () => <PageTitleText><Trans id={'passwordReset'} /></PageTitleText>,
        ...headerStyle,
        headerTitleAlign: 'center',
        animationTypeForReplace: authState.isSignout ? 'pop' : 'push',
    })

    return (
        <Auth.Navigator headerMode={headerMode}>
            <Auth.Screen
                name={'LoginScreen'}
                component={LoginScreen}
                options={({navigation}) => ({
                    headerTitle: () => <PageTitleText><Trans id={'welcomeToMEIT'} /></PageTitleText>,
                    ...headerStyle,
                    headerTitleAlign: 'center',
                    animationTypeForReplace: authState.isSignout ? 'pop' : 'push',
                })}
            />
            <Auth.Screen
                name={'Loading'}
                component={LoadingScreen}
                options={({navigation}) => ({
                    headerTitle: () => <PageTitleText><Trans id={'welcomeToMEIT'} /></PageTitleText>,
                    ...headerStyle,
                    headerTitleAlign: 'center',
                    animationTypeForReplace: authState.isSignout ? 'pop' : 'push',
                })}
            />
            <Auth.Screen
                name={'PasswordResetRequest'}
                component={PasswordResetRequestScreen}
                options={({navigation}) => passwordResetOptions()}
            />
            <Auth.Screen
                name={'PasswordReset'}
                component={PasswordResetScreen}
                options={({navigation}) => passwordResetOptions()}
            />
            <Auth.Screen
                name={'AccountActivation'}
                component={AccountActivationScreen}
                options={({navigation}) => ({
                    headerTitle: () => <PageTitleText><Trans id={'accountActivation'} /></PageTitleText>,
                    ...headerStyle,
                    headerTitleAlign: 'center',
                    animationTypeForReplace: authState.isSignout ? 'pop' : 'push',
                })}
            />
        </Auth.Navigator>
    )
}

const AppNavigator = (props) => {
    const { signOut }  = useAuth();
    const [authState]  = useContext(AuthContext);
    const routeNameRef = useRef();

    const sendKeepAliveAndCheckSession = async (previousRouteName, currentRouteName, routePayload) => {
        let networkError = false;
        const response   = await Requester.post(
            '/keepalive',
            {
                previousRouteName: previousRouteName,
                currentRouteName: currentRouteName,
                routePayload: navigationRef?.current?.getCurrentRoute()
            },
        ).catch((e) => {
            networkError = true;
        });

        if (networkError) {
            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);

        if (authState.isAuthenticated && response.status === 401) {
            Toast.showInfo(t`sessionTimedOut`);
            console.log('session not valid anymore');
            signOut();
        }
    }

    const config = {
        screens: {
            PasswordReset: 'reset',
            AccountActivation: 'activate',
            Loading: 'Loading',
            Home: 'Home',
            ItemList: 'ItemList',
            Users: 'Users',
            MasterData: 'MasterData',
            News: 'News',
            OfferBook: 'OfferBook',
            Storage: 'Storage',
            UITest: 'UITest',
            MPG: 'MPG',             // subscreen: 'MPG',
            DGUV: 'DGUV',           // subscreen: 'DGUV',
            MyDevices: 'MyDevices',
            MyDevice: 'MyDevice',
            Catalog: 'Catalog',
            Article: 'Article',
            Cart: 'Cart',
            MEIT: 'MEIT',           // subscreen: MEITScreen.PAGE_ABOUT_US
            ContactUs: 'ContactUs', // subscreen: MEITScreen.PAGE_CONTACT
        }
    };

    const prefix = Linking.createURL('/');
    const linking = {
        prefixes: [
            prefix,
            'https://meit.de',
            'https://*.meit.de',
            'meit://'
        ],
        config,
    };

    return (
        <NavigationContainer
            ref={navigationRef}
            linking={linking}
            onReady={() => routeNameRef.current = navigationRef.current.getCurrentRoute().name}
            onStateChange={(state) => {
                const previousRouteName = routeNameRef.current;
                const currentRoute      = navigationRef.current.getCurrentRoute();
                const currentRouteName  = currentRoute.name;

                if (authState.isLoading  === false
                    && previousRouteName !== currentRouteName
                    && currentRouteName  !== 'LoginScreen'
                    && currentRouteName  !== undefined
                ) {
                    const logNavigationStateChange = new Log(debug.FgGreen, debug.BgBlack, 'Local nav state change');
                    logNavigationStateChange.add('navigating from:', previousRouteName);
                    logNavigationStateChange.add('navigating to  :', currentRouteName);
                    logNavigationStateChange.add('getCurrentRoute:', currentRoute);
                    logNavigationStateChange.flush();
                    sendKeepAliveAndCheckSession(
                        previousRouteName,
                        currentRouteName,
                        currentRoute
                    );

                    // The line below uses the expo-firebase-analytics tracker
                    // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
                    // Change this line to use another Mobile analytics SDK
                    // Analytics.setCurrentScreen(currentRouteName);
                }

                routeNameRef.current = currentRouteName;
            }}
        >
            { !authState.isUserLoaded && <AuthStack/> }
            {  authState.isUserLoaded && <Mainmenu/> }
            <Footer />
        </NavigationContainer>
    );
}

export default AppNavigator;
