import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
} from 'react-native-svg';

const IconBed = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="Bed" fill={color}>
                <Path id="Verbindungsstange" d="M1 174v7h299v-7z"/>
                <Path id="Bettdecke" d="M101 104s-6 0-6 7v14s0 6 6 6h154s6 0 6-6v-14s0-7-6-7H101z"/>
                <Path id="Kopfbalken" d="M1 198s0 6 6 6h14s7 0 7-6V88s0-7-7-7H7s-6 0-6 7v110z"/>
                <Path id="Fussbalken" d="M273 198s0 6 6 6h14s7 0 7-6v-84s0-6-7-6h-14s-6 0-6 6v84z"/>
                <Path id="Kopfkissen" d="M64 129s5 4 9 0l10-10s5-5 0-9L54 81s-4-5-9 0L35 91s-4 4 0 9l29 29z"/>
                <Path id="Bettbalken_mit_Kreuz" d="M293 138H7s-6 0-6 7v14s0 6 6 6h286s7 0 7-6v-14s0-7-7-7zm-32 18h-6v6h-7v-6h-6v-7h6v-6h7v6h6v7z"/>
                <Path id="Griff" fill="none" stroke={color} strokeWidth="5.6693" d="M76 69s0 3 4 3h19s4 0 4-3l-7-23s-1-3-4-3h-5s-3 0-4 3l-7 23z"/>
                <Path id="Stange_Griff" fill="none" stroke={color} strokeWidth="5.6693" strokeLinecap="round" d="M99 32H68L13 86"/>
            </G>
        </Svg>
    );
}

IconBed.defaultProps = {
    color: '#FFF',
};

export default IconBed;