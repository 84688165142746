import React from "react";
import { Paragraph } from "../components/StyledText";
import { FlatList, StyleSheet, View } from "react-native";
import { Icon } from "react-native-elements";
import * as Styles from "../styles/index";
import Layout from '../constants/Layout';
import { Trans } from '@lingui/react';

const UserManagementScreen = (props) => {
    const dummyUserList = [
        {
            'name': 'Sara Schnell',
            'userId': 2,
            'position': 'Krankenschwester',
            'department': 'Frauenklinik',
            'role': 'Nutzer',
            'limit': 1000.00,
            'limitWindow': 'Monat',
            'active': true,
        },
        {
            'name': 'Sybille Schwerfällig',
            'userId': 3,
            'position': 'Krankenschwester',
            'department': 'ZNA Chirurgie',
            'role': 'Nutzer',
            'limit': 1000.00,
            'limitWindow': 'Monat',
            'active': true,
        },
        {
            'name': 'Paul Panter',
            'userId': 8,
            'position': 'Pfleger',
            'department': 'Intensiv',
            'role': 'Nutzer',
            'limit': 1000.00,
            'limitWindow': '7 Tage',
            'active': false,
        },
        {
            'name': 'Dr. Jana Jungspunt',
            'userId': 12,
            'position': 'Ärztin',
            'department': 'Jugend- und Kinder',
            'role': 'Nutzer',
            'limit': 5000.00,
            'limitWindow': 'Tag',
            'active': true,
        },
        {
            'name': 'Prof. Dr. Dr. Dominik Direkt',
            'userId': 1,
            'position': 'Klinikdirektor',
            'department': 'Innere',
            'role': 'Manager',
            'limit': false,
            'limitWindow': false,
            'active': true,
        },
    ];

    const userList = dummyUserList;

    return (
        <View>
            <FlatList
                keyExtractor={(item, itemIndex) => `${itemIndex}`}
                data={userList}
                renderItem={({item, index}) =>
                    <View style={[
                        Styles.Layouts.row,
                        // index % 2 === 0 ? Styles.Lists.listItemEven : Styles.Lists.listItemOdd,
                        {
                            justifyContent: 'center'
                        }
                    ]}>
                        <View style={[styles.active, styles.icon]}>
                            <Icon
                                name={item.active ? 'check' : 'times'}
                                type={'font-awesome'}
                                color={item.active ? '#0f0' : '#ff0000'}
                                underlayColor={'#ccc'}
                                size={20}
                                style={[styles.icon]}
                            />
                        </View>
                        <View style={[styles.userName]}>
                            <Paragraph>{item.name}</Paragraph>
                            {/*<Paragraph style={styles.position}>{item.position}</Paragraph>*/}
                            {/*<Paragraph style={styles.department}>{item.department}</Paragraph>*/}
                            {/*<Paragraph style={styles.role}>{item.role}</Paragraph>*/}
                            <Paragraph>
                                {item.limit > 0 ? l18nPrice(item.limit) : 'unbeschränkt'}
                                {item.limitWindow !== false ? '/' + item.limitWindow : ''}
                            </Paragraph>
                        </View>
                        <View style={[styles.active, styles.icon]}>
                            <Icon
                                name={'pencil'}
                                type={'font-awesome'}
                                color={'#000'}
                                underlayColor={'#ccc'}
                                size={40}
                                style={[styles.icon]}
                                onPress={() => {
                                    console.log("Benutzer editieren");
                                }}
                            />
                        </View>
                    </View>
                }
                ListHeaderComponent={(
                    <View style={Styles.Layouts.row}>
                        <Paragraph style={[styles.tableHeader, styles.active, styles.icon]}><Trans id={'active'} /></Paragraph>
                        <Paragraph style={[styles.tableHeader, styles.userName]}><Trans id={'employeeName'} /></Paragraph>
                        {/*<Paragraph style={[styles.tableHeader, styles.position]}>Position</Paragraph>*/}
                        {/*<Paragraph style={[styles.tableHeader, styles.department]}>Abteilung</Paragraph>*/}
                        {/*<Paragraph style={[styles.tableHeader, styles.role]}>Rolle</Paragraph>*/}
                        {/*<Paragraph style={[styles.tableHeader, styles.limit]}>Bestelllimit</Paragraph>*/}
                        <Paragraph style={[styles.tableHeader, styles.active]} />
                    </View>
                )}
                ListFooterComponent={(
                    <View style={Styles.Layouts.row}>
                        <Paragraph style={[styles.tableHeader, styles.active, styles.icon]} />
                        <Paragraph style={[styles.tableHeader, styles.userName]} />
                        {/*<Paragraph style={[styles.tableHeader, styles.position]} />*/}
                        {/*<Paragraph style={[styles.tableHeader, styles.department]} />*/}
                        {/*<Paragraph style={[styles.tableHeader, styles.role]} />*/}
                        {/*<Paragraph style={[styles.tableHeader, styles.limit]} />*/}
                        <View      style={[styles.tableHeader, styles.active]}>
                            <Icon
                                name={'user-plus'}
                                type={'font-awesome'}
                                color={'#000'}
                                underlayColor={'#ccc'}
                                size={40}
                                style={[styles.icon]}
                                onPress={() => {
                                    console.log("neuen Benutzer anlegen");
                                }}
                            />
                        </View>
                    </View>
                )}
                ItemSeparatorComponent={Layout.renderSeparator}
            />
        </View>
    )
};

export default UserManagementScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    tableHeader: {
        fontWeight: 'bold'
    },

    userName: {
        flex: 1,
        alignSelf: 'center',
    },
    position: {
        width: 150,
        textAlign: 'right',
        alignSelf: 'center',
    },
    department: {
        width: 250,
        textAlign: 'right',
        alignSelf: 'center',
    },
    role: {
        width: 150,
        textAlign: 'right',
        alignSelf: 'center',
    },
    limit: {
        width: 150,
        textAlign: 'right',
        alignSelf: 'center',
    },
    icon: {
        marginHorizontal: 2,
    },
    active: {
        width: 50,
        textAlign: 'center',
        alignSelf: 'center',
    },
});
