import { Platform } from 'react-native';

export const container = {
    justifyContent: 'center',
    ...Platform.select({
        ios: {
            shadowColor: 'black',
            shadowOffset: { width: 0, height: -3 },
            shadowOpacity: 0.1,
            shadowRadius: 3,
        },
        android: {
            elevation: 20,
        },
    }),
    alignItems: 'center',
    backgroundColor: '#eee',
    height: 30,
};

export const text = {
    fontSize: 17,
    color: 'rgba(96, 100, 109, 1)',
    textAlign: 'center',
};

export const overlayText = {
    fontSize: 17,
    color: 'rgba(96, 100, 109, 1)',
    textAlign: 'left',
};