import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
} from 'react-native-svg';

const IconLogout = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <Path
                fill="none"
                stroke={color}
                strokeWidth="28.3"
                strokeLinecap="round"
                d="M110 48.6a97 97 0 00-56.2 88.1c0 53.6 43.1 97 96.3 97a96.6 96.6 0 0096.2-97 97 97 0 00-54.7-87.4M150.1 25.9v93.2"
            />
        </Svg>
    );
}

IconLogout.defaultProps = {
    color: '#E1001A',
};

export default IconLogout;