export * from './colorsMonochromatic';
// export * from './colorsMonochromaticWithComplementary';
// export * from './colorsTriad';
// export * from './colorsTriadWithComplementary';

export const colorText0    = '#000';
export const colorText1    = '#ccc';
export const colorText2    = '#fff';

export const grayLight     = '#dadadc';
export const grayNormal    = '#9c9e9f';
export const grayStrong    = '#6d6d6d';
export const grayDark      = '#333';

export const maintenanceOverDueColor      = '#e2001a';
export const maintenanceWarningColor      = '#f29400';
export const maintenanceNothingAmissColor = '#b0c800';

export const getMaintenanceWarningColor = (warningLevel) => {
    switch (warningLevel) {
        case 1:
            return maintenanceOverDueColor;

        case 2:
            return maintenanceWarningColor;

        default:
            return maintenanceNothingAmissColor;
    }
};