import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
} from 'react-native-svg';

const IconSupport = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="SUPPORT" fill={color}>
                <Path id="Kopf" d="M180 75c1 12-2 21-6 29a53 53 0 01-26 26c-4 2-9 3-15 3-11 1-19-3-26-8s-12-11-16-19l15 6 16 5 1 1c3 5 14 5 17 0v-5c-2-2-6-3-10-2h-3l-2 2-3-1-17-5-17-7a67 67 0 014-54c3-7 8-13 15-18a43 43 0 0150 0c7 5 13 12 16 19 4 8 7 17 7 28"/>
                <Path id="Körper_B" d="M230 225h-53l1-1 43-43a145 145 0 019 44"/>
                <Path id="Körper_A" d="M177 123l15 12-32 34-16 17-9 8-1 1h-3l-8 3c-9 5-16 14-17 27H31v-1l1-5c1-10 3-21 6-30 3-11 7-20 13-28l19-22 20-19 2-1c4 7 9 12 15 16a43 43 0 0064-16l6 4"/>
                <Path id="Maulschlüssel" d="M262 141l-30-8-7-26-1-1 2-2 22-25h1v-1a77 77 0 00-38 15c-5 4-8 10-9 17-2 6-3 13-2 21v3l-2 2-6 7-32 33-8 8-8 8-4 4-4 4-1 1h-3a27 27 0 00-20 28c0 8 4 13 8 18a26 26 0 0018 7h2c9 0 14-4 18-8 5-4 8-11 8-19l2-2 2-2 28-28 30-29 2-2 1 1c8 1 16 0 23-2 7-1 14-3 19-7 9-9 13-23 15-38v-1l-26 24zM138 241a13 13 0 110-26 13 13 0 010 26z"/>
                <Path id="Kopfhörer" d="M201 74c1 7 0 14-3 18-1 2-3 4-6 5h-4l-2-3c-3-6-3-17-1-24l-1-5c-1-9-4-18-7-25-2-4-5-9-9-12a56 56 0 00-38-15l-9 2c-11 3-21 8-27 16-7 8-11 21-14 34v5l1 5-1 15c0 2-1 6-3 7s-5 0-6-1c-5-3-7-8-8-14 0-5 1-9 3-13 1-3 4-5 7-6l1-4c2-7 3-13 6-19s6-12 11-17c10-9 23-16 40-17h2a77 77 0 0113 2c11 3 20 8 27 15 10 10 15 23 18 40 5 1 8 6 10 11"/>
            </G>
        </Svg>
    );
}

IconSupport.defaultProps = {
    color: '#FFF',
};

export default IconSupport;