import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
} from 'react-native-svg';

const IconCart = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G fill="none" stroke={color} strokeWidth="22.7">
                <Path d="M40 34l39-3 37 149h138"/>
                <Path strokeLinecap="round" d="M40 34l39-3 37 149h117"/>
            </G>
            <Path id="Korb" fill="none" stroke={color} strokeWidth="22.7" d="M85 56h171v61l-146 28"/>
            <G id="Räder" fill={color}>
                <Path d="M109 237a18 18 0 100-37 18 18 0 000 37M244 237a18 18 0 100-37 18 18 0 000 37"/>
            </G>
        </Svg>
    );
}

IconCart.defaultProps = {
    color: '#FFF',
};

export default IconCart;