import React, {
    useState
} from 'react';
import {
    View,
    StyleSheet,
    SectionList,
    TouchableOpacity,
    Image
} from 'react-native';
import SectionListSearchbar, {
    searchFilterFunction
} from '../components/SectionListSearchbar';
import { useNavigation } from '@react-navigation/native';
import { Paragraph } from '../components/StyledText';
import * as Styles from '../styles';
import Requester from '../components/Requester';
import Layout from '../constants/Layout';
import * as colors from '../styles/colors';
import { Trans } from '@lingui/react';
import useAuth from '../components/useAuth';

const CatalogScreen = (props) => {
    const { signOut } = useAuth();
    const navigation = useNavigation();
    const [itemsSource, setInventoryElements] = useState([]);
    const [itemTypes, setItemTypes] = useState([]);
    const [isCatalogFetched, setCatalogFetched] = useState(false);
    const [items, setItems] = useState([]);
    const [searchableItems, setSearchableItems] = useState([]);
    const [filters, updateFilterButtons] = useState([]);
    const [forceRerender, triggerForceRerender] = useState(false);
    const [searchText, setSearchText] = useState('');

    React.useEffect(() => {
        filterItemsByCategories();
    }, [filters]);

    const handleNetworkError = () => {
    };

    const handleCatalog = async (response) => {
        if (response.ok) {
            let dataSource = await response.json();
            setCatalogFetched(true);
            return dataSource;
        } else {
            signOut();
        }
    };

    const groupCatalog = async (responseJson) => {
        let sections = itemTypes.map(section => {
            return {
                itemType : section.item_type, // → removed in backend
                data : []
            }
        });

        const dataSource = responseJson.reduce(function(sections, item) {
            let section = sections.find(section => item.itemTypes.includes(section.itemTypes));
            section.data.push(item);
            return sections;
        }, sections);
        console.log('dataSource', dataSource)

        // const dataSource = responseJson.reduce(function(sections, item) {
        //     let section = sections.find(section => section.itemRelationType === item.itemRelationType);
        //
        //     console.log('section', section)
        //
        //     if (!section) {
        //         section = {
        //             itemType : item.itemRelationType,
        //             data : []
        //         };
        //
        //         sections.push(section);
        //     }
        //
        //     section.data.push(item);
        //     return sections;
        // }, []);

        setInventoryElements(dataSource);
        setItems(dataSource);
        return dataSource;
    };

    const initFilters = async (dataSource) => {
        let tmp = [];

        dataSource.map((section) =>
            tmp[section.itemTypes] = true
        );

        updateFilterButtons(tmp);
    };

    const loadItemTypes = async () => {
        let networkError = false;
        const response   = await Requester.get(
            '/products/itemTypes'
        ).catch((e) => {
            networkError = true;
        });

        if (networkError) {
            navigation.goBack();

            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);
        setItemTypes(data);
    }

    if (!isCatalogFetched) {
        loadItemTypes();

        Requester.get('/products')
            .then(handleCatalog)
            .then(groupCatalog)
            .then(initFilters)
            .catch(handleNetworkError)
        ;
    }

    const renderSeparator = (item) => {
        if (item.leadingItem.offerState) {
            return Layout.renderSeparator();
        }

        return null;
    };

    const toggleFilterButtonState = (button) => {
        filters[button] = !filters[button];
        updateFilterButtons(filters);
        triggerForceRerender(!forceRerender);
        filterItemsByCategories();
    };

    function filterItemsByCategories() {
        let tempObj = [];
        //Loop over keys and filter if key value is true and add to temp object
        Object.keys(filters).filter((o)=> {
            if (filters[o]) {
                tempObj.push(o);
            }
        });

        let searchableItemSet = itemsSource.filter(section => {
            return tempObj.includes(section.itemTypes);
        });

        setSearchableItems(searchableItemSet);
        setItems(searchableItemSet);
        searchFilterFunction(searchText, {searchText, setSearchText, searchableItems, setItems});
    }

    const header = () => {
        let state = forceRerender;
        return (
            <View>
                {SectionListSearchbar({searchText, setSearchText, searchableItems, setItems})}
                <View style={[Styles.Layouts.row, {
                    alignItems: 'center',
                    marginTop: 7,
                }]}>
                    <Paragraph><Trans id={'filter'} /></Paragraph>
                    {
                        // itemsType.map((section) =>
                        //     <TouchableOpacity
                        //         key={section.itemTypes}
                        //         style={[styles.filterButton, filters[section.itemTypes] ? Styles.Layouts.buttonPressed : Styles.Layouts.buttonNotPressed]}
                        //         onPress={() => toggleFilterButtonState(section.itemTypes)}
                        //     >
                        //         <View>
                        //             <Paragraph style={[styles.buttonText, {color: colors.colorText2}]}>{`${section.itemTypes}`}</Paragraph>
                        //         </View>
                        //     </TouchableOpacity>
                        // )
                    }
                </View>
            </View>
        )
    };

    const generateSectionHeader = (itemType) => {
        let text;

        switch (itemType) {
            case 'device':
                text = 'Geräte';
                break;

            case 'equipment':
                text = 'Zubehör';
                break;

            case 'consumable':
                text = 'Verbrauchsmaterialien';
                break;

            default:
                text = itemType;
        }

        return (
            <Paragraph style={styles.sectionHeader}>{text}</Paragraph>
        );
    };

    return (
        <View style={styles.container}>
            {/*https://github.com/gitim/react-native-sortable-list/issues/88*/}
            {header()}
            <View style={{marginTop: 7}}>
                <SectionList
                    keyExtractor={(item, index) => `${index}`}
                    sections={items}
                    extraData={filters}
                    renderSectionHeader={
                        ({section: {itemType}}) => (
                            generateSectionHeader(itemType)
                        )
                    }
                    renderItem={({ item, index }) => {
                        if (item.offerState) {
                            return (
                                <TouchableOpacity
                                    style={styles.button}
                                    onPress={() => {navigation.navigate('Article', { showTotalPrice: true, device: item, catalog: [] })}}
                                >
                                    <View style={[
                                        Styles.Layouts.row,
                                        // index % 2 === 0 ? Styles.Lists.listItemEven : Styles.Lists.listItemOdd,
                                        Styles.Lists.imageList
                                    ]}>
                                        {
                                            (item.images.length === 0 ?
                                                <Image
                                                    style={{width: 150, height: 150}}
                                                    source={require('../assets/images/placeholder.jpg')}
                                                    resizeMode={'contain'}
                                                />
                                                :
                                                <Image
                                                    style={{width: 150, height: 150}}
                                                    source={{uri: Requester.getImageURL('/file' + item.images[0].path + '/' + item.images[0].name)}}
                                                    loadingIndicatorSource={require('../assets/images/placeholder.jpg')}
                                                    resizeMode={'contain'}
                                                />
                                            )
                                        }
                                        <View style={styles.item}>
                                            <Paragraph style={styles.title}>{`${item.name}`}</Paragraph>
                                            <Paragraph style={styles.subtitle}>{` ${l18nPrice(item.netPrice)}`}</Paragraph>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            );
                        }

                        return null;
                    }}
                    ItemSeparatorComponent={renderSeparator}
                    // ListHeaderComponent={header}
                    // ListHeaderComponent={SectionListSearchbar({searchableItems, setItems})}
                    maxToRenderPerBatch={20}
                    // initialNumToRender={0}
                    windowSize={21}
                    enableEmptySections={true}
                    // getItemCount={10}
                    // getItem={0}
                    // updateCellsBatchingPeriod={0}
                />
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    sectionHeader: {
        paddingTop: 2,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 2,
        fontSize: 25,
        fontWeight: 'bold',
        color: colors.colorText2,
        backgroundColor: colors.colorComplement0,
    },
    item: {
        marginLeft: 20,
        marginBottom: 10
    },
    title: {

    },
    subtitle: {
        color: 'gray'
    },

    filterButton: {
        ...Styles.Layouts.button,
    },
    filterActive: {
        color: colors.colorText2
    },
    filterDisabled: {
        color: colors.colorText1,
        borderColor: 'black',
        backgroundColor: colors.colorComplement2,

    }
});

export default CatalogScreen;