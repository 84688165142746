global.l18nPrice = (priceInCent) => {
    if (!priceInCent) {
        priceInCent = 0;
    }

    return new Intl.NumberFormat(
        'de-DE',
        {
            style: 'currency',
            currency: 'EUR'
        }
    ).format(priceInCent / 100);
}
