import React, {
    useState
} from 'react';
import {
    StyleSheet,
    TouchableWithoutFeedback,
    View
} from 'react-native';
import { Paragraph } from '../components/StyledText';
import * as colors from '../styles/colors';
import * as UIStyles from './UIStyles';
import { LinearGradient } from 'expo-linear-gradient';

const WebButton = (propsParam) => {
    const { size, height, disabled, ...props } = propsParam;
    const [pressed, setPressed] = useState(false);
    const linearGradient = disabled
        ? ['rgb(170, 170, 170)', 'rgba(45, 80, 156, 0.2)']
        : ['rgba(0, 171, 238, 1)', 'rgba(45, 80, 156, 1)'];

    return (
        <View style={[
            height === 'normal' ? UIStyles.buttonBG : UIStyles.buttonBGSmall,
            {
                width: size !== 'auto' ? size : '100%',
                marginVertical: 5,
                cursor: 'pointer',
            }
        ]}>
            <TouchableWithoutFeedback
                onPressIn={() => {
                    setPressed(true);

                    if (!disabled && typeof props.onPressIn === 'function') {
                        props.onPressIn();
                    }
                }}
                onPressOut={() => {
                    setPressed(false);

                    if (!disabled && typeof props.onPressOut === 'function') {
                        props.onPressOut();
                    }
                }}
                onPress={() => {
                    if (!disabled && typeof props.onPress === 'function') {
                        props.onPress();
                    }
                }}
                onLongPress={() => {
                    if (!disabled && typeof props.onLongPress === 'function') {
                        props.onLongPress();
                    }
                }}
            >
                <View
                    style={[
                        size === 'auto' ? UIStyles.button : UIStyles.buttonSmall,
                        // pressed ? UIStyles.buttonPressed : null,
                        {
                            flexDirection: 'row',
                            alignItems: 'center'
                        }
                    ]}
                >
                    <LinearGradient
                        colors={pressed ? linearGradient.reverse() : linearGradient}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            borderRadius: 4,
                        }}
                    />
                    {props.icon &&
                        <View style={[
                            pressed ? styles.buttonTextPressed : styles.buttonTextNotPressed,
                            {
                                width: 50,
                                height: '85%',
                            }
                        ]}>
                            {props.icon}
                        </View>
                    }
                    <Paragraph
                        style={[
                            styles.buttonText,
                            pressed ? styles.buttonTextPressed : styles.buttonTextNotPressed,
                        ]}
                    >
                        {props.children}
                    </Paragraph>
                </View>
            </TouchableWithoutFeedback>
        </View>
    )
}

WebButton.defaultProps = {
    size: 'auto',
    height: 'normal',
    disabled: false,
}

export default WebButton;

const styles = StyleSheet.create({
    buttonText: {
        fontSize: 20,
        zIndex: 1,
    },
    buttonTextNotPressed: {
        color: colors.colorText2,
    },
    buttonTextPressed: {
        color: colors.colorText2,
        top: 1,
    },
});