import React from 'react';
import assets from 'react-native-toast-message/src/assets/index';
import colors from 'react-native-toast-message/src/colors/index';
import RnToast, {
    BaseToast,
} from 'react-native-toast-message';

export default class Toast {
    static init() {
        const toastConfig = {
            success: ({ ...props }) => (
                <BaseToast
                    { ...props }
                    style={{ borderLeftColor: colors.mantis }}
                    leadingIcon={assets.icons.success}
                    trailingIcon={assets.icons.close}
                    text2Style={{
                        fontSize: 20,
                        fontWeight: 'bold'
                    }}
                    onTrailingIconPress={() => {
                        RnToast.getRef().hide();
                    }}
                />
            ),
            info: ({ ...props }) => (
                <BaseToast
                    { ...props }
                    style={{ borderLeftColor: colors.lightSkyBlue }}
                    leadingIcon={assets.icons.info}
                    trailingIcon={assets.icons.close}
                    text2Style={{
                        fontSize: 18,
                        fontWeight: 'bold'
                    }}
                    onTrailingIconPress={() => {
                        RnToast.getRef().hide();
                    }}
                />
            ),
            error: ({ ...props }) => (
                <BaseToast
                    { ...props }
                    style={{ borderLeftColor: colors.blazeOrange }}
                    leadingIcon={assets.icons.error}
                    trailingIcon={assets.icons.close}
                    text2Style={{
                        fontSize: 20,
                        fontWeight: 'bold'
                    }}
                    onTrailingIconPress={() => {
                        RnToast.getRef().hide();
                    }}
                />
            ),
        };

        return <RnToast
            config={toastConfig}
            ref={(ref) => RnToast.setRef(ref)}
        />
    }

    static show(text, options) {
    }

    static showSuccess(text) {
        RnToast.show({
            type: 'success',
            position: 'top',
            text2: text,
            visibilityTime: 4000,
            autoHide: true,
            topOffset: 100,
        });
    }

    static showInfo(text) {
        RnToast.show({
            type: 'info',
            position: 'top',
            text2: text,
            visibilityTime: 3000,
            autoHide: true,
            topOffset: 100,
        });
    }

    static showError(text) {
        RnToast.show({
            type: 'error',
            position: 'top',
            text2: text,
            visibilityTime: 3000,
            autoHide: true,
            topOffset: 100,
        });
    }
}
