import React, {
    useState
} from 'react';
import {
    View,
    StyleSheet,
    ScrollView,
    Button,
} from 'react-native';
import { Paragraph } from '../components/StyledText';
import { Input } from 'react-native-elements';
import * as Styles from '../styles/index';
import Requester from '../components/Requester';
import * as colors from '../styles/colors';
import WebButton from '../ui/WebButton';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import EmailInput from '../ui/EmailInput';
import { useNavigation } from '@react-navigation/native';

const SettingsScreen = (props) => {
    const navigation = useNavigation();
    const [, rerender] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [isFacilityDataLoaded, setFacilityDataLoaded] = useState(false);
    const [data, setData] = useState({
        organization: 'Städtisches Klinikum Karlsruhe gGmbH',
        street: 'Moltkestraße 90',
        addition: '',
        cityCode: '76133',
        cityName: 'Karlsruhe',
        contactPersonKey: '2',
        phone: '+49 (0)721 974-999',
        email: '',
        contactReplacements: [
            {
                key: '2',
                name: 'Dominik Direkt',
            },
            {
                key: '13',
                name: 'Jana Jungspunt',
            }
        ]
    });
    const [editable, setEditable] = useState(false);
    const [buttonCaption, setButtonCaption] = useState('Daten bearbeiten');

    const loadFacilityData = async () => {
        setFacilityDataLoaded(true);
        let networkError = false;
        const response   = await Requester.get(
            '/facilities'
        ).catch((e) => {
            networkError = true;
        });

        if (networkError) {
            navigation.goBack();

            return false;
        }

        const _data = await response.json();
        setData(_data);
    }

    if (!isFacilityDataLoaded) {
        loadFacilityData();
    }

    const toggleEditMode = () => {
        switch (editable) {
            case true:
                saveChanges();
                disableEditMode();
                break;

            case false:
                enableEditMode();
                break;
        }
    };

    const enableEditMode = () => {
        setEditable(true);
        setButtonCaption('Änderungen übernehmen');
    };

    const disableEditMode = () => {
        setEditable(false);
        setButtonCaption('Daten bearbeiten');
    };

    const saveChanges = () => {
        //@TODO Saving to back end
        console.log('sending data to server', data);
    };

    const updateData = (prop, value) => {
        data[prop] = value;
        setData(data);
        rerender(Math.random()); // tricking the RN to re-render after data update
    };

    return (
        <View style={styles.container}>
            <ScrollView>
                <View style={styles.boxContainer}>
                    <View style={styles.box}>
                        <Paragraph style={styles.headline}><Trans id={'address'} /></Paragraph>
                        <Input
                            value={data.address}
                            keyboardType={'default'}
                            onChangeText={(value) => {
                                updateData('address', value);
                            }}
                            onFocus={() => setErrorMessage('')}
                            placeholder={t`addressPlaceholder`}
                            placeholderTextColor={'#aaa'}
                            inputContainerStyle={[styles.textInput, {height: 160}]}
                            inputStyle={{color: colors.colorText2}}
                            multiline={true}

                            leftIcon={{
                                type: 'font-awesome',
                                name: 'ambulance',
                                color: colors.colorText2,
                            }}
                            leftIconContainerStyle={{marginRight: 10}}

                            editable={editable}
                            underlineColorAndroid='transparent'
                        />

                        <Paragraph style={styles.headline}><Trans id={'contactPerson'} /></Paragraph>
                        {/*<Picker*/}
                        {/*        selectedValue={data.contactReplacements.filter(*/}
                        {/*                (entry) => entry.key === data.contactPersonKey*/}
                        {/*            ).name*/}
                        {/*        }*/}
                        {/*        style={styles.textInput}*/}
                        {/*        inputContainerStyle={styles.textInput}*/}
                        {/*        onValueChange={(value) => {*/}
                        {/*            updateData('contactPersonKey', value);*/}
                        {/*        }}*/}
                        {/*        enabled={editable}*/}
                        {/*>*/}
                        {/*    {*/}
                        {/*        data.contactReplacements.map((prop, key) => {*/}
                        {/*            return (*/}
                        {/*                <Picker.Item key={key}*/}
                        {/*                             label={prop.name}*/}
                        {/*                             value={prop.key}*/}
                        {/*                />*/}
                        {/*            )*/}
                        {/*        })*/}
                        {/*    }*/}
                        {/*</Picker>*/}
                        <Paragraph style={styles.headline}><Trans id={'contact'} /></Paragraph>
                        <Input
                            value={data.phone}
                            keyboardType={'phone-pad'}
                            onChangeText={(value) => {
                                updateData('phone', value);
                            }}
                            onFocus={() => setErrorMessage('')}
                            placeholder={t`yourPhoneNumberPlaceholder`}
                            placeholderTextColor={'#aaa'}
                            inputContainerStyle={styles.textInput}
                            inputStyle={{color: colors.colorText2}}

                            leftIcon={{
                                type: 'font-awesome',
                                name: 'phone',
                                color: colors.colorText2,
                            }}
                            leftIconContainerStyle={{marginRight: 10}}

                            label={t`yourTelephoneNumber`}
                            labelStyle={{color: colors.colorSecondary24}}
                            editable={editable}
                            underlineColorAndroid='transparent'
                        />
                        <EmailInput
                            email={data.contactMail}
                            onChangeText={(email) => updateData('contactMail', email)}
                            placeholder={t`emailAddressPlaceholderForContactPerson`}
                            editable={editable}
                        />
                        <View style={styles.editModeButton}>
                            <WebButton onPress={toggleEditMode}>{buttonCaption}</WebButton>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </View>
    );
};

SettingsScreen.navigationOptions = {
  title: 'Einstellungen',
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        marginLeft: 25,
        marginRight: 25
    },
    headline: {
        fontSize: 24,
        // fontWeight: 'bold', gafata is not able to be bold. Looking for alternative!
        marginTop: 7,
    },

    textInput: {
        ...Styles.Layouts.input,
    },
    editModeButton: {
        marginTop: 7,
    }
});

export default SettingsScreen;