import * as colors from '../styles/colors';

/*
 *      INPUTS
 */
/**
 *
 * @type {{borderColor: string, backgroundColor: string, borderRadius: number, marginVertical: number, borderWidth: number, width: string, borderBottomWidth: number}}
 */
export const inputContainer = {
    width: '100%',
    marginVertical: 0,
    borderRadius: 5,
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: colors.colorPrimary1,
    backgroundColor: '#fff',
}

/**
 *
 * @type {{borderColor: string, backgroundColor: string, borderRadius: number, marginVertical: number, borderWidth: number, width: string, borderBottomWidth: number}}
 */
export const inputContainerFocused = {
    ...inputContainer,
    borderColor: '#f60',
}

/**
 *
 * @type {{borderColor: string, backgroundColor: string, borderRadius: number, marginVertical: number, borderWidth: number, width: string, borderBottomWidth: number}}
 */
export const inputContainerError = {
    ...inputContainer,
    borderColor: '#f00',
}

/**
 *
 * @type {{borderColor: string, backgroundColor: string, borderRadius: number, marginVertical: number, borderWidth: number, width: string, borderBottomWidth: number}}
 */
export const inputContainerDisabled = {
    ...inputContainer,
    backgroundColor: colors.grayNormal,
}

/**
 *
 * @type {string}
 */
export const placeholderTextColor = colors.grayStrong;

/**
 *
 * @type {string}
 */
export const placeholderTextColorDisabled = colors.colorText2;


/*
 *      CHECKBOX
 */

/**
 *
 * @type {{borderColor: string, backgroundColor: string, borderRadius: number, marginVertical: number, borderWidth: number}}
 */
export const checkBoxContainer = {
    marginVertical: 0,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: colors.colorPrimary1,
    backgroundColor: '#fff',
}

/**
 *
 * @type {{borderColor: string, backgroundColor: string, borderRadius: number, marginVertical: number, borderWidth: number}}
 */
export const checkBoxContainerDisabled = {
    ...checkBoxContainer,
    backgroundColor: colors.grayNormal,
}

/**
 *
 * @type {{color: string}}
 */
export const checkBoxText = {
    color: colors.colorText0,
}

/**
 *
 * @type {{color: string}}
 */
export const checkBoxTextDisabled = {
    color: colors.colorText2,
};


/*
 * BUTTONS
 */

/**
 *
 * @type {number}
 */
const buttonRadius = 5;
/**
 *
 * @type {number}
 */
const buttonRadiusSmall = 5;

/**
 *
 * @type {{alignItems: string, borderRadius: number, flex: number, borderWidth: number, justifyContent: string}}
 */
export const button = {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: buttonRadius,
};

/**
 *
 * @type {{alignItems: string, borderRadius: number, flex: number, borderWidth: number, justifyContent: string}}
 */
export const buttonSmall = {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: buttonRadiusSmall,
};

/**
 *
 * @type {{borderColor: string, backgroundColor: string}}
 */
export const buttonPressed = {
    borderColor: colors.colorPrimary3,
    backgroundColor: colors.colorPrimary4,
};

/**
 *
 * @type {{borderColor: string, backgroundColor: string}}
 */
export const buttonNotPressed = {
    borderColor: colors.colorPrimary1,
    backgroundColor: colors.colorPrimary2,
};

/**
 *
 * @type {{padding: number, borderRadius: number, borderWidth: number, height: number}}
 */
export const buttonBG = {
    height: 44,
    padding: 1,
    borderWidth: 0,
    borderRadius: buttonRadius,
};

/**
 *
 * @type {{padding: number, borderRadius: number, borderWidth: number, height: number}}
 */
export const buttonBGSmall = {
    height: 35,
    padding: 1,
    borderWidth: 0,
    borderRadius: buttonRadiusSmall,
};