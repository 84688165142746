import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
} from 'react-native-svg';

const IconMyDevices = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="myDevices" fill={color}>
                <Path d="M192.07 25.89a19.07 19.07 0 100 38.14 19.07 19.07 0 000-38.14zm0 26.83a7.76 7.76 0 110-15.52 7.76 7.76 0 010 15.52zM233.39 175.83h9.77v26.59h-9.77z"/>
                <Path d="M192.07 47.82a2.86 2.86 0 100-5.72 2.86 2.86 0 000 5.72M240.02 9.68a19.07 19.07 0 100 38.14 19.07 19.07 0 000-38.15zm0 26.82a7.76 7.76 0 110-15.51 7.76 7.76 0 010 15.51z"/>
                <Path d="M240.02 31.6a2.86 2.86 0 100-5.71 2.86 2.86 0 000 5.71"/>
                <Path fill="none" stroke={color} strokeWidth="6" d="M208.81 48.63s37.33 9.1 45.96 17.5c8.64 8.4 19.83 17.5 7.23 45.5-12.6 27.98-21.46 51.07-15.86 55.27s22.84 4.66 19.13 21c-1.17 5.13-11.2 5.13-14.93 5.13h-9.56"/>
                <Path fill="none" stroke={color} strokeWidth="6" d="M251.74 40.47s17.03 21 25.2 32.66c8.16 11.67 17.72 22.4 14.23 39.43-3.5 17.03-12.06 26.84-16.1 33.11-11.43 17.73-12.37 32.43-16.1 35-3.73 2.56-17.03 1.63-17.03 1.63"/>
                <Path d="M225.03 69.63H24.68s-9.33 0-9.33 9.33v133.26s0 9.33 9.33 9.33h200.35s9.33 0 9.33-9.33V78.96s0-9.33-9.33-9.33zm-8.22 33.83h9.33v16.1h-9.33v-16.1zm0 25.02h9.33v16.08h-9.33v-16.08zm-12.78-25.02h9.33v16.1h-9.33v-16.1zm0 25.02h9.33v16.08h-9.33v-16.08zm-7.7 72.54c0 4.66-4.67 4.66-4.67 4.66H37.63c-4.66 0-4.66-4.66-4.66-4.66V89.29c0-4.67 4.66-4.67 4.66-4.67h154.03c4.67 0 4.67 4.67 4.67 4.67v111.73zm18.78-12.89a11.43 11.43 0 110-22.86 11.43 11.43 0 010 22.86z"/>
                <Path d="M219.67 174.65a2.16 2.16 0 100-4.3 2.16 2.16 0 000 4.3M127.63 229.42H27.54s2.64 10.34 6.53 14.23c4.67 4.67 17.03 4.67 17.03 4.67h80.03l-3.5-18.9zm-49.88 14.06H46.38v-9.21h31.38v9.2zm7.7-5.31a2.16 2.16 0 110-4.31 2.16 2.16 0 010 4.3zm8.7 0a2.16 2.16 0 110-4.31 2.16 2.16 0 010 4.3zm8.68 0a2.16 2.16 0 110-4.31 2.16 2.16 0 010 4.3z"/>
                <Path d="M124.13 229.42l-2.34 18.9h78.85s12.37 0 17.04-4.67c3.89-3.89 6.53-14.23 6.53-14.23H124.13zm58.55 14.81a2.15 2.15 0 110-4.3 2.15 2.15 0 010 4.3zm0-6.06a2.15 2.15 0 110-4.31 2.15 2.15 0 010 4.3zm8.69 6.06a2.15 2.15 0 110-4.3 2.15 2.15 0 010 4.3zm0-6.06a2.16 2.16 0 110-4.31 2.16 2.16 0 010 4.3zm8.7 6.06a2.15 2.15 0 11-.01-4.3 2.15 2.15 0 010 4.3zm0-6.06a2.15 2.15 0 11-.01-4.31 2.15 2.15 0 010 4.3z"/>
            </G>
        </Svg>
    );
}

IconMyDevices.defaultProps = {
    color: '#FFF',
};

export default IconMyDevices;