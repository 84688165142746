import React from 'react';
import { SearchBar } from 'react-native-elements';
import * as colors from '../styles/colors';
import { t } from '@lingui/macro';

//@TODO This doesn't seem right… commit 2019-09-29 18:23
export function searchFilterFunction(text, props) {
    let newData = [];

    props.searchableItems.forEach(function(section) {
        let tmp = [];
        let sectionMatches = section.data.filter(item => {
            const itemData = `${item.name.toUpperCase()}`;
            const textData = text.toUpperCase();
            return itemData.includes(textData);
        });

        tmp['data'] = sectionMatches;

        newData.push(tmp);
    });

    props.setSearchText(text);
    props.setItems(newData);
};

const SectionListSearchbar = (props) => {
    return (
        <SearchBar
            underlineColorAndroid={colors.colorText2}
            selectionColor={colors.colorText2}
            containerStyle={{
                backgroundColor: colors.colorPrimary0,
                borderWidth: 0,
                borderRadius: 20,
                borderBottomColor: 'transparent',
                borderTopColor: 'transparent'
            }}
            inputContainerStyle={{
                backgroundColor: colors.colorPrimary0,
            }}
            onChangeText={text => searchFilterFunction(text, props)}
            onClear={text => searchFilterFunction('', props)}
            autoCorrect={false}
            placeholder={t`searchWithEllipsis`}
            searchIcon={{
                size: 30,
                color: colors.colorText2,
                backgroundColor: colors.colorPrimary0,
            }}
            value={props.searchText}
        />
    );
};

export default SectionListSearchbar;