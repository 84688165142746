import React, {
    useContext, 
} from 'react';
import {
    View,
    StyleSheet,
} from 'react-native';
import * as Styles from '../styles/index';
import Cart from '../components/Cart';
import { AuthContext } from '../components/AuthContext';

const CartScreen = (props) => {
    const [authState] = useContext(AuthContext);

    return (
        <View style={styles.container}>
            <Cart
                locationId={authState.user.location?.locationId}
            />
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 18,
    },
    location: {
        ...Styles.Layouts.row,
        alignItems: 'center',
    },
});

export default CartScreen;