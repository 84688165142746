import React from 'react';
import { Newsbox } from "../components/Newsbox";
import { StyleSheet, View } from "react-native";

const NewsScreen = (props) => {
    return (
        <View style={styles.container}>
            <Newsbox showNews={true}/>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    }
});

export default NewsScreen;