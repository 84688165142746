import React, {
    useState
} from 'react';
import {
    Button,
    StyleSheet,
    View,
} from 'react-native';
import * as Linking from 'expo-linking';
import { Camera } from 'expo-camera';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Paragraph } from './StyledText';
import { useNavigation } from '@react-navigation/native';
import { Trans } from '@lingui/react';
import Toast from '../interfaces/Toast';
import { t } from '@lingui/macro';

const Scanner = (props) => {
    const navigation = useNavigation();
    const [scanned, setScanned] = useState(false);
    const [hasCameraPermission, setCameraPermission] = useState(false);

    const handleBarCodeScanned = ({ type, data }) => {
        setScanned(true);

        if (type !== BarCodeScanner.Constants.BarCodeType.qr) {
            Toast.showError(t`QRCodeInvalid`);
            props.scanFinished();
            return false;
        }

        const linkObject = Linking.parse(data);

        if (linkObject.scheme !== 'meit') {
            Toast.showError(t`QRCodeInvalid`);
            props.scanFinished();
            return false;
        }

        //empty string can be split, split() on null not possible
        if (linkObject.path === null) {
            Toast.showError(t`QRCodeInvalid`);
            props.scanFinished();
            return false;
        }

        let parts = linkObject.path.split('/');

        switch (parts[0]) {
            case 'MyDevice':
                const inventoryItemId = parseInt(parts[1]);

                navigation.navigate(
                    'MyDevice',
                    {
                        inventoryItemId: inventoryItemId
                    }
                );

                props.scanFinished();
                return true;
        }

        Toast.showError(t`QRCodeInvalid`);
        props.scanFinished();
        return false;
    };

    const getPermissionsAsync = async () => {
        const { status } = await Camera.requestPermissionsAsync();
        setCameraPermission(status === 'granted');
    };

    getPermissionsAsync();

    if (hasCameraPermission === null) {
        return <Paragraph><Trans id={'cameraPermissionRequesting'} /></Paragraph>;
    }

    if (hasCameraPermission === false) {
        return <Paragraph><Trans id={'cameraPermissionDenied'} /></Paragraph>;
    }

    return (
        <View style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
        }}>
            {hasCameraPermission &&
                <BarCodeScanner
                    barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
                    onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                    style={StyleSheet.absoluteFillObject}
                />
            }
            {scanned && (
                <Button title={'Tap to Scan Again'} onPress={() => setScanned(false)} />
            )}
        </View>
    )
};

export default Scanner;