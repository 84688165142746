import {
    useContext,
} from 'react';
import { InventoryContext } from './InventoryContext';
import { AuthContext } from './AuthContext';
import Requester from './Requester';

const useInventory = () => {
    const [authState]       = useContext(AuthContext);
    const [state, setState] = useContext(InventoryContext);

    const fetchProductImageList = async () => {
        let networkError = false;
        const response   = await Requester.get(
            '/directories/public/product'
        ).catch(async (e) => {
            networkError = true;
        });

        if (networkError) {
            return false;
        }

        const dataSource = await response.json();
        Requester.debug(response, dataSource);

        if (response.ok === false) {
            return false;
        }

        let productImages = [];

        dataSource.forEach( file =>
            productImages[file['name']] = (file['content']?.find(f => f['isDir'] && f['name'] === 'Bilder')?.content ?? [])
        );

        await setState(currentState => ({
            ...currentState,
            productImages: productImages
        }));
    }

    const fetchInventoryImageList = async () => {
        let networkError = false;
        const response   = await Requester.get(
            '/directories/' + authState.user.facilityId + '/Bestand'
        ).catch(async (e) => {
            networkError = true;
        });

        if (networkError) {
            return false;
        }

        const dataSource = await response.json();
        Requester.debug(response, dataSource);

        if (response.ok === false) {
            return false;
        }

        let inventoryImages = [];

        dataSource.forEach( file =>
            inventoryImages[file['name']] = (file['content']?.find(f => f['isDir'] && f['name'] === 'Bilder')?.content ?? [])
        );

        await setState(currentState => ({
            ...currentState,
            inventoryImages: inventoryImages
        }));
    }

    const updateInventory = async () => {
        if (state.isLoading) {
            new Promise(resolve => setTimeout(resolve, 750, updateInventory)).then(value => value);
        }

        await setState(currentState => ({
            ...currentState,
            isLoading: true,
            isLoaded: false
        }));

        let networkError = false;
        const response   = await Requester.get(
            '/facility/' + authState.user.facilityId + '/inventoryLists'
        ).catch(async (e) => {
            networkError = true;
        });

        if (networkError) {
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        const dataSource = await response.json();
        Requester.debug(response, dataSource);

        if (response.ok === false) {
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        await fetchProductImageList();
        await fetchInventoryImageList();

        let indexedInventory = {};

        Object.values(dataSource).map((inventoryList) => {
            if (!inventoryList.hasOwnProperty('inventoryListId')) {
                return false;
            }

            if (typeof indexedInventory[inventoryList.moduleName] === 'undefined') {
                indexedInventory[inventoryList.moduleName] = [];
            }

            indexedInventory[inventoryList.moduleName].push(inventoryList);
        });

        await setState(currentState => ({
            ...currentState,
            isLoading: false,
            isLoaded: true,
            inventory: indexedInventory
        }));

        return indexedInventory;
    }

    const reset = async () => {
        await setState(currentState => ({
            ...currentState,
            isLoaded: false,
            inventory: []
        }));
    }

    const list = async (forceReload = false) => {
        if (forceReload || (!state.isLoading && !state.isLoaded)) {
            return await updateInventory();
        }

        return state.inventory;
    }

    const getInventoryByType = (inventoryType: string, locationId: int) => {
        if (!state.isLoading && !state.isLoaded) {
            updateInventory().then(result => result[inventoryType]);
        }

        const set = state.inventory[inventoryType].filter(inventory => inventory.location.locationId === locationId);
        return (set.length === 1) ? set[0] : false;
    }

    return {
        isLoaded: state.isLoaded,
        list,
        reset,
        getInventoryByType,
        TYPE_DEVICES: 'myDevices',
        TYPE_BEDS:    'myBeds',
        TYPE_STORAGE: 'storage',
    }
};

export default useInventory;