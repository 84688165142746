import React from 'react';
import Requester from './Requester';
import { Image } from 'react-native';
import AsyncStorage from '../interfaces/AsyncStorage';

const AuthImage = (props) => {
    const [source, setSource] = React.useState(null);

    React.useEffect(
        () => {
            const getCachedImage = async (image) => {
                const cachedImage = await AsyncStorage.getItem(image);

                if (cachedImage !== null) {
                    setSource(cachedImage);
                    return;
                }

                Requester
                    .getBase64FromImageURL(props.source)
                    .then((c) => {
                        if (mounted) {
                            AsyncStorage.setItem(props.source, c);
                            setSource(c);
                        }
                    });
            }

            let mounted = true;

            getCachedImage(props.source);

            return function cleanup() {
                mounted = false;
            }
        },
        [props.source]
    );

    if (source === null) {
        return (
            <Image
                style={props.style}
                source={require('../assets/images/placeholder.jpg')}
                resizeMode={props.resizeMode}
            />
        );
    }

    return (
        <Image
            style={props.style}
            source={{uri: source}}
            resizeMode={props.resizeMode}
        />
    );
}

export default AuthImage;