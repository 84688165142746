import React, {
    useState
} from 'react';
import {
    StyleSheet,
    TouchableHighlight,
    View,
    Platform,
} from 'react-native';
import { Paragraph } from '../components/StyledText';
import {
    Overlay as RneOverlay,
} from 'react-native-elements';
import * as colors from '../styles/colors';
import Modal from 'modal-react-native-web';

const Overlay = ({
    children,
    isVisible, setVisibility,
    titleText,
    width, height,
    ...props
}) => {
    const [pressingOnCloseElement, setPressingOnCloseElement] = useState(false);

    return (
        <RneOverlay
            {...(Platform.OS === 'web' ? {ModalComponent: Modal} : undefined)}
            isVisible={isVisible}
            // onBackdropPress={() => setVisibility(false)} // for better usability, we remove this functionality ref. APP-231
            overlayStyle={[
                styles.overlayContainer,
                {
                    width: width,
                    minHeight: height,
                }
            ]}
            windowBackgroundColor={"rgba(255, 255, 255, .5)"}
        >
            <React.Fragment>
                <View style={styles.header}>
                    <Paragraph style={styles.titleText}>{titleText}</Paragraph>
                    {typeof setVisibility === 'function' &&
                        <View style={styles.titleCloseContainer}>
                            <View style={styles.divider}/>
                            <TouchableHighlight
                                onPress={() => setVisibility(false)}
                                onPressIn={() => setPressingOnCloseElement(true)}
                                onPressOut={() => setPressingOnCloseElement(false)}
                                underlayColor={'transparent'}
                                style={styles.titleCloseButton}
                            >
                                <View>
                                    <Paragraph
                                        style={{
                                            ...(pressingOnCloseElement ? {color: '#555'} : undefined)
                                        }}>X</Paragraph>
                                </View>
                            </TouchableHighlight>
                        </View>
                    }
                </View>
                <View style={styles.content}>
                    {children}
                </View>
            </React.Fragment>
        </RneOverlay>
    );
}

Overlay.defaultProps = {
    width: '75%',
    height: undefined,
    titleText: '',
}

const styles = StyleSheet.create({
    overlayContainer: {
        backgroundColor: '#ddd',
        padding: 0,
        borderWidth: 1,
        borderColor: colors.grayLight,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,
        elevation: 10,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        padding: 5,
        height: 36,
    },
    titleText: {
        flex: 1,
    },
    titleCloseContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: 24
    },
    divider: {
        width: 2,
        height: 37,
        top: -6,
        backgroundColor: '#fff',
        marginLeft: 15,
        marginRight: 5,
    },
    titleCloseButton: {
        width: 24,
        alignItems: 'center',
    },
    content: {
        minHeight: 200,
        flexGrow: 1,
        backgroundColor: 'white',
        padding: 5,
    }
});

export default Overlay;