import React from 'react';
import Svg, {
    G,
    Path,
} from 'react-native-svg';

const IconMEIT = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"50 50 200 159"} width={"100%"} height={"100%"}>
            <G>
                <Path id="Kreis" fill="#FFCC0A" d="M234 129v12a50 50 0 01-48 44h-4a50 50 0 01-48-49v-2-5a49 49 0 0114-28l4-2 3 2c1 2 0 3-1 4-5 5-9 11-11 19-1 5-2 10-1 16a42 42 0 0021 32 42 42 0 0038 2 42 42 0 0023-25 42 42 0 00-10-44l-1-4 4-2 4 2a50 50 0 0113 28"/>
                <Path id="i-Punkt" fill="#FFCC0A" d="M191 78l-2 6-1 1h-8l-1-1c-1-2-2-4-1-6 1-3 3-4 6-5h1c3 1 5 3 6 5"/>
                <Path id="i" fill="#FFCC0A" d="M191 98v56c0 2-2 4-4 4h-5c-2 0-4-2-4-4V99v-4c5 0 9 2 13 3"/>
                <Path id="T" fill="#2E529C" d="M244 110v12h-14v34h-16v-34h-8c-1-5-3-7-6-9v-3h44"/>
                <Path id="Schlange_oben" fill="#2E529C" d="M206 95l9 1-4 1c1 2 5 1 5 4l-8-3-2-1-2 1-5 1-6-2-5-2-6-2h-7l-4 4c-1 2 0 4 1 6l4 4v8c-5-1-9-4-11-7s-4-7-3-11l1-4c2-4 6-6 12-6h13l4-1c4 0 7 1 8 3l6 6"/>
                <Path id="Schlange_Mitte" fill="#2E529C" d="M203 120c1 2 2 5 1 7-3 6-11 8-18 9l-8 2c-2 0-4 1-5 3-1 3 1 5 3 5v8c-5-2-8-4-10-8-1-2-2-4-1-7 2-7 9-8 16-10l7-1c3-1 6-1 7-3 1-3-1-5-2-5v-8c5 1 9 3 10 8"/>
                <Path id="Schlange_unten" fill="#2E529C" d="M201 158v7c-3 7-16 4-25 6l-6 2v-1c0-3 2-4 4-5 5-1 12-1 17-3l3-2c2-1 0-4-1-4v-7c4 1 7 3 8 7"/>
                <Path id="E" fill="#2E529C" d="M167 152v4h-43v-46h41l1 1 1 1v10h-27v5h24v9l-1 2h-23v6h23c0 3 2 5 4 7v1"/>
                <Path id="M" fill="#2E529C" d="M114 110v46H99v-30 1l-9 29H79l-9-28v-2 30H55v-46h22l6 20 2 7 7-27h22z"/>
            </G>
        </Svg>
    );
}

IconMEIT.defaultProps = {
    color: '#FFF',
};

export default IconMEIT;