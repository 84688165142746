import React, {
    useEffect,
    useState,
    useRef,
} from 'react';
import {
    View,
    StyleSheet,
    Image,
    TouchableOpacity,
    FlatList,
    ScrollView,
    TouchableHighlight,
    Animated,
    Easing,
    TouchableWithoutFeedback,
} from 'react-native';
import {
    TabView,
    SceneMap
} from 'react-native-tab-view';
import ArticleView from '../components/ArticleView';
import { Paragraph } from '../components/StyledText';
import * as Styles from '../styles/index';
import useCatalog from '../components/useCatalog';
import {
    Icon,
} from 'react-native-elements';
import Requester from '../components/Requester';
import PdfView from '../components/PdfView';
import TabBar from '../components/TabBar';
import * as colors from '../styles/colors';
import {
    t,
    Trans
} from '@lingui/macro';
import Service from '../components/Service';
import ContentLoader, {
    Rect
} from '../ui/ContentLoader';
import DefaultInput from '../ui/DefaultInput';
import * as SVG from '../assets/images/svg/svg';
import Overlay from '../ui/Overlay';
import MenuList from '../ui/MenuList';
import AuthImage from '../components/AuthImage';
import Toast from '../interfaces/Toast';
// import ExplosiveDrawing from '../components/ExplosiveDrawing';

const ServiceButton = (props) => {
    const [pressingOnServiceElement, setPressingOnServiceElement] = useState(false);

    return (
        <TouchableHighlight
            onPress={() => {props.onPress(true)}}
            onPressIn={() => setPressingOnServiceElement(true)}
            onPressOut={() => setPressingOnServiceElement(false)}
            underlayColor={'transparent'}
            style={{
                width: 200,
                alignSelf: 'center',
                alignItems: 'center'
            }}
        >
            <Image
                style={{width: 150, height: 150}}
                source={(pressingOnServiceElement ? require('../assets/images/service_active.png') : require('../assets/images/service_default.png'))}
                loadingIndicatorSource={require('../assets/images/placeholder.jpg')}
                resizeMode={'contain'}
            />
        </TouchableHighlight>
    )
}

const MyDeviceScreen = ({route, navigation, ...props}) => {
    const { list: listCatalog } = useCatalog();

    const [isServiceVisible, setServiceVisible]                     = useState(false);
    const [completelyLoaded, setCompletelyLoaded]                   = useState(false);
    const [lastItemId, setLastItemId]                               = useState(false);
    const [device, setDevice]                                       = useState([]);
    const [items, setItems]                                         = useState([]);
    const [equipment, setEquipment]                                 = useState([]);
    const [consumables, setConsumables]                             = useState([]);
    const [sortedDocuments, setSortedDocuments]                     = useState([]);
    const [sortedList, setList]                                     = useState();
    const [selectedIndex, setSelectedIndex]                         = useState(0);
    const [deviceImageMagnifyingMode, setDeviceImageMagnifyingMode] = useState('shrinking');
    const [article, setArticle]                                     = useState(null);
    const [navigationState, updateNavigationState]                  = useState({
        index: -1,
        routes: [
            { key: 'documents',   title: i18n._(t`documents`) },
            { key: 'consumables', title: i18n._(t`consumable`) },
            { key: 'equipment',   title: i18n._(t`equipment`) },
        ],
    });

    const inventoryItemId = route.params?.inventoryItemId ?? false;

    const loadDevice = async () => {
        let _device;

        if (inventoryItemId) {
            let networkError = false;
            const response   = await Requester.get(
                '/inventoryItem/' + inventoryItemId
            ).catch(() => {
                networkError = true;
            });

            if (networkError) {
                navigation.goBack();

                return false;
            }

            _device = await response.json();
            Requester.debug(response, _device);

            if (!Object.keys(_device).length) {
                Toast.showError(t`noAccessOrDeviceNotFound`);
                navigation.goBack();

                return false;
            }
        }

        setDevice(_device);
    }

    if (lastItemId !== inventoryItemId) {
        setCompletelyLoaded(false);
        setLastItemId(inventoryItemId);
        loadDevice();
    }

    useEffect(
        () => {
            listCatalog().then(catalogItems => {
                setItems(Object.values(catalogItems));

                if (catalogItems === false && inventoryItemId === false) {
                    navigation.navigate('MyDevices');
                    return null;
                }
            });
        },
    []
    );

    useEffect(() =>
        {
            if (typeof device !== 'object' || device.length === 0 || items.length === 0) {
                return;
            }

            const _sortedList = [...(device.documents)].sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                let comparison = 0;

                if (nameA > nameB) {
                    comparison = 1;
                } else if (nameA < nameB) {
                    comparison = -1;
                }

                return comparison;
            });

            const _equipment   = items.filter(item => device.equipment.includes(item.shopItemId));
            const _consumables = items.filter(item => device.consumables.includes(item.shopItemId));
            // const firstDocument = _sortedList[0]?.content ? _sortedList[0].content : null; //could create undefined if no consumable is available
            setEquipment(_equipment);
            setConsumables(_consumables);
            // setArticle(_consumables[0] || null); //could create undefined if no consumable is available
            setArticle(null); //could create undefined if no consumable is available
            setSortedDocuments(_sortedList[0]?.content ? _sortedList[0].content : []);
            setList(_sortedList);
            setCompletelyLoaded(true);
        },
        [items, device]
    );

    useEffect(() => {
        if (deviceImageMagnifyingMode === 'enlarging') {
            enlargeDeviceImage();
        }

        if (deviceImageMagnifyingMode === 'shrinking') {
            shrinkDeviceImage();
        }
    },
        [deviceImageMagnifyingMode]
    );

    const size = useRef(new Animated.Value(0)).current;

    const enlargeDeviceImage = () => {
        Animated.timing(size, {
            toValue: 1,
            duration: 100,
            easing: Easing.ease,
            useNativeDriver: true,
        }).start();
    };

    const shrinkDeviceImage = () => {
        Animated.timing(size, {
            toValue: 0,
            duration: 100,
            easing: Easing.ease,
            useNativeDriver: true,
        }).start();
    };

    const explosionDrawingCallback = (index) => {
        menuListCallback(consumables[index], index);
    }

    const menuListCallback = (item, index) => {
        setSelectedIndex(index);

        if (item.hasOwnProperty('isDir')) {
            const categoryName = item.name;
            setArticle(null);

            const documents = sortedList.filter(_item => categoryName === _item.name)[0]?.content;

            const _sortedDocuments = documents ? [...documents].sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                let comparison = 0;

                if (nameA > nameB) {
                    comparison = 1;
                } else if (nameA < nameB) {
                    comparison = -1;
                }

                return comparison;
            }) : [];

            setSortedDocuments(_sortedDocuments);
        } else {
            setArticle(item);
        }
    }

    const renderListSectionDocuments = (_items) => {
        return (
            <View style={styles.scene}>
                <View style={[styles.listContainer]}>
                    {_items?.length > 0 &&
                        <MenuList
                            list={_items}
                            onListElementPressed={menuListCallback}
                            activeIndex={selectedIndex}
                        />
                    }
                    <View style={{flex: 1}} />
                </View>
                <View style={[styles.articleView]}>
                    {sortedDocuments.length === 0 &&
                        <View style={[
                            styles.scene,
                            styles.empty
                        ]}>
                            <View>
                                <Paragraph style={styles.title}><Trans id={'noDocumentsAvailable'}/></Paragraph>
                            </View>
                        </View>
                    }
                    {sortedDocuments.length > 0 &&
                        <FlatList
                            style={[{marginTop: 15, marginLeft: 10}]}
                            keyExtractor={(item, itemIndex) => `${itemIndex}`}
                            data={sortedDocuments}
                            numColumns={4}
                            showsHorizontalScrollIndicator={true}
                            renderItem={({item, index}) => {
                                const title      = item.name.substring(0, item.name.length - 4);
                                const shortTitle = title.substr(-4, 4);

                                return (
                                    <TouchableOpacity
                                        style={[
                                            styles.gridItemContainer,
                                            {
                                                flex: 1 / 4,
                                            }
                                        ]}
                                        onPress={() => {
                                            const file = '/file' + item.path + '/' + item.name;
                                            PdfView.show(file);
                                        }}
                                    >
                                        <View style={{alignItems: 'center'}}>
                                            <View style={{
                                                width: 120,
                                                height: 120,
                                            }}>
                                                <SVG.IconDocument
                                                    color={"#1EA6DF"}
                                                    { ...(!isNaN(parseInt(shortTitle)) ? {text: title.substr(-4, 4)} : undefined)}
                                                />
                                            </View>
                                            <Paragraph style={styles.name}>{title}</Paragraph>
                                        </View>
                                    </TouchableOpacity>
                                )
                            }}
                        />
                    }
                </View>
            </View>
        )
    };

    const renderListSectionArticle = (_items, showExplosiveDrawing: boolean) => {
        return (
            <View style={styles.scene}>
                <View style={[styles.listContainer]}>
                    {_items?.length > 0 &&
                        <MenuList
                            list={_items}
                            onListElementPressed={menuListCallback}
                            activeIndex={selectedIndex}
                        />
                    }
                    <View style={{flex: 1}} />
                </View>
                <View style={[styles.articleView]}>
                    {/*{showExplosiveDrawing &&*/}
                    {/*    <View*/}
                    {/*        style={{borderWidth: 2, borderColor: 'lime', width: "100%", height: 500,}}*/}
                    {/*    >*/}
                    {/*        <ExplosiveDrawing callback={explosionDrawingCallback}/>*/}
                    {/*    </View>*/}
                    {/*}*/}
                    {article && <ArticleView key={article.itemNumber} article={article} />}
                    {article === null &&
                        <View style={[
                            styles.scene,
                            styles.empty
                        ]}>
                            <View>
                                <Paragraph style={styles.title}><Trans id={'noEntriesAvailable'}/></Paragraph>
                            </View>
                        </View>
                    }
                </View>
            </View>
        )
    };

    const EquipmentRoute = () => {
        return renderListSectionArticle(equipment, false);
    };

    const ConsumablesRoute = () => {
        return renderListSectionArticle(consumables, true);
    };

    const DocumentsRoute = () => {
        return renderListSectionDocuments(device.documents);
    };

    const maintenanceWarningColor = colors.getMaintenanceWarningColor(device.warningLevel);
    const saveNotes = async (notes) => {
        let networkError = false;
        const response   = await Requester.put(
            '/inventoryItem/' + inventoryItemId,
            {
                ...device,
                additional: notes
            }
        ).catch(() => {
            networkError = true;
        });

        if (networkError) {
            navigation.goBack();

            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);
    }

    const MyLoader = () => (
        <ContentLoader>
            <Rect x="0" y="275" rx="0" ry="0" width="1376" height="7" />
            <Rect x="10" y="10" rx="0" ry="0" width="200" height="200" />
            <Rect x="15" y="290" rx="0" ry="0" width="270" height="340" />
            <Rect x="95" y="220" rx="9" ry="9" width="330" height="60" />
            <Rect x="230" y="150" rx="9" ry="9" width="670" height="60" />
            <Rect x="230" y="50" rx="0" ry="0" width="360" height="30" />
            <Rect x="230" y="100" rx="0" ry="0" width="360" height="30" />
            <Rect x="230" y="10" rx="0" ry="0" width="295" height="30" />
            <Rect x="310" y="290" rx="0" ry="0" width="210" height="210" />
            <Rect x="310" y="500" rx="0" ry="0" width="60" height="60" />
            <Rect x="380" y="500" rx="0" ry="0" width="60" height="60" />
            <Rect x="450" y="500" rx="0" ry="0" width="60" height="60" />

            <Rect x="530" y="220" rx="9" ry="9" width="330" height="60" />
            <Rect x="530" y="290" rx="0" ry="0" width="210" height="30" />
            <Rect x="530" y="345" rx="0" ry="0" width="170" height="20" />
            <Rect x="530" y="380" rx="0" ry="0" width="170" height="20" />
            <Rect x="530" y="410" rx="9" ry="9" width="130" height="50" />
            <Rect x="530" y="480" rx="0" ry="0" width="840" height="50" />
            <Rect x="530" y="540" rx="0" ry="0" width="800" height="160" />

            <Rect x="690" y="410" rx="9" ry="9" width="500" height="60" />
            <Rect x="950" y="220" rx="9" ry="9" width="330" height="60" />
        </ContentLoader>
    );

    if (!completelyLoaded) {
        return (
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <MyLoader />
            </View>
        )
    }

    function switchTab(tab) {
        let item;

        switch (tab) {
            case 0:
                item = null;
                break;

            case 1:
                item = consumables.length ? consumables[0] : null;
                break;

            case 2:
                item = equipment.length ? equipment[0] : null;
                break;
        }

        setArticle(item);
        setSelectedIndex(0);
        updateNavigationState(currentState => ({ ...currentState, index: tab}));
    }

    let fileHelper = false;

    //TODO-manual
    // if (device.documents.length) {
    //     fileHelper = device.documents.find(doc => doc.path.includes('public'));
    // }

    const privateDeviceImages = device.images.filter(image => image.isPrivate);
    const publicDeviceImages  = device.images.filter(image => !image.isPrivate);
    const serviceDates        = device.serviceDates.filter(service => !service.resolved)
    const facilityImages      = [].concat(privateDeviceImages, publicDeviceImages);

    const renderTabBar = (_props) => {
        return (
            <TabBar
                navigationState={_props.navigationState}
                showArrows={false}
                scaleTabs={true}
                switchTab={switchTab}
            />
        );
    }

    const renderScene = SceneMap({
        documents: DocumentsRoute,
        consumables: ConsumablesRoute,
        equipment: EquipmentRoute,
    });

    return (
        <ScrollView contentContainerStyle={[styles.outerDeviceContainer]}>
            <View style={[styles.innerDeviceContainer, {}]}>
                <View style={{display: 'flex', flexDirection: "row", justifyContent: "space-between"}}>
                    <View style={{marginVertical: 7}}>
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: 230,
                            height: 150,
                        }}>
                            <View style={{
                                height: '100%',
                                width: 30,
                                backgroundColor: maintenanceWarningColor,
                            }}/>
                            {
                                (facilityImages.length === 0 ?
                                    <Image
                                        style={{width: 200, height: 150}}
                                        source={require('../assets/images/placeholder.jpg')}
                                        resizeMode={'contain'}
                                    />
                                    :
                                    <React.Fragment>
                                        <Animated.View
                                            style={{
                                                position: 'absolute',
                                                left: 30,
                                                top: 0,
                                                width: 200,
                                                height: 150,
                                                zIndex: 999,
                                                transform: [
                                                    {
                                                        translateX: size.interpolate({
                                                            inputRange: [0, 1],
                                                            outputRange: [0, 200]
                                                        })
                                                    },
                                                    {
                                                        translateY: size.interpolate({
                                                            inputRange: [0, 1],
                                                            outputRange: [0, 150]
                                                        })
                                                    },
                                                    {
                                                        scaleX: size.interpolate({
                                                            inputRange: [0, 1],
                                                            outputRange: [1, 3]
                                                        })
                                                    },
                                                    {
                                                        scaleY: size.interpolate({
                                                            inputRange: [0, 1],
                                                            outputRange: [1, 3]
                                                        })
                                                    }
                                                ]
                                            }}
                                        >
                                            <TouchableWithoutFeedback
                                                onPress={() => {
                                                    if (deviceImageMagnifyingMode === 'enlarging') {
                                                        setDeviceImageMagnifyingMode('shrinking');
                                                    }

                                                    if (deviceImageMagnifyingMode === 'shrinking') {
                                                        setDeviceImageMagnifyingMode('enlarging');
                                                    }
                                                }}
                                            >
                                                <AuthImage
                                                    style={{
                                                        width: 200,
                                                        height: 150,
                                                    }}
                                                    source={'/file' + facilityImages[0].path + '/' + facilityImages[0].name}
                                                    loadingIndicatorSource={require('../assets/images/placeholder.jpg')}
                                                    resizeMode={'contain'}
                                                />
                                            </TouchableWithoutFeedback>
                                        </Animated.View>
                                    </React.Fragment>
                                )
                            }
                        </View>
                    </View>
                    <View style={[styles.details, {flex: 1, marginHorizontal: 7, marginVertical: 7}]}>
                        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row' }}>
                                <View style={{ flex: 3, alignSelf: 'stretch' }} ><Paragraph style={[styles.innerDeviceHeadline, {flex: 1}]}>{device.name}</Paragraph></View>
                            </View>
                            { Boolean(device.serialNumber) &&
                                <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row' }}>
                                    <View style={{ flex: 1, alignSelf: 'stretch' }} ><Paragraph><Trans id={'serialNumber'} /></Paragraph></View>
                                    <View style={{ flex: 3, alignSelf: 'stretch' }} ><Paragraph>{device.serialNumber}</Paragraph></View>
                                </View>
                            }
                            { Boolean(device.inventoryNumber) &&
                                <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row' }}>
                                    <View style={{ flex: 1, alignSelf: 'stretch' }} ><Paragraph><Trans id={'inventoryId'} /></Paragraph></View>
                                    <View style={{ flex: 3, alignSelf: 'stretch' }} ><Paragraph>{device.inventoryNumber}</Paragraph></View>
                                </View>
                            }
                            { Boolean(device.lastPurchased) &&
                                <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row' }}>
                                    <View style={{ flex: 1, alignSelf: 'stretch' }} ><Paragraph><Trans id={'acquisitionDate'} /></Paragraph></View>
                                    <View style={{ flex: 3, alignSelf: 'stretch' }} ><Paragraph>{l18nDate(device.lastPurchased)}</Paragraph></View>
                                </View>
                            }
                            { Boolean(serviceDates[0]?.date) &&
                                <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row' }}>
                                    <View style={{ flex: 1, alignSelf: 'stretch' }} ><Paragraph><Trans id={'nextInspection'} /></Paragraph></View>
                                    <View style={{ flex: 3, alignSelf: 'stretch' }} ><Paragraph style={{color: maintenanceWarningColor}}>{l18nDate(serviceDates[0]?.date)}</Paragraph></View>
                                </View>
                            }
                            { fileHelper &&
                            <View>
                                <Icon
                                    name={'book'}
                                    type={'font-awesome'}
                                    color={'#000'}
                                    underlayColor={'#ccc'}
                                    size={40}
                                    style={{flex: 1, marginRight: 15}}
                                    onPress={() => {
                                        const file = '/file' + fileHelper.path + '/' + fileHelper.title;
                                        PdfView.show(file);
                                    }}
                                />
                            </View>
                            }
                        </View>
                        <View style={{marginHorizontal: -10}}>
                            <DefaultInput
                                value={device.additional}
                                multiline={true}
                                growInHeight={true}
                                onBlur={(text) => saveNotes(text)}
                                inputStyle={{height: 50}}
                                placeholder={i18n._(t`additionalDeviceNotesPlaceholder`)}
                                leftIconType={'edit'}
                            />
                        </View>
                    </View>
                    <ServiceButton onPress={setServiceVisible} />
                    <Overlay
                        isVisible={isServiceVisible}
                        setVisibility={setServiceVisible}
                        titleText={i18n._(/*i18n*/'serviceRequestForDevice', { deviceName: device.name})}
                        width={"75%"}
                        height={420}
                    >
                        <Service
                            onCloseCallback={setServiceVisible}
                            inventoryItemId={device.inventoryItemId}
                        />
                    </Overlay>
                </View>
            </View>
            <View style={[styles.tabMenu, {}]}>
                <TabView
                    navigationState={navigationState}
                    renderTabBar={renderTabBar}
                    renderScene={renderScene}
                    onIndexChange={index => switchTab(index)}
                    style={[styles.container, {}]}
                />
            </View>
        </ScrollView>
    )
};

const styles = StyleSheet.create({
    listContainer: {
        width: 250,
        marginTop: 18,
    },
    outerDeviceContainer: {
        flexGrow: 1,
        display: 'flex',
    },
    innerDeviceContainer: {
        marginLeft: 10,
        marginRight: 50,
    },
    innerDeviceHeadline: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    input: {
        ...Styles.Layouts.input,
        borderBottomWidth: 0
    },
    articleView: {
        flex: 1,
        marginLeft: 7,
        paddingLeft: 7,
    },
    tabMenu: {
        flex: 1,
    },
    container: {
        flex: 1,
    },
    scene: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginLeft: 15,
    },
    empty: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    gridItemContainer: {
        flex: 1,
        marginHorizontal: 7,
        paddingTop: 15,
        marginBottom: 15,
        alignItems: 'flex-start',
    },
    item: {
        padding: 5,
    },
    title: {
        alignSelf: 'center',
    },
});

export default MyDeviceScreen;