import React, {
    useEffect,
    useState
} from 'react';
import {
    FlatList,
    StyleSheet,
    TouchableOpacity,
    View
} from 'react-native';
import * as Styles from '../styles';
import { Paragraph } from '../components/StyledText';
import Layout from '../constants/Layout';
import * as colors from '../styles/colors';

const MenuList = ({
    list, onListElementPressed,
    activeIndex,
    header, headerCallback,
    footer, footerCallback,
    ...props
}) => {
    const [selectedIndex, setSelectedIndex] = useState(activeIndex);

    useEffect(() => {
        setSelectedIndex(activeIndex);
    }, props.disableState === true ? null : []);

    const headerInnerElement = () => (
        <View style={styles.header}>
            <Paragraph style={styles.headerText}>{header}</Paragraph>
        </View>
    )

    const headerElement = () => {
        if (typeof headerCallback === 'function') {
            return (
                <TouchableOpacity
                    onPress={() => headerCallback()}
                >
                    {headerInnerElement()}
                </TouchableOpacity>
            )
        } else {
            return headerInnerElement()
        }
    }

    const footerInnerElement = () => (
        <View style={styles.footer}>
            <Paragraph style={styles.footerText}>{footer}</Paragraph>
        </View>
    )

    const footerElement = () => {
        if (typeof footerCallback === 'function') {
            return (
                <TouchableOpacity
                    onPress={() => footerCallback()}
                >
                    {footerInnerElement()}
                </TouchableOpacity>
            )
        } else {
            return footerInnerElement()
        }
    }

    return (
        <View style={[styles.listContainer]}>
            <FlatList
                style={Styles.Lists.container}
                contentContainerStyle={{
                    borderTopRightRadius: 7,
                    borderTopLeftRadius: 7,
                    overflow: 'hidden'
                }}
                keyExtractor={(item, itemIndex) => `${itemIndex}`}
                data={list}
                extraData={selectedIndex}
                renderItem={({item, index}) => {
                    return (
                        <TouchableOpacity
                            onPress={() => {
                                setSelectedIndex(index);
                                onListElementPressed(item, index)
                            }}
                        >
                            <View style={[styles.item, index === selectedIndex ? Styles.Lists.selected : null]}>
                                <Paragraph style={styles.title}>{item.name}</Paragraph>
                            </View>
                        </TouchableOpacity>
                    )
                }}
                ItemSeparatorComponent={Layout.renderSeparator}
                {...(header ? {
                    ListHeaderComponent: (headerElement())
                } : undefined)}
                {...(footer ? {
                    ListFooterComponent: (footerElement())
                } : undefined)}
            />
            <View style={{flex: 1}} />
        </View>
    )
}

MenuList.defaultProps = {
    activeIndex: 0,
}

const styles = StyleSheet.create({
    scene: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginLeft: 15,
    },
    listContainer: {
        width: 250,
    },
    header: {
        alignItems: 'center',
        padding: 5,
        backgroundColor: '#ddd',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        borderBottomWidth: 1,
        borderBottomColor: colors.grayNormal,
    },
    headerText: {
        fontWeight: 'bold',
    },
    footer: {
        alignItems: 'flex-start',
        padding: 5,
        backgroundColor: '#ddd',
        borderTopWidth: 1,
        borderTopColor: colors.grayNormal,
    },
    footerText: {
        fontWeight: 'normal',
    },
    articleView: {
        flex: 1,
        marginLeft: 7,
        paddingLeft: 7,
    },
    gridItemContainer: {
        flex: 1,
        marginHorizontal: 7,
        paddingTop: 15,
        marginBottom: 15,
        alignItems: 'center',
        backgroundColor: '#ddd',
    },
    item: {
        padding: 5,
    },
    title: {
        paddingVertical: 5,
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
});

export default MenuList;