import React from 'react';
import {
    StyleSheet,
    TouchableHighlight,
    View
} from 'react-native';
import {
    Subtitle,
} from './StyledText';
import { withBadge } from 'react-native-elements';
import IconComb from '../assets/images/svg/IconComb';
import * as RootNavigation from './RootNavigation';

const Comb = (props) => {
    const item = {
        navOptionName:         (props.item.navOptionName && props.item.navOptionName !== "" ? props.item.navOptionName : null),
        navOptionThumb:        (props.item.navOptionThumb && props.item.navOptionThumb !== "" ? props.item.navOptionThumb : null),
        path:                  (props.item.path && props.item.path !== "" ? props.item.path : null),
        screenToNavigate:      (props.item.screenToNavigate && props.item.screenToNavigate !== "" ? props.item.screenToNavigate : null),
        screenToNavigateProps: (props.item.screenToNavigateProps && props.item.screenToNavigateProps !== "" ? props.item.screenToNavigateProps : null),
        icon:                  (props.item.icon && props.item.icon !== "" ? props.item.icon : null),
        scale:                 (props.item.scale && props.item.scale !== "" ? props.item.scale : '60%'),
        borderColor:           (props.item.borderColor ? props.item.borderColor : 'none'),
        backgroundColor:       (props.item.backgroundColor ? props.item.backgroundColor : null),
        hidden:                (props.item.hidden ? true : false),
        opacity:               (props.item.opacity ? props.item.opacity : !props.item.screenToNavigate && props.item.navOptionName !== '' ? 0.6 : 1),
    };
    const menuIndex          = props.section * 10 + props.index;
    const BadgedParagraph    = withBadge(item.withBadge)(Subtitle);

    if (item.hidden && item.hidden === true) {
        return (
            <View
                style={[
                    props.style,
                    (props.atomic ? styles.big : styles.small),
                    {
                        alignItems: 'center',
                        justifyContent: 'center',
                    }]
                }
            />
        );
    }

    return (
        <TouchableHighlight
            style={[
                props.style,
            ]}
            underlayColor={(props.underlayColor ? props.underlayColor : 'transparent')}
            disabled={!item.screenToNavigate}
            onPress={() => {
                global.currentScreenIndex = menuIndex;
                RootNavigation.navigate(item.screenToNavigate, item.screenToNavigateProps);
            }}
        >
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: 2,
                    paddingBottom: 2,
                }}
            >
                <View
                    style={[
                        (props.atomic ? null : styles.sideMenuMargin),
                        {
                            justifyContent: 'center',
                        }
                    ]}
                >
                    <View
                        style={[
                            (props.atomic ? styles.big : styles.small),
                            {
                                alignItems: 'center',
                                justifyContent: 'center',
                                opacity: item.opacity,
                            }]
                        }
                    >
                        <IconComb
                            borderColor={item.borderColor}
                            backgroundColor={item.backgroundColor}
                            style={{position: 'absolute', top: 0}}
                        />
                        <View style={{position: 'absolute', width: item.scale, height: item.scale}}>
                            { item.icon }
                        </View>
                    </View>
                </View>
                {!props.atomic &&
                    <View>
                        {item.withBadge ?
                            <BadgedParagraph
                                style={{
                                    marginRight: 5,
                                    textAlignVertical: 'center',
                                    fontSize: 15,
                                    fontWeight: global.currentScreenIndex === menuIndex ? 'bold' : 'normal',
                                }}
                            >
                                {item.navOptionName}
                            </BadgedParagraph>
                            :
                            <Subtitle
                                style={{
                                    textAlignVertical: 'center',
                                    fontSize: 15,
                                    fontWeight: global.currentScreenIndex === menuIndex ? 'bold' : 'normal',
                                }}
                            >
                                {item.navOptionName}
                            </Subtitle>
                        }
                    </View>
                }
            </View>
        </TouchableHighlight>
    )
};

export default Comb;

const styles = StyleSheet.create({
    sideMenuMargin: {
        marginRight: 10,
    },
    sideMenuContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        alignItems: 'center',
        paddingTop: 20,
    },
    sideMenuProfileIcon: {
        borderRadius: 30,
        height: 80,
        width: 80,
        borderColor: 'black',
        borderWidth: 1,
        backgroundColor: 'white'
    },
    big: {
        width: 300*0.65,
        height: 259*0.65,
    },
    small: {
        width: 38,
        height: 38,
    },
});