import React from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    View,
} from 'react-native';
import { Paragraph } from './StyledText';
import Tab from './Tab';

const TabBar = (propsParam) => {
    const { showArrows, scaleTabs, ...props } = propsParam;

    function switchTab(tab) {
        props.switchTab(tab);
    }

    return (
        <View key={'renderTabBar_' + Math.random()}>
            <View style={[styles.tabBarLine]} />
            <View style={styles.tabBar}>
                { showArrows && props.navigationState.index !== 0 ? (
                    <TouchableOpacity
                        style={[styles.tabItem, styles.tabsNav]}
                        onPress={() => switchTab(props.navigationState.index - 1)}
                        disabled={props.navigationState.index === 0}
                    >
                        <View>
                            <Paragraph>{`<<`}</Paragraph>
                        </View>
                    </TouchableOpacity>
                ) : (
                    <View style={styles.tabsNav} />
                )}
                { props.navigationState.routes.map((route, tab) => {
                    if (props.navigationState.index !== tab) {
                        // return null;
                    }

                    return (
                        <TouchableOpacity
                            key={'renderTabBarTab_' + tab}
                            style={[
                                styles.tabItem,
                                {
                                    flexDirection: 'row',
                                    flex: scaleTabs ? 1 : 0,
                                }
                            ]}
                            onPress={() => switchTab(tab)}
                        >
                            <View style={[styles.tabsBetween, {flex: scaleTabs ? 1 : 0}]} />
                            <Tab
                                navigationState={props.navigationState}
                                tab={tab}
                                route={route}
                                updateTabName={props.updateTabName || null}
                                scaleTabs={scaleTabs}
                            />
                            <View style={[styles.tabsBetween, {flex: scaleTabs ? 1 : 0}]} />
                        </TouchableOpacity>
                    );
                })}
                { showArrows && props.navigationState.index !== props.navigationState.routes.length - 1 ? (
                    <TouchableOpacity
                        style={[styles.tabItem, styles.tabsNav]}
                        onPress={() => switchTab(props.navigationState.index + 1)}
                        disabled={props.navigationState.index === props.navigationState.routes.length - 1}
                    >
                        <View>
                            <Paragraph>{`>>`}</Paragraph>
                        </View>
                    </TouchableOpacity>
                ) : (
                    <View style={styles.tabsNav} />
                )}
            </View>
        </View>
    );
}

TabBar.defaultProps = {
    showArrows: true,
    scaleTabs: false,
}

export default TabBar;

const styles = StyleSheet.create({
    tabBar: {
        flexDirection: 'row',
        alignSelf: 'center',
        zIndex: 10,
        position: 'relative',
    },
    tabBarLine: {
        position: 'absolute',
        zIndex: 0,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        width: '100%',
        height: '100%',
    },
    tabItem: {
        alignItems: 'center',
    },
    tabsBetween: {
        width: 120,
        height: '100%',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    tabsNav: {
        paddingTop: 5,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        width: 40,
    },
});
