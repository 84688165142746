import {
    useContext,
} from 'react';
import { ItemListContext } from './ItemListContext';
import { AuthContext } from './AuthContext';
import {
    ToastAndroid
} from 'react-native';
import Requester from './Requester';
import { t } from '@lingui/macro';
import Toast from '../interfaces/Toast';

const useItemList = () => {
    const [authState]       = useContext(AuthContext);
    const [state, setState] = useContext(ItemListContext);

    let doubleClickDelayTimer = null;

    function getCurrentProductsInItemList(itemListId) {
        const currentItemList = getCurrentItemList(itemListId);

        if (currentItemList.length === 0) {
            return [];
        }

        return JSON.parse(JSON.stringify(currentItemList.items));
    }

    function getCurrentItemList(itemListId) {
        const _list = state.lists.filter(haystack => haystack.itemListId === itemListId);

        if (_list.length === 0) {
            return [];
        }

        return JSON.parse(JSON.stringify((_list)[0]));
    }

    const updateItemLists = async () => {
        await setState(currentState => ({
            ...currentState,
            isLoading: true,
            isLoaded: false
        }));

        let networkError = false;
        const response   = await Requester.get(
            '/facility/' + authState.user.facilityId + '/itemLists'
        ).catch(async (e) => {
            networkError = true;
        });

        if (networkError) {
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        let dataSource = await response.json();
        Requester.debug(response, dataSource);

        if (response.ok === false) {
            //TODO use user object
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        dataSource = Object.values(dataSource).filter(_list => {
            return _list.hasOwnProperty('items');
        });

        await setState(currentState => ({
            ...currentState,
            isLoading: false,
            isLoaded: true,
            lists: dataSource
        }));

        return dataSource;
    }

    const reset = async () => {
        await setState(currentState => ({
            ...currentState,
            isLoaded: false,
            lists: []
        }));
    }

    const list = async (forceReload = false) => {
        if (forceReload || (!state.isLoading && !state.isLoaded)) {
            return await updateItemLists();
        }

        return state.lists;
    }

    const createItemList = async (locationId, name) => {
        let networkError = false;
        const response   = await Requester.post(
            '/itemLists',
            {
                locationId: locationId,
                name: name
            }
        ).catch(() => {
            networkError = true;
        });

        if (networkError) {
            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);
        await updateItemLists();
    }

    function deleteItemList(itemListId) {

    }

    const addProductToItemList = async (itemListId, newProduct, newQuantity) => {
        const quantity = parseInt(newQuantity);

        if (isNaN(quantity)) {
            return false;
        }

        let currentProductsInItemList = getCurrentProductsInItemList(itemListId);
        let lists                     = JSON.parse(JSON.stringify(state.lists));

        if (!currentProductsInItemList.find(product => product.shopItemId === newProduct.shopItemId)) {
            currentProductsInItemList.push(newProduct);

            const itemListIndex = lists.findIndex((_list => _list.itemListId === itemListId));

            if (itemListIndex === -1) {
                ToastAndroid.showWithGravity(t`itemListNotAvailable`, ToastAndroid.LONG, ToastAndroid.BOTTOM);
                return false;
            }

            if (lists[itemListIndex].vendor.vendorId !== newProduct.vendor.vendorId) {
                return false;
            }

            let networkError = false;
            const response   = await Requester.put(
                '/itemList/' + itemListId + '/' + lists[itemListIndex].hash + '/' + newProduct.shopItemId,
                newProduct
            ).catch(() => {
                networkError = true;
            });

            if (networkError) {
                await setState(currentState => ({
                    ...currentState,
                    lists: lists
                }));

                return false;
            }

            const data = await response.json();
            Requester.debug(response, data);

            lists[itemListIndex] = data;
            setState(currentState => ({ ...currentState, lists: lists }));

            return response;
        }
    }

    const removeProductFromItemList = async (itemListId, productToRemove) => {
        let lists           = JSON.parse(JSON.stringify(state.lists));
        const itemListIndex = lists.findIndex((_list => _list.itemListId === itemListId));
        let networkError    = false;
        const response      = await Requester.delete(
            '/itemList/' + itemListId + '/' + lists[itemListIndex].hash + '/' + productToRemove.shopItemId
        ).catch(async (e) => {
            networkError = true;
        });

        if (networkError) {
            await setState(currentState => ({
                ...currentState,
                lists: lists
            }));

            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);

        lists[itemListIndex] = data;
        setState(currentState => ({ ...currentState, lists: lists }));

        return response;
    }

    function updateProductOnItemList(itemListId, productToUpdate, newQuantity) {
        setState(currentState => ({
            ...currentState,
            hasActiveRequest: true,
        }));
        clearTimeout(doubleClickDelayTimer);
        doubleClickDelayTimer = setTimeout(_updateProductOnItemList, 450, itemListId, productToUpdate, newQuantity);
    }

    const _updateProductOnItemList = async (itemListId, productToUpdate, newQuantity) => {
        const quantity = parseInt(newQuantity);

        if (isNaN(quantity) || (productToUpdate.quantity === quantity)) {
            return false;
        }

        productToUpdate.quantity = quantity;

        // let currentProductsInItemList = getCurrentProductsInItemList(itemListId);

        let lists           = JSON.parse(JSON.stringify(state.lists));
        const itemListIndex = lists.findIndex((_list => _list.itemListId === itemListId));
        let networkError    = false;
//@TODO-API request PATCH
        const response      = await Requester.put(
            '/itemList/' + itemListId + '/' + lists[itemListIndex].hash + '/' + productToUpdate.shopItemId,
            productToUpdate
        ).catch(async () => {
            networkError = true;
        });

        if (networkError) {
            await setState(currentState => ({
                ...currentState,
                lists: lists,
                hasActiveRequest: false,
            }));

            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);

        lists[itemListIndex] = data;

        /* As this function is called via setTimeout, we cannot return the response and have to generate the Toast here */
        if (response.status === 400) {
            Toast.showError(t`error`);
            await list(true);
            return;
        }

        setState(currentState => ({
            ...currentState,
            lists: lists,
            hasActiveRequest: false,
        }));

        return response;
    }

    const updateItemList = async (itemListId, newItemListName) => {
        let lists            = JSON.parse(JSON.stringify(state.lists));
        const itemListIndex  = lists.findIndex((_list => _list.itemListId === itemListId));
        let networkError     = false;
        const response       = await Requester.put(
            '/itemList/' + itemListId + '/' + lists[itemListIndex].hash,
            {
                itemListId: itemListId,
                hash: lists[itemListIndex].hash,
                locationId: locationId,
                name: newItemListName
            }
        ).catch(() => {
            networkError = true;
        });

        if (networkError) {
            await setState(currentState => ({
                ...currentState,
                lists: lists
            }));

            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);

        lists[itemListIndex] = data;
        await setState(currentState => ({
            ...currentState,
            lists: lists
        }));

        return response;
    }

    const placeOrder = async (itemListId) => {
        const currentItemList = getCurrentItemList(itemListId);
        let networkError      = false;
        const response        = await Requester.post(
            '/itemList/' + itemListId + '/' + currentItemList.hash + '/offer'
        ).catch(() => {
            networkError = true;
        });

        if (networkError) {
            return false;
        }

        const dataSource = await response.json();
        Requester.debug(response, dataSource);

        await updateItemLists();
        return response;
    }

    return {
        isLoaded: state.isLoaded,
        hasActiveRequest: state.hasActiveRequest,
        updateItemLists,
        reset,
        list,
        createItemList,
        deleteItemList,
        addProductToItemList,
        removeProductFromItemList,
        updateProductOnItemList,
        updateItemList,
        placeOrder,
    }
};

export default useItemList;