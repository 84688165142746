import { i18n } from '@lingui/core';
import {
    de,
    en
} from 'make-plural';
import catalogEn from '../assets/locales/en/messages';
import catalogDe from '../assets/locales/de/messages';

i18n.loadLocaleData('de', { plurals: de })
i18n.loadLocaleData('en', { plurals: en })

i18n.load('en', catalogEn.messages);
i18n.load('de', catalogDe.messages);

i18n.activate('de');

global.i18n = i18n;