import React, {
    useState,
} from 'react';
import {
    ScrollView,
    Text,
    ToastAndroid,
    View,
} from 'react-native';
import {
    t
} from '@lingui/macro';
import {
    MonoText,
    Paragraph
} from '../components/StyledText';
import DefaultInput from '../ui/DefaultInput';
import NumericInput from '../ui/NumericInput';
import EmailInput from '../ui/EmailInput';
import PasswordInput from '../ui/PasswordInput';
import DefaultCheckBox from '../ui/DefaultCheckBox';
import WebButton from '../ui/WebButton';
import * as SVG from '../assets/images/svg/svg';
import TabbableInput from '../ui/TabbableInput';
import AvatarWithName from '../ui/AvatarWithName';
import MenuList from '../ui/MenuList';
import Overlay from '../ui/Overlay';
import IconComb from '../assets/images/svg/IconComb';

const UITestScreen = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isOverlayVisible, setOverlayVisible] = useState(false);

    const menuList = [
        {
            listId: 1,
            name: 'entry 1',
        },
        {
            listId: 2,
            name: 'entry 2',
        },
        {
            listId: 3,
            name: 'entry 3',
        },
    ];

    const color1 = "#333";
    const svgList = [
        {
            icon: <SVG.IconItemList/>,
            iconColored: <SVG.IconItemList color={color1}/>
        },
        {
            icon: <SVG.IconSupport/>,
            iconColored: <SVG.IconSupport color={color1}/>
        },
        {
            icon: <SVG.IconUVV/>,
            iconColored: <SVG.IconUVV color={color1}/>
        },
        {
            icon: <SVG.IconShop/>,
            iconColored: <SVG.IconShop color={color1}/>
        },
        {
            icon: <SVG.IconStorage/>,
            iconColored: <SVG.IconStorage color={color1}/>
        },
        {
            icon: <SVG.IconISO/>,
            iconColored: <SVG.IconISO color={color1}/>
        },
        {
            icon: <SVG.IconHygiene/>,
            iconColored: <SVG.IconHygiene color={color1}/>
        },
        {
            icon: <SVG.IconMyDevices/>,
            iconColored: <SVG.IconMyDevices color={color1}/>
        },
        {
            icon: <SVG.IconBed/>,
            iconColored: <SVG.IconBed color={color1}/>
        },
        {
            icon: <SVG.IconMEIT/>,
            iconColored: <SVG.IconMEIT color={color1}/>
        },
        {
            icon: <SVG.IconCart/>,
            iconColored: <SVG.IconCart color={color1}/>
        },
        {
            icon: <SVG.IconLogout/>,
            iconColored: <SVG.IconLogout color={color1}/>
        },
        {
            icon: <SVG.IconInfo/>,
            iconColored: <SVG.IconInfo color={color1}/>
        },
        {
            icon: <SVG.IconMasterData/>,
            iconColored: <SVG.IconMasterData color={color1}/>
        },
        {
            icon: <SVG.IconUser/>,
            iconColored: <SVG.IconUser color={color1}/>
        },
        {
            icon: <SVG.IconDocument/>,
            iconColored: <SVG.IconDocument color={color1}/>
        },
        {
            icon: <SVG.IconDocument text={'MPG'}/>,
            iconColored: <SVG.IconDocument color={color1} text={'MPG'}/>
        },
        {
            icon: <SVG.IconDocument text={'DGUV'}/>,
            iconColored: <SVG.IconDocument color={color1} text={'DGUV'}/>
        },
        {
            icon: <SVG.IconDocument text={' '}/>,
            iconColored: <SVG.IconDocument color={color1} text={' '}/>
        },
        {
            icon: <SVG.IconDocument text={'1234567'}/>,
            iconColored: <SVG.IconDocument color={color1} text={'1234567'}/>
        },
        {
            icon: <SVG.IconHamburger/>,
            iconColored: <SVG.IconHamburger color={color1}/>
        },
        {
            icon: <SVG.IconHome/>,
            iconColored: <SVG.IconHome color={color1}/>
        },
        {
            icon: <SVG.IconQR/>,
            iconColored: <SVG.IconQR color={color1}/>
        },
    ];

    return (
        <ScrollView>
            <MonoText style={{marginTop: 15, fontSize: 26}}>SVGs</MonoText>
            <React.Fragment>
                <View style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    {svgList.map((svg, key) => (
                        <View
                            key={key}
                            style={{paddingRight: 15}}
                        >
                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flex: 1
                            }}>
                                <View style={{
                                    width: 150,
                                    height: 150,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <IconComb/>
                                    <View style={{
                                        position: 'absolute',
                                        width: "60%",
                                        height: "60%",
                                    }}>
                                        { svg.icon }
                                    </View>
                                </View>
                                <View style={{width: 75}}>
                                    <View style={{
                                        width: 75,
                                        height: 75,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <IconComb/>
                                        <View style={{
                                            position: 'absolute',
                                            width: "60%",
                                            height: "60%"
                                        }}>
                                            { svg.icon }
                                        </View>
                                    </View>
                                    <View style={{
                                        width: 75,
                                        height: 75,
                                        backgroundColor: '#ccc'
                                    }}>
                                        { svg.iconColored }
                                    </View>
                                </View>
                            </View>
                        </View>
                    ))}
                </View>
                <View style={{
                    width: 150,
                    height: 150,
                    backgroundColor: '#ccc'
                }}>
                    <SVG.IconService/>
                </View>
            </React.Fragment>

            <MonoText style={{marginTop: 15, fontSize: 26}}>Texts</MonoText>
            <React.Fragment>
                <Paragraph>Default text</Paragraph>
            </React.Fragment>

            <MonoText style={{marginTop: 15, fontSize: 26}}>Inputs</MonoText>
            <React.Fragment>
                <Text>Default input without pictogram</Text>
                <DefaultInput/>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text>Default input with pictogram</Text>
                        <DefaultInput
                            leftIconType='search'
                        />
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Default input mandatory</Text>
                        <DefaultInput
                            mandatory
                        />
                    </View>
                </View>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text>Default input multi line NOT growing in height</Text>
                        <DefaultInput
                            value={'Line 1\nLine 2\nLine 3'}
                            leftIconType='edit'
                            multiline={true}
                        />
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Default input multi line growing in height</Text>
                        <DefaultInput
                            value={'Line 1\nLine 2\nLine 3'}
                            leftIconType='edit'
                            multiline={true}
                            growInHeight={true}
                        />
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Default input multi line growing preset height</Text>
                        <DefaultInput
                            value={'Line 1\nLine 2\nLine 3'}
                            leftIconType='edit'
                            multiline={true}
                            growInHeight={true}
                            inputStyle={{height: 150}}
                        />
                    </View>
                </View>
            </React.Fragment>
            <React.Fragment>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text>Numeric input</Text>
                        <NumericInput/>
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Numeric input with value > 3 validator</Text>
                        <NumericInput
                            min={3}
                        />
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Numeric input with value {`<`} 10 validator</Text>
                        <View style={{width: 75}}>
                            <NumericInput
                                max={10}
                            />
                        </View>
                    </View>
                </View>
            </React.Fragment>
            <React.Fragment>
                <Text>Email input with pictogram</Text>
                <EmailInput
                    email={''}
                    onChangeText={(_email) => setEmail(_email)}
                />
                <Text>Email input with pictogram and email address</Text>
                <EmailInput
                    email={'example@example.com'}
                />
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text>Email input disabled</Text>
                        <EmailInput
                            email={email}
                            onChange={(_email) => setEmail(_email)}
                            editable={false}
                        />
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Email input working</Text>
                        <EmailInput
                            email={email}
                            onChange={(_email) => setEmail(_email)}
                        />
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Email input without label and custom placeholder</Text>
                        <EmailInput
                            email={''}
                            noLabel
                            placeholder={t`emailAddressPlaceholderForContactPerson`}
                        />
                    </View>
                </View>
            </React.Fragment>
            <React.Fragment>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text>Password input with reveal</Text>
                        <PasswordInput
                            password={password}
                            onChange={(_password) => setPassword(_password)}
                            noLabel
                        />
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Password input with min lenght 5</Text>
                        <PasswordInput
                            password={password}
                            onChange={(_password) => setPassword(_password)}
                            noLabel
                            minLength={5}
                        />
                    </View>
                </View>
            </React.Fragment>
            <React.Fragment>
                <View style={{flex: 1}}>
                    <Text>Checkbox</Text>
                    <DefaultCheckBox
                        title={t`addToQuickLoginOptIn`}
                    />
                </View>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text>Checkbox disabled</Text>
                        <DefaultCheckBox
                            title={t`addToQuickLoginOptIn`}
                            editable={false}
                        />
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Checkbox disabled</Text>
                        <DefaultCheckBox
                            checked={true}
                            title={t`addToQuickLoginOptIn`}
                            editable={false}
                        />
                    </View>
                </View>
            </React.Fragment>
            <MonoText style={{marginTop: 15, fontSize: 26}}>Buttons</MonoText>
            <React.Fragment>
                <Text>Web button with hover effect</Text>
                <WebButton
                    onPress={() => ToastAndroid.show('button pressed', ToastAndroid.SHORT)}
                >{t`login`}</WebButton>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text>Web button disabled</Text>
                        <WebButton
                            onPress={() => ToastAndroid.show('button pressed', ToastAndroid.SHORT)}
                            disabled
                        >{t`login`}</WebButton>
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Web button disabled with icon</Text>
                        <WebButton
                            onPress={() => ToastAndroid.show('button pressed', ToastAndroid.SHORT)}
                            disabled
                            icon={<SVG.IconCart/>}
                        >{t`login`}</WebButton>
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Web button with icon</Text>
                        <WebButton
                            onPress={() => ToastAndroid.show('button pressed', ToastAndroid.SHORT)}
                            icon={<SVG.IconCart/>}
                        >{t`login`}</WebButton>
                    </View>
                </View>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text>Web button small disabled</Text>
                        <WebButton
                            onPress={() => ToastAndroid.show('button pressed', ToastAndroid.SHORT)}
                            disabled
                            size={140}
                        >{t`login`}</WebButton>
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Web button small with icon</Text>
                        <WebButton
                            onPress={() => ToastAndroid.show('button pressed', ToastAndroid.SHORT)}
                            size={'75%'}
                            icon={<SVG.IconCart/>}
                        >{t`login`}</WebButton>
                    </View>
                    <View style={{flex: 1}}>
                        <Text>Web button small with icon</Text>
                        <WebButton
                            onPress={() => ToastAndroid.show('button pressed', ToastAndroid.SHORT)}
                            size={150}
                            icon={<SVG.IconCart/>}
                        >{t`login`}</WebButton>
                    </View>
                </View>
            </React.Fragment>
            <MonoText style={{marginTop: 15, fontSize: 26}}>TabbableInput</MonoText>
            <React.Fragment>
                <Text>Tabbable Input</Text>
                <TabbableInput
                    value={1}
                />
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text>Tabbable Input disabled</Text>
                        <TabbableInput
                            value={1}
                            disabled={true}
                        />
                    </View>
                </View>
            </React.Fragment>
            <MonoText style={{marginTop: 15, fontSize: 26}}>Avatar</MonoText>
            <React.Fragment>
                <Text>Avatar with name below</Text>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    {[...Array(10)].map((x, i) =>
                        <AvatarWithName
                            key={i}
                            name={'Martina Musterfrau'}
                            source={require('../assets/images/profiles/Doc1.jpg')}
                        />
                    )}
                </View>
                <Text>Avatar with name on the right side</Text>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    {[...Array(3)].map((x, i) =>
                        <AvatarWithName
                            key={i}
                            name={'Martin Mustermann'}
                            right
                            source={require('../assets/images/profiles/Doc1.jpg')}
                        />
                    )}
                </View>
            </React.Fragment>
            <MonoText style={{marginTop: 15, fontSize: 26}}>MenuList</MonoText>
            <React.Fragment>
                <Text>Default menu list</Text>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <MenuList
                        list={menuList}
                    />
                </View>
                <Text>Menu list with header and footer</Text>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <MenuList
                        list={menuList}
                        header={'Menu List Header'}
                        footer={'Menu List Footer'}
                    />
                </View>
                <Text>Menu list with header and footer with callbacks</Text>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <MenuList
                        list={menuList}
                        header={'Menu List Header'}
                        headerCallback={() => alert('Header clicked')}
                        footer={'Menu List Footer'}
                        footerCallback={() => alert('Footer clicked')}
                    />
                </View>
            </React.Fragment>
            <MonoText style={{marginTop: 15, fontSize: 26}}>Overlay</MonoText>
            <React.Fragment>
                <Text>Overlay test</Text>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <WebButton
                        onPress={() => setOverlayVisible(true)}
                    >Show overlay</WebButton>
                    <Overlay
                        isVisible={isOverlayVisible}
                        setVisibility={setOverlayVisible}
                        titleText={'Overlay title'}
                        width={600}
                    >
                        <View style={{height: 300}}>
                            <Text>Overlay test</Text>
                        </View>
                    </Overlay>
                </View>
            </React.Fragment>
        </ScrollView>
    );
}

export default UITestScreen;