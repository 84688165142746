import {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { AppState } from 'react-native';
import Log from './Log';
import { debug } from '../constants/Colors';
import Requester from './Requester';
import Toast from '../interfaces/Toast';
import { t } from '@lingui/macro';
import { AuthContext } from './AuthContext';
import useAuth from './useAuth';

const KeepAlive = () => {
    const appState     = useRef(AppState.currentState);
    const timeoutRef   = useRef(null);

    const [authState]  = useContext(AuthContext);
    const { signOut }  = useAuth();

    const [triggerKeepAlive, setTriggerKeepAlive] = useState();

    useEffect(() => {
        AppState.addEventListener("change", _handleAppStateChange);

        return () => {
            AppState.removeEventListener("change", _handleAppStateChange);
        };
    }, []);

    useEffect(
        () => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(
                activateKeepAliveInterval,
                global.keepAliveIntervalInSeconds * 1000
            );
        },
        [
            authState.isAuthenticated,
            triggerKeepAlive
        ]
    );

    const activateKeepAliveInterval = async () => {
        const log = new Log(debug.FgBlack, debug.BgYellow, 'Every ' + global.keepAliveIntervalInSeconds + ' seconds keepAlive interval');

        if (authState.isAuthenticated) {
            let networkError = false;
            const response   = await Requester.post(
                '/keepalive',
            ).catch((e) => {
                networkError = true;
            });

            if (networkError) {
                return false;
            }

            const data = await response.json();
            Requester.debug(response, data);

            if (authState.isAuthenticated && response.status === 401) {
                Toast.showInfo(t`sessionTimedOut`);
                log.add('session not valid anymore');
                signOut();
            }
        }

        log.add('AppState.currentState:', AppState.currentState, 'authState:', authState, 'keepAliveInterval:', timeoutRef.current);

        if (logging.keepAlive) {
            log.flush();
        }

        timeoutRef.current = null;
        setTriggerKeepAlive(Math.random());
    }

    const _handleAppStateChange = (nextAppState) => {
        const log = new Log(debug.FgBlack, debug.BgYellow, 'App state change');
        log.add('nextAppState:', nextAppState);

        if (
            appState.current.match(/inactive|background/) &&
            nextAppState === "active"
        ) {
            log.add('App has come to the foreground!');
            setTriggerKeepAlive(Math.random());
        }

        appState.current = nextAppState;

        if (appState.current !== 'active' && timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        if (logging.appStateChange) {
            log.flush();
        }
    };

    return null;
}

export default KeepAlive;