import React, {
    useContext,
} from 'react';
import Requester from './Requester';
import AsyncStorage from '../interfaces/AsyncStorage';
import { AuthContext } from './AuthContext';
import useCart from './useCart';
import useInventory from './useInventory';
import useStorage from './useStorage';
import useItemList from './useItemList';
import useMenu from '../context/useMenu';

const useAuth = () => {
    const ERROR_NETWORK     = 1;
    const ERROR_CREDENTIALS = 2;

    const [auth, setState] = useContext(AuthContext);

    const {
        list: listCart,
        reset: resetCart
    } = useCart();
    const {
        list: listInventory,
        reset: resetInventory
    } = useInventory();
    const {
        list: listStorage,
        reset: resetStorage
    } = useStorage();
    const {
        list: listItemLists,
        reset: resetItemList
    } = useItemList();
    const { reset: resetMenu } = useMenu();

    const signIn = async (email, password) => {
        let networkError = false;

        const response = await Requester.post(
            '/user/login',
            {
                email: email,
                password: password,
            },
        ).catch(() => networkError = true);

        return new Promise(async (resolve, reject) => {
            if (networkError) {
                reject(ERROR_NETWORK);
            }

            if (response.ok) {
                const dataSource = await response.json();
                Requester.debug(response, dataSource);

                Requester.setToken(dataSource.token);
                AsyncStorage.setItem('userToken', dataSource.token);

                setState(currentState => {
                    const newState = {
                        ...currentState,
                        isSignout: false,
                        isAuthenticated: true,
                        isUserLoaded: false,
                        userToken: dataSource.token,
                    };

                    return newState;
                });

                resolve(dataSource);
            }

            reject(ERROR_CREDENTIALS);
        });
    }

    const signOut = async (skipLogoutCall = false) => {
        if (auth.user?.userId && skipLogoutCall === false) {
            Requester.post('/user/' + auth.user.userId + '/logout');
        }

        resetMenu()
        resetCart();
        resetInventory();
        resetStorage();
        resetItemList();

        await AsyncStorage.removeItem('userToken');

        setState(currentState => ({
            ...currentState,
            isSignout: true,
            isAuthenticated: false,
            isUserLoaded: false,
            userToken: null,
            user: null,
        }));
    }

    const setUserReady = async () => {
        setState(currentState => ({
            ...currentState,
            isUserLoaded: true,
        }));
    }

    const updateUser = async (user) => {
        setState(currentState => ({
            ...currentState,
            user: user,
        }));
    }

    const restoreSession = async (user) => {
        setState(currentState => ({
            ...currentState,
            isAuthenticated: true,
            isUserLoaded: true,
            isLoading: false,
            user: user,
        }));
    }

    const restoreToken = async (userToken) => {
        setState(currentState => ({
            ...currentState,
            token: userToken,
        }));
    }

    return {
        ERROR_NETWORK: ERROR_NETWORK,
        ERROR_CREDENTIALS: ERROR_CREDENTIALS,
        signIn,
        signOut,
        setUserReady,
        updateUser,
        restoreSession,
        restoreToken,
    }
};

export default useAuth;