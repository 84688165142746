import { debug } from '../constants/Colors';
import moment from 'moment';
import Uuid from '../classes/Uuid';
import Log from './Log';
import * as Network from 'expo-network';
import Toast from '../interfaces/Toast';
import { t } from '@lingui/macro';

const Requester = {
    METHOD_POST: 'POST',
    METHOD_GET: 'GET',
    METHOD_PUT: 'PUT',
    METHOD_PATCH: 'PATCH',
    METHOD_DELETE: 'DELETE',

    VERBOSE_ALL: 3,
    VERBOSE_INCOMING: 2,
    VERBOSE_OUTGOING: 1,
    VERBOSE_NONE: 0,

    VERBOSE_LEVEL: 0,

    muted: [
        'keepalive',

        'products',
        'shopItems',
        'inventory',
        'itemList',
        'cart',
        'keepalive',
        'order',
        'mail',
        'module',
    ],

    verboseAllException: [
        // 'products',
        // 'shopItems',
        // 'inventory',
        // 'itemList',
        // 'cart',
        // 'keepalive',
        // 'order',
        // 'mail',
    ],

    requests: {},

    token: null,

    debug: (response, data) => {
        if (-1 !== Requester.muted.findIndex(entry => response.url.includes(entry))) {
            return;
        }

        const log               = new Log(debug.FgBrightBlue, debug.BgBlack, 'SERVER RESPONSE');
        const requestReturnTime = moment();
        // const requestId         = response.headers.map.requestid;
        const requestId         = response.headers.get('Requestid');
        const requestStartTime  = requestId !== undefined && requestId !== null ? Requester.requests[requestId]['requestStartTime'] : moment();
        const runtime           = moment.duration(requestReturnTime.diff(moment(requestStartTime)));

        Requester.requests[requestId]['requestEndTime  '] = requestReturnTime;
        Requester.requests[requestId]['runtime         '] = runtime;
        // console.log('Requester.requests', Requester.requests)

        delete response.headers;
        delete response._bodyInit;
        delete response._bodyBlob;

        Requester.VERBOSE_LEVEL >= Requester.VERBOSE_OUTGOING ? log.add('requestId:', requestId) : null;
        Requester.VERBOSE_LEVEL >= Requester.VERBOSE_OUTGOING ? log.add('request url:', response.url) : null;
        Requester.VERBOSE_LEVEL >= Requester.VERBOSE_OUTGOING ? log.add('requestStartTime:', requestStartTime) : null;
        Requester.VERBOSE_LEVEL >= Requester.VERBOSE_OUTGOING ? log.add('requestReturnTime:', requestReturnTime) : null;
        Requester.VERBOSE_LEVEL >= Requester.VERBOSE_OUTGOING ? log.add('runtime:', runtime) : null;
        Requester.VERBOSE_LEVEL >= Requester.VERBOSE_INCOMING && -1 === Requester.verboseAllException.findIndex(entry => response.url.includes(entry)) ? log.add('response:', response) : null;
        Requester.VERBOSE_LEVEL >= Requester.VERBOSE_ALL      && -1 === Requester.verboseAllException.findIndex(entry => response.url.includes(entry)) ? log.add(data) : null;
        Requester.VERBOSE_LEVEL >= Requester.VERBOSE_OUTGOING ? log.flush() : null;
        delete Requester.requests[requestId];
    },

    sleep: (ms:number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    __debug__: async (url:string) => {
        //@TODO-API check later
        const whitelist = [
            '/user/login',
            '/user/logout',
            '/keepalive',
        ];

        if (whitelist.includes(url)) {
            return false;
        }

        // artifical delay of [2..7] retires for a request
        let requestCount = Requester.requests[url] || Math.floor(Math.random() * 5) + 2;
        requestCount--;
        Requester.requests[url] = requestCount;

        if (requestCount <= 0) {
            delete Requester.requests[url];
            // 50 percent chance to let the request fail
            return Boolean(Math.floor(Math.random() * 2));
        } else {
            Toast.showError(url + '\n' + requestCount + ' retries left');
            await Requester.sleep(1000);
            return Requester.__debug__(url);
        }
    },

    _preflightCheck: async (
        log:Log,
        url:string
    ) => {
        let __debug = false;

        /*             .
         *            / \
         *           / . \
         *          / / \ \
         *         / /   \ \
         *        / /     \ \                MAKE SURE
         *       / / _ _O  \ \               THE FOLLOWING
         *      / / \ / |   \ \              LINE NEVER
         *     / /   / /|    \ \             IS UNCOMMENTED
         *    / /   //\\ `. . \ \            ON LIVE
         *   / /   //  \\  / \ \ \
         *  / /   //   || /___\ \ \
         * /  `-----------------'  \
         * `-----------------------'
         *   jrei
         */
        // __debug = await Request.__debug__(url);

        const networkState = await Network.getNetworkStateAsync();

        if (__debug || !networkState.isConnected || !networkState.isInternetReachable) {
            log.highlightColor(debug.Bright + debug.Reverse + debug.FgRed);
            log.add('preflight check failed!');
            log.resetColor();
            Toast.showError(i18n._(t`noInternetConnection`));
            return false;
        }

        if (networkState.type === 'CELLULAR') {
            log.highlightColor(debug.Bright + debug.Reverse + debug.FgRed);
            log.add('POTENTIALLY SLOW OR METERED CONNECTION!');
            log.resetColor();
        }

        return true;
    },

    _request: async (
        method:string,
        url:string,
        data
    ) => {
        const log              = new Log(debug.FgBrightBlue, debug.BgBlack, 'SERVER REQUEST');
        const requestStartTime = moment();
        const { getUuid }      = Uuid();
        const requestId        = await getUuid();
        let _url               = new URL(url, global.url.backend);

        if (-1 === Requester.muted.findIndex(entry => _url.href.includes(entry))) {
            Requester.requests[requestId] = {
                'URL             ': _url,
                'requestStartTime': requestStartTime,
                'requestEndTime  ': null,
                'runtime         ': null,
            };
        }

        const preflightCheckResult = await Requester._preflightCheck(log, url);

        if (!preflightCheckResult)  {
            log.flush();
            return Promise.reject(new Error('preflight check failed'));
        }

        if (-1 === Requester.muted.findIndex(entry => _url.href.includes(entry))) {
            Requester.VERBOSE_LEVEL >= Requester.VERBOSE_OUTGOING ? log.add('requestStartTime:', requestStartTime) : null;
            Requester.VERBOSE_LEVEL >= Requester.VERBOSE_OUTGOING ? log.add('requestId:', requestId) : null;
            Requester.VERBOSE_LEVEL >= Requester.VERBOSE_OUTGOING ? log.add('outgoing request - Type:', method, _url) : null;
            Requester.VERBOSE_LEVEL >= Requester.VERBOSE_OUTGOING ? log.add('data:', data) : null;
            Requester.VERBOSE_LEVEL >= Requester.VERBOSE_OUTGOING ? log.flush() : null;
        }

        const controller = new AbortController();
        const signal     = controller.signal;

        // https://medium.com/cameron-nokes/4-common-mistakes-front-end-developers-make-when-using-fetch-1f974f9d1aa1
        return fetch(
            _url,
            {
                signal: signal,
                headers: {
                    Accept: 'application/json, text/plain, image*, application/pdf',
                    'Content-Type': 'application/json',
                    'Requestid': requestId,
                    mode: 'cors',
                    Authorization: 'Bearer ' + Requester.getToken()
                },
                method: method,
                body: JSON.stringify(data),
            }
        );
    },

    post: (url:string, data = {})  => {
        return Requester._request(Requester.METHOD_POST, url, data);
    },

    get: (url:string) => {
        return Requester._request(Requester.METHOD_GET, url);
    },

    put: (url:string, data = {}) => {
        return Requester._request(Requester.METHOD_PUT, url, data);
    },

    patch: (url:string, data = {}) => {
        return Requester._request(Requester.METHOD_PATCH, url, data);
    },

    delete: (url:string) => {
        return Requester._request(Requester.METHOD_DELETE, url, {});
    },

    convertBlobToBase64: (blob:string) => new Promise((resolve, reject) => {
        const reader = new FileReader;
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    }),

    getBlobFromURL: async (url:string) => {
        const response = await Requester._request(Requester.METHOD_GET, url);
        const blob     = await response.blob();
        return blob;
    },

    getBase64FromImageURL: async (url:string) => {
        const blob     = await Requester.getBlobFromURL(url);
        const base64   = await Requester.convertBlobToBase64(blob);
        return base64;
    },

    getImageURL: (url:string) => {
        return global.url.backend + url;
    },

    getToken: () => {
        return Requester.token;
    },

    setToken: (token:string) => {
        Requester.token = token;
    },
};

export default Requester;
