import React, {
    useEffect,
    useState,
} from 'react';
import {
    FlatList,
    View,
    StyleSheet,
    Alert,
    TouchableWithoutFeedback,
} from 'react-native';
import { Paragraph } from './StyledText';
import * as Styles from '../styles';
import {
    Icon,
    Input,
    Divider,
} from 'react-native-elements';
import useCart from '../components/useCart';
import Layout from '../constants/Layout';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import WebButton from '../ui/WebButton';
import * as colors from '../styles/colors';
import * as SVG from '../assets/images/svg/svg';
import { colorPrimary0 } from '../styles/colors';
import PdfView from './PdfView';

const Order = (props) => {
    const [expanded, setExpanded] = useState(true);
    const order = props.order;

    const renderItem = ({item, index}) => {
        return (
            <View
                style={[
                    Styles.Layouts.row,
                    // index % 2 === 0 ? Styles.Lists.listItemEven : Styles.Lists.listItemOdd,
                    {
                        alignItems: 'center',
                    }
                ]}
            >
                <Paragraph style={styles.name}>{item.name}</Paragraph>
                {order.acknowledged
                    ?
                    <Paragraph
                        style={[
                            styles.quantity,
                            {
                                // flex: 1
                            }
                        ]}
                    >{item.quantity}</Paragraph>
                    :
                    <Input
                        value={item.quantity.toString()}
                        keyboardType={'numeric'}
                        placeholder={'1'}
                        placeholderTextColor={'#aaa'}
                        inputContainerStyle={{width: 60, borderBottomWidth: 0}}
                        inputStyle={{...Styles.Layouts.input}}
                        containerStyle={styles.quantityContainer}
                        textAlign={'right'}
                        disabled={true}
                        underlineColorAndroid='transparent'
                    />
                }
            </View>
        )
    }

    return (
        <View style={
            styles.container
        }>
            <TouchableWithoutFeedback
                onPress={() => setExpanded(!expanded)}
            >
                <View style={[Styles.Layouts.row, styles.orderBox]}>
                    <View style={styles.orderIcon}>
                            <View style={{
                                width: 40,
                                height: 30,
                            }}>
                                <SVG.IconDocument color={colorPrimary0} />
                            </View>
                    </View>
                    {/*<View style={styles.orderState}>*/}
                    {/*    <Paragraph*/}
                    {/*        style={{*/}
                    {/*            color: !order.acknowledged ? colors.colorComplement0 : colors.colorPrimary0,*/}
                    {/*            fontWeight: !order.acknowledged ? 'bold' : 'normal'*/}
                    {/*        }}*/}
                    {/*    >{!order.acknowledged ? <Trans id={'orderPending'} /> : <Trans id={'orderAcknowledged'} />}</Paragraph>*/}
                    {/*</View>*/}
                    <Paragraph style={styles.orderDate}>{order.offerDate}</Paragraph>
                    <Paragraph style={styles.orderInfo}>{order.customer.name}</Paragraph>
                    {/*<Paragraph style={styles.orderAckedBy}>{order.acknowledgedBy?.name}</Paragraph>*/}
                    {/*<Paragraph style={styles.orderAckedAt}>{order.acknowledged ? order.acknowledgedAt : ''}</Paragraph>*/}
                </View>
            </TouchableWithoutFeedback>
                <View style={[expanded ? styles.hide : styles.visible]}>
                    <Divider style={{ backgroundColor: 'black' }} />
                    <FlatList
                        keyExtractor={(item, itemIndex) => `${itemIndex}`}
                        data={order.productList}
                        style={styles.orderDetails}
                        renderItem={renderItem}
                        ListHeaderComponent={(
                            <View style={Styles.Layouts.row}>
                                <Paragraph style={[styles.tableHeader, styles.name]}><Trans id={'article'} /></Paragraph>
                                <Paragraph style={[styles.tableHeader, styles.quantity]}><Trans id={'quantity'} /></Paragraph>
                            </View>
                        )}
                        ItemSeparatorComponent={Layout.renderSeparator}
                    />
                </View>
        </View>
    );
}

export default Order;

const styles = StyleSheet.create({
    orderBox: {
        alignContent: 'center',
        backgroundColor: '#eee',
    },
    hide: {
        height: 0
    },
    visible: {
    },
    orderDetails: {
        marginTop: 10,
        padding: 7,
        borderBottomLeftRadius: 7,
    },
    orderIcon: {
        alignSelf: 'center',
    },
    orderDate: {
        width: 160,
        paddingHorizontal: 10,
        textAlign: 'center',
    },
    orderInfo: {
        width: 250,
        paddingHorizontal: 10,
        textAlign: 'center',
    },
    container: {
        backgroundColor: '#fff',
        overflow: 'hidden',
        borderRadius: 7,
    },
    tableHeader: {
        fontWeight: 'bold',
    },
    scene: {
        flex: 1,
    },
    name: {
        flex: 1,
        marginHorizontal: 7,
    },
    quantity: {
        width: 120,
    },
    quantityContainer: {
        width: 120,
    },
    productionUnit: {
        width: 50,
        textAlign: 'right',
    },
    factor: {
        width: 70,
        textAlign: 'right',
    },
    action: {
        width: 35,
        alignContent: 'center',
    },
});