import {
    View,
    StyleSheet,
    TouchableHighlight,
} from 'react-native';
import React, {
    useContext,
    useState,
} from 'react';
import { useNavigation } from '@react-navigation/native';
import {
    Badge,
} from 'react-native-elements';
import Overlay from '../ui/Overlay';
import { CartContext } from './CartContext';
import Scanner from './Scanner';
import * as SVG from '../assets/images/svg/svg';
import useAuth from './useAuth';
import * as colors from '../styles/colors';
import MEITScreen from '../screens/MEITScreen';
import { AuthContext } from './AuthContext';

const Header = (props) => {
    const navigation  = useNavigation();
    const { signOut } = useAuth();

    const [authState]     = useContext(AuthContext);
    const [cartContent]   = useContext(CartContext);

    const [isScannerVisible, setScannerVisible]                         = useState(false);
    const [pressingOnQrElement, setPressingOnQrElement]                 = useState(false);
    const [pressingOnSupportElement, setPressingOnSupportElement]       = useState(false);
    const [pressingOnCartElement, setPressingOnCartElement]             = useState(false);
    const [pressingOnSignOutElement, setPressingOnSignOutElement]       = useState(false);
    const [pressingOnHamburgerElement, setPressingOnHamburgerElement]   = useState(false);

    const abortScanner = () => {
        setScannerVisible(false);
    }

    const startScanner = () => {
        setScannerVisible(true);
    }

    if (!authState.isUserLoaded) {
        return null;
    }

    const showSideMenuIcon = authState.user.sideMenuPosition === "right";
    const totalItemsInCart = cartContent.articles.reduce((total, current) => (total + parseInt(current.quantity)), 0);

    return (
        <View
            {...props}
            style={[
                props.style,
            ]}
        >
            <Overlay
                isVisible={isScannerVisible}
                setVisibility={abortScanner}
                // width={"90%"}
                height={"90%"}
            >
                <Scanner scanFinished={abortScanner} />
            </Overlay>
            <View style={[styles.headerContainer, {}]}>
                <View style={styles.logoutButton}>
                    {/* @TODO-web QR Code scanner for now not supported on web. Requesting cam and showing the overlay works, but the reader is not loaded */}
                    {/*<View style={styles.divider} />*/}
                    {/*<TouchableHighlight*/}
                    {/*    onPress={() => startScanner()}*/}
                    {/*    onPressIn={() => setPressingOnQrElement(true)}*/}
                    {/*    onPressOut={() => setPressingOnQrElement(false)}*/}
                    {/*    underlayColor={'transparent'}*/}
                    {/*    style={styles.icon}*/}
                    {/*>*/}
                    {/*    <SVG.IconQR*/}
                    {/*        color={pressingOnQrElement ? colors.grayNormal : colors.grayStrong}*/}
                    {/*    />*/}
                    {/*</TouchableHighlight>*/}
                    <View style={styles.divider} />
                    <TouchableHighlight
                        onPress={() => navigation.navigate('MEIT', {subscreen: MEITScreen.PAGE_CONTACT})}
                        onPressIn={() => setPressingOnSupportElement(true)}
                        onPressOut={() => setPressingOnSupportElement(false)}
                        underlayColor={'transparent'}
                        style={styles.icon}
                    >
                        <SVG.IconSupport
                            color={pressingOnSupportElement ? colors.grayNormal : colors.grayStrong}
                        />
                    </TouchableHighlight>
                    <View style={styles.divider} />
                    <TouchableHighlight
                        onPress={() => navigation.navigate('Cart')}
                        onPressIn={() => setPressingOnCartElement(true)}
                        onPressOut={() => setPressingOnCartElement(false)}
                        underlayColor={'transparent'}
                        style={styles.icon}
                    >
                        <View>
                            <SVG.IconCart
                                color={pressingOnCartElement ? colors.grayNormal : colors.grayStrong}
                            />
                            <Badge
                                value={totalItemsInCart}
                                badgeStyle={{backgroundColor: colors.maintenanceOverDueColor}}
                                containerStyle={{position: 'absolute', top: 1, right: 1 }}
                            />
                        </View>
                    </TouchableHighlight>
                    <View style={styles.divider} />
                    <TouchableHighlight
                        onPress={signOut}
                        onPressIn={() => setPressingOnSignOutElement(true)}
                        onPressOut={() => setPressingOnSignOutElement(false)}
                        underlayColor={'transparent'}
                        style={styles.icon}
                    >
                        <SVG.IconLogout
                            {...(pressingOnSignOutElement ? {color: '#F00'} : undefined)}
                        />
                    </TouchableHighlight>
                    {showSideMenuIcon &&
                    <React.Fragment>
                        <View style={styles.divider} />
                        <TouchableHighlight
                            onPress={sideMenu}
                            onPressIn={() => setPressingOnHamburgerElement(true)}
                            onPressOut={() => setPressingOnHamburgerElement(false)}
                            underlayColor={'transparent'}
                            style={styles.icon}
                        >
                            <SVG.IconHamburger
                                color={pressingOnHamburgerElement ? colors.grayNormal : colors.grayStrong}
                            />
                        </TouchableHighlight>
                    </React.Fragment>
                    }
                    <View style={{marginRight: 15}} />
                </View>
            </View>
        </View>
    )
};

export default Header;

const styles = StyleSheet.create({
    divider: {
        width: 2,
        backgroundColor: 'white',
        marginLeft: 15,
        marginRight: 15,
    },
    icon: {
        width: 50,
        height: 50,
    },
    headerContainer: {
        height: 50,
    },
    logoutButton: {
        flexDirection: 'row',
    }
});