import React, {
    useContext
} from 'react';
import * as SVG from '../assets/images/svg/svg';
import * as colors from '../styles/colors';
import Requester from '../components/Requester';
import { AuthContext } from '../components/AuthContext';
import { MenuContext } from './MenuContext';
import { defineMessage } from '@lingui/macro';

const useMenu = () => {
    const [authState]       = useContext(AuthContext);
    const [state, setState] = useContext(MenuContext);

    defineMessage({message: 'menuSeparatorDocuments'});
    defineMessage({message: 'menuSeparatorManagementAndHelp'});
    defineMessage({message: 'menuSeparatorOffers'});
    defineMessage({message: 'DGUV'});
    defineMessage({message: 'MPG'});
    defineMessage({message: 'hygieneManagement'});
    defineMessage({message: 'iso9001'});
    defineMessage({message: 'myDevices'});
    defineMessage({message: 'notifications'});
    defineMessage({message: 'offerBook'});
    defineMessage({message: 'shop'});
    defineMessage({message: 'startPage'});
    defineMessage({message: 'support'});
    defineMessage({message: 'team'});
    defineMessage({message: 'workSafety'});

    function compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;

            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }

            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    function setIcon(entry) {
        const icon = SVG.getComponent(entry);

        if (icon) {
            entry.icon = icon;
        }

        if (entry.iconName === 'IconMEIT') {
            entry.backgroundColor = '#FFF';
        }
    }

    function setBorderColor(entry) {
        switch (entry.status) {
            case 'alert':
                entry.borderColor = colors.maintenanceOverDueColor;
                break;

            case 'warning':
                entry.borderColor = colors.maintenanceWarningColor;
                break;

            case 'normal':
                entry.borderColor = colors.maintenanceNothingAmissColor;
                break;

            default:
            //no border as default
        }
    }

    function combMenu(rawMenuData) {
        let combs = Array(18).fill({})

        rawMenuData.forEach(group => {
            group.entries.forEach(entry => {
                if (entry.hasOwnProperty('navOptionName')) {
                    entry.navOptionName = i18n._(entry.navOptionName);
                }

                setIcon(entry);
                setBorderColor(entry);

                if (entry.hasOwnProperty('combPosition')) {
                    combs[entry.combPosition - 1] = entry;
                }
            })
        });

        return combs;
    }

    function sideMenu(rawMenuData) {
        let _groups = rawMenuData.filter(entry => {
            return true;
            // return (!entry.hasOwnProperty('groupPosition')) ? false : true;
        });

        // _groups.sort(compareValues('groupPosition'));

        _groups.forEach(group => {
            group.entries.sort(compareValues('groupPosition'));

            group.entries = group.entries.filter(entry => {
                return (
                    // !entry.hasOwnProperty('groupPosition')                    ||
                    !entry.hasOwnProperty('screenToNavigate')
                    || entry.screenToNavigate === ''
                ) ? false : true;
            });
        });

        return _groups;
    }

    const load = async () => {
        await setState(currentState => ({
            ...currentState,
            isLoading: true,
            isLoaded: false,
        }));

        let networkError = false;
        const response   = await Requester.get(
            '/modules/' + authState.user.facilityId
        ).catch(async (e) => {
            networkError = true;
        });

        if (networkError) {
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        let dataSource = await response.json();
        Requester.debug(response, dataSource);

        if (response.ok === false) {
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        /**
         *                      Beware of magic here
         * As the combMenu() is modifing/enriching every single entry,
         * the sideMenu() only needs to sort and filter
         */
        const _combMenu = combMenu(dataSource);
        const _sideMenu = sideMenu(dataSource);

        await setState(currentState => ({
            ...currentState,
            isLoading: false,
            isLoaded: true,
            combMenu: _combMenu,
            sideMenu: _sideMenu
        }));

        return {
            combMenu: _combMenu,
            sideMenu: _sideMenu
        };
    }

    const reset = async () => {
        await setState(currentState => ({
            ...currentState,
            isLoaded: false,
            combMenu: null,
            sideMenu: null
        }));
    }

    const getCombMenu = (forceReload = false) => {
        if (forceReload || (!state.isLoading && !state.isLoaded)) {
            load().then(result => result);
        }

        return state.combMenu;
    }

    const getSideMenu = (forceReload = false) => {
        if (forceReload || (!state.isLoading && !state.isLoaded)) {
            load().then(result => result);
        }

        return state.sideMenu;
    }

    return {
        load,
        reset,
        getCombMenu,
        getSideMenu,
    }
}

export default useMenu;