import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
} from 'react-native-svg';

const IconHygiene = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="Hygiene" fill={color}>
                <Path id="Hand" d="M168 174c1-2 3-5 2-8l-1-3-8-19-1-1a384 384 0 00-9 19l-1 3 1 6c7 0 12 2 17 3m106 27v2l-4 5-8 6-3 3-5 3-13 7-19 9-6 3h-37l-18 1h-18l-15-4-16-3c-5-1-11-1-17 1l-14 4-15 4-14 5-15 4h-2c-4-1-6-3-7-7l-1-12 1-12-2-11v-2l1-2v-2c0-5 2-7 6-9s9-2 15-3l36-2a119 119 0 0016-3l27-10 15-4-1-8 2-6c5-12 11-23 18-34l13 25 5 13v8l-2 7 18 5 6 1c3 2 7 3 8 6v6c-3 5-12 4-18 3l-13-2-14-1h-7c-3-1-6 0-6 2-1 4 2 6 5 7l10 2 31 7 10 2h5l14-5 2-7 3-5 4-4c2-1 5-1 6 1l-1 3-2 8 9-3 2-1 1-1 4-4c1-2 4-3 5-1l1 1 1 1h2l6-2c3 1 4 3 5 5"/>
                <Path id="Flasche" d="M173 107v-9h-25v9h25zm49-94v50l-1 7a42 42 0 01-13 21c-6 5-15 7-26 7v10c0 5-2 8-6 9h-31c-4-1-6-4-6-9V98c-11 0-20-2-26-7l-8-9-6-12V21v-8c0-3 1-4 4-5h1c2 1 4 2 4 5v51l2 7c1 4 4 8 7 10 2 3 6 5 11 7l15 1h34l16-1 11-7 7-10 2-7V21v-8c0-3 2-4 4-5h1c2 1 4 2 4 5"/>
                <Path id="Kreuz" d="M189 32v19h-19v19h-19V51h-19V32h19V14h19v18h19"/>
            </G>
        </Svg>
    );
}

IconHygiene.defaultProps = {
    color: '#FFF',
};

export default IconHygiene;