import React, {
    useState
} from 'react';
import {
    StyleSheet,
    View,
} from 'react-native';
import { Paragraph } from './StyledText';
import { t } from '@lingui/macro';
import WebButton from '../ui/WebButton';
import * as Styles from '../styles';
import DefaultInput from '../ui/DefaultInput';
import Requester from './Requester';
import Toast from '../interfaces/Toast';

const GenericContactForm = (props) => {
    const [telephoneNumber, setTelephoneNumber] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [yourConcern, setYourConcern] = useState('');
    const [isSendingMail, setIsSendingMail] = useState(false);

    const sendMail = async (content) => {
        if (isSendingMail) {
            return;
        }

        setIsSendingMail(true);
        let url;

        switch (props.type) {
            case GenericContactForm.TYPE_CONTACT:
                url = '/mail/' + props.contactData.id;
                break;

            case GenericContactForm.TYPE_SUPPORT:
                url = '/mail/support';
                break;

            case GenericContactForm.TYPE_SERVICE:
                url = '/serviceRequests/' + props.inventoryItemId;
                break;

            default:
                url = '/mail';
                break;
        }

        let networkError = false;
        const response   = await Requester.post(
            url,
            {
                ...content,
            },
        ).catch((e) => {
            networkError = true;
        });

        if (networkError) {
            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);

        if (response.status === 200) {
            Toast.showSuccess(t`mailSent`);
        } else {
            Toast.showError(t`error`);
        }

        setIsSendingMail(false);

        if (typeof props.onCloseCallback === 'function') {
            props.onCloseCallback(false);
        }
    }

    return (
        <View style={{marginHorizontal: 10}}>
            <View>
                <Paragraph style={{marginBottom: 10}}>{props.message}</Paragraph>
            </View>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                marginHorizontal: -10
            }}>
                <View style={{flex: 1}}>
                    <DefaultInput
                        onChange={setTelephoneNumber}
                        keyboardType={'phone-pad'}
                        placeholder={t`yourPhoneNumberPlaceholder`}
                        leftIconType={'phone'}
                        label={t`yourTelephoneNumber`}
                    />
                </View>
                <View style={{flex: 1}}>
                    <DefaultInput
                        onChange={setContactPerson}
                        placeholder={t`contactPersonNamePlaceholder`}
                        leftIconType={'user-md'}
                        label={t`contactPerson`}
                    />
                </View>
            </View>
            <View style={{
                marginHorizontal: -10
            }}>
                <DefaultInput
                    onChange={setYourConcern}
                    inputStyle={styles.inputComment}
                    leftIconType={'comments'}
                    label={t`yourConcern`}
                    multiline={true}
                />
            </View>
            <View style={{
                display: 'flex',
                alignSelf: 'flex-end',
                width: 250,
            }}>
                <WebButton
                    size={250}
                    disabled={isSendingMail}
                    onPress={() => {
                        const contactPersonKey      = t`contactPerson`;
                        const telephoneNumberKey    = t`yourTelephoneNumber`;
                        const yourConcernKey        = t`yourConcern`;
                        let content                 = {};
                        content[contactPersonKey]   = contactPerson;
                        content[telephoneNumberKey] = telephoneNumber;
                        content[yourConcernKey]     = yourConcern;

                        sendMail(content);
                    }}
                >{t`sendMessage`}</WebButton>
            </View>
        </View>
    );
}

GenericContactForm.TYPE_CONTACT = 1;
GenericContactForm.TYPE_SUPPORT = 2;
GenericContactForm.TYPE_SERVICE = 3;

GenericContactForm.defaultProps = {
    type: GenericContactForm.TYPE_CONTACT,
}

const styles = StyleSheet.create({
    divider: {
        width: 2,
        backgroundColor: 'white',
        marginLeft: 15,
        marginRight: 15,
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignContent: 'flex-start',
        height: '100%'
    },
    box: {
        borderWidth: 2,
        borderRadius: 7,
        width: '40%',
        minHeight: '20%',
        padding: 10,
        marginTop: 10
    },

    headline: {
        fontSize: 16,
        fontWeight: 'bold'
    },

    textInput: {
        ...Styles.Layouts.input,
        margin: 5
    },

    inputComment: {
        height: 120,
    },

    button: {
        width: '80%',
        padding: 6,
        backgroundColor: '#FF6F00',
        borderRadius: 7,
    },

    TextStyle: {
        color: '#fff',
        fontSize: 18,
        textAlign: 'center',
    },

    phone: {
        color: 'blue',
        textDecorationLine: 'underline',
    },
});

export default GenericContactForm;