import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
} from 'react-native-svg';

const IconUser = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="info" fill={color}>
                <Path fill="none" stroke={color} strokeWidth="14.1732" d="M280 245c0-34-5-78-128-78S24 211 24 245h256zM147 129c33 0 59-26 59-59s-26-59-59-59a59 59 0 000 118z"/>
            </G>
        </Svg>
    );
}

IconUser.defaultProps = {
    color: '#FFF',
};

export default IconUser;