global.l18nDate = (input) => {
    if (typeof input !== 'string') {
        return false;
    }

    const date = new Date(input.replace(/[-]/g, '/'));

    if (isNaN(date)) {
        return false;
    }

    return new Intl.DateTimeFormat(
        'de-DE',
        {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }
    ).format(date);
}
