import React, {
    useRef,
    useState,
} from 'react';
import {
    StyleSheet,
    View,
} from 'react-native';
import WebButton from '../ui/WebButton';
import {
    t,
    Trans
} from '@lingui/macro';
import * as Styles from '../styles';
import { Paragraph } from '../components/StyledText';
import Requester from '../components/Requester';
import Toast from '../interfaces/Toast';
import DefaultInput from '../ui/DefaultInput';
import PasswordInput from '../ui/PasswordInput';
import { useNavigation } from '@react-navigation/native';

const PasswordResetScreen = (props) => {
    const navigation    = useNavigation();
    const tokenInput    = useRef();
    const passwordInput = useRef();
    const token         = props.route?.params?.token || '';

    const [isPasswordHidden, setPasswordHidden] = useState(true);
    const [password, setPassword]               = useState('');

    const _saveNewPassword = async () => {
        let networkError  = false;
        const _password   = password;

        Requester.setToken(tokenInput.current.props.value);

        setPassword('');
        setPasswordHidden(false);

        const response = await Requester.post(
            '/resetPassword',
            {
                password: _password
            },
        ).catch(() => {
            networkError = true;
        });

        if (networkError) {
            Toast.showError(t`error`);
            return false;
        }

        if (response.status === 401) {
            Toast.showError(t`tokenNotValid`);
            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);

        Toast.showSuccess(t`passwordResetSuccessful`);
        navigation.navigate('LoginScreen');
        return true;
    }

    async function handleKeyUp(e) {
        e.which = e.which || e.keyCode;

        // If the key press is Enter
        if (e.which === 13) {
            switch (e.target.id) {
                case 'resetTokenInput':
                    passwordInput.current.focus();
                    break;

                case 'resetPasswordInput':
                    await _saveNewPassword();
                    break;

                default:
                    break;
            }
        }
    }

    return (
        <View
            style={[{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignSelf: 'center',
                width: '50%',
            }]}
        >
            <View>
                <Paragraph style={styles.passwordResetHead}><Trans id={'passwordResetHead'} /></Paragraph>
                <Paragraph style={styles.passwordResetBody}><Trans id={'passwordResetBody'} /></Paragraph>
                <View style={{display: token === '' ? 'block' : 'none'}}>
                    <DefaultInput
                        nativeID={'resetTokenInput'}
                        ref={tokenInput}
                        autoCompleteType={'off'}
                        label={t`code`}
                        placeholder={t`code`}
                        value={token}
                        onKeyPress={handleKeyUp}
                        leftIconType='key'
                    />
                </View>
                <PasswordInput
                    nativeID={'resetPasswordInput'}
                    ref={passwordInput}
                    isNewPassword={true}
                    password={password}
                    isPasswordHidden={isPasswordHidden}
                    onChange={(_password) => setPassword(_password)}
                    onKeyPress={handleKeyUp}
                    label={t`yourNewPassword`}
                    placeholder={t`yourNewPassword`}
                />
                <View>
                    <WebButton
                        onPress={() => _saveNewPassword()}
                    >{t`save`}</WebButton>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    background: {
        ...Styles.Layouts.backgroundImage,
    },
    passwordResetHead: {
        fontSize: 20,
    },
    passwordResetBody: {
        marginBottom: 20,
    }
});

export default PasswordResetScreen;