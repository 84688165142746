import React, {
    useState
} from 'react';
import {
    View,
    ScrollView,
    Text,
    StyleSheet,
} from 'react-native';
import AsyncStorage from '../interfaces/AsyncStorage';
import { NewsArticle } from './NewsArticle';
import * as Styles from '../styles';
import { ModalScreen } from './ModalScreen';
import { murmurhash3_32_gc } from '../libs/murmurhash3_gc';
import Requester from './Requester';
import { Overlay } from 'react-native-elements';
import { t } from '@lingui/macro';
import { useNavigation } from '@react-navigation/native';
import useAuth from './useAuth';

/**
 * @return {null}
 */
export function Newsbox(props) {
    const { signOut } = useAuth();

    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = dd + '.' + mm + '.' + yyyy;

    const showModal = props.showModal || false;
    const [showNews, showNewNews] = useState(props.showNews || false);
    const [isNewsFetched, setNewsFetched] = useState(false);
    const [newsElements, setNewsElements] = useState([
        {
            newsId: 1,
            date: today,
            headline: t`newsPlaceholderHeadline`,
            content: t`newsPlaceholderContent`,
        },
    ]);

    let newNewsHash = null;

    const handleNetworkError = () => {
    };

    const storeNews = async (response) => {
        if (response.ok) {
            let data = await response.json();
            setNewsFetched(true);
            setNewsElements(data);
        } else {
            signOut();
        }
    };

    const generateNewsHash = async (response) => {
        const seed   = 'MEIT_News';
        const string = JSON.stringify(newsElements);
        let hash     = murmurhash3_32_gc(string, seed)
        newNewsHash  = "" + hash;
    };

    const checkNewsVisibility = async (response) => {
        const storedNewsHash = await AsyncStorage.getItem('newsHash')

        if (storedNewsHash !== newNewsHash) {
            showNewNews(true);
            await AsyncStorage.setItem('newsHash', newNewsHash);
        }
    };

    if (!isNewsFetched) {
        Requester.get('/news')
            .then(storeNews)
            .then(generateNewsHash)
            .then(checkNewsVisibility)
            .catch(handleNetworkError)
        ;
    }

    if (!showNews) {
        return null;
    }

    const content =
        <ScrollView>
            {
                newsElements.map((newsArticle) =>
                    <NewsArticle key={newsArticle.newsId} news={newsArticle} />
                )
            }
        </ScrollView>
    ;

    if (showModal) {
        return (
            <Overlay
                isVisible={showNews}
                onBackdropPress={() => showNewNews(false)}
                windowBackgroundColor={"rgba(255, 255, 255, .5)"}
                overlayBackgroundColor={"#ffe5ca"}
                width={"65%"}
                height={"85%"}
            >
                <View style={styles.newsbox}>
                    {content}
                </View>
            </Overlay>
        );
    }

    return (
        <View style={styles.newsbox}>
            {content}
        </View>
    );
}

const styles = StyleSheet.create({
    newsbox: {
        // ...Styles.Layouts.modal,
        // top: 0,
        // left: 0,
        // // height: 300,
        // // backgroundColor: '#ccc',
    },
    title: {
        textAlign: 'center',
    },
});