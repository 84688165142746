import React from 'react';
import Svg, {
    G,
    Path
} from 'react-native-svg';

const IconItemList = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <G id="Pikto_Renner">
                <G id="Renner" fill={color}>
                    <Path id="Kreis" d="M284.5 91.2v.1l-12.3 42c-3-2.1-5.7-4.6-8.5-7l-22.9-18.8-.3-.3a237.6 237.6 0 00-2.1-2l14.5-4.2a107.3 107.3 0 00-42.1-59 107 107 0 00-69.3-18.2 106 106 0 00-96.5 117c1.5 15 6 27.6 11.9 38.6 8.7 16.4 21 29.7 36.4 39.4a104.3 104.3 0 0046.7 16.4 105.4 105.4 0 0095.7-42.1 103 103 0 0020.7-53.6v-1.4c.2-.3.4 0 .5.1 2.6 2 5.1 4.3 7.6 6.3l7 5.9c.4.2.8.5 1 .8a125.7 125.7 0 01-73.3 92.1 123.4 123.4 0 01-45.2 10h-6.7A123.6 123.6 0 0127 134.2c-.2 0 0 .8-.1 1v-8.6-.1A122.6 122.6 0 0163 42.2a124.3 124.3 0 0184.3-36.3h6.7a124 124 0 01115.7 89.9l14.8-4.6"/>
                    <Path id="Korb" d="M208.8 125.6V97.8h-97.3v.2l10.8 43c.1.3 0 .9.4 1.2l86.1-16.6m13.2-41v51.8l-96.2 18.7 2.6 10.4h85.7v13.2h-96L93.8 81.3 75 82.7c-3.3.3-5.6-.7-7-2.8a6.9 6.9 0 010-7.6c.8-1 1.8-1.7 3-2.2 1.2-.5 2.7-.5 4.3-.7l23.4-1.7 4.9-.4c.4 0 .3.3.5.9l4.1 16.5H222z"/>
                    <Path id="Rad_rechts" d="M218.3 197c0 3.4-1.2 6-3 7.9a10.4 10.4 0 01-7.3 3.4c-3.3 0-6-1.1-8-2.9a10.5 10.5 0 01-3.4-7.4 10.8 10.8 0 0110.3-11.4c3.4-.2 6.1 1.2 8 3 1.6 1.6 3.2 4 3.4 7.3"/>
                    <Path id="Rad_Links" d="M129.7 197c.1 3.4-1.1 6-3 7.9a10.5 10.5 0 01-7.3 3.4c-3.3.1-6-1.1-8-2.9a10.5 10.5 0 01-3.4-7.4 10.8 10.8 0 0118.3-8.5c1.7 1.7 3.3 4 3.4 7.4"/>
                </G>
            </G>
        </Svg>
    );
}
IconItemList.defaultProps = {
    color: '#FFF',
};

export default IconItemList;