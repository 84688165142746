import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
} from 'react-native-svg';

const IconShop = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="SHOP" fill={color}>
                <Path id="Tasche" d="M189 45v-6c0-2-2-4-3-5-3-5-7-9-12-12-3-3-7-4-11-6a40 40 0 00-19-1c-10 1-18 6-24 12l-7 9-1 4v20h77V45m57 140l-5-112h-39v17l-1 8c-1 2-3 3-6 3s-4-1-5-3l-1-8V73h-77v17l-1 8c-1 2-3 3-5 3-3 0-5-1-6-3l-1-8V73H60l-6 112h192m4 60l-3-47H54l-3 47h199zm13 12H38s0 1 0 0v-6l3-43 1-25 6-123h51V40l1-6c2-6 7-11 11-16 4-4 10-8 16-11s13-5 21-6h5a58 58 0 0137 17c4 4 9 10 11 16l1 6v20h51v1l10 196"/>
                <Path id="P" d="M220 139c3-2 3-11-1-13h-7v14l8-1m15-12c2 7 0 15-4 18-4 4-11 5-19 5v21h-13v-55h23c7 1 11 5 13 11"/>
                <Path id="O" d="M179 152v-19c-1-5-1-10-7-9h-2c-2 2-3 6-3 9v20c0 4 1 9 3 10h6c2-2 3-7 3-11m10-30c3 5 3 13 3 21 0 13 0 23-9 27-2 2-6 2-9 2-8 1-14-2-17-7s-4-13-4-20c0-8 1-16 3-22 3-5 8-8 16-8s14 2 17 7"/>
                <Path id="H" d="M147 116v55h-13v-23h-11v23h-13v-55h13v21h11v-21h13z"/>
                <Path id="S" d="M104 152c0 5 0 9-2 13-2 3-5 5-9 6-3 2-9 2-13 1-5-1-8-2-11-5-2-3-2-7-2-13h12c0 5 1 9 5 10 5 0 7-3 7-7s-3-6-7-7l-9-5c-3-1-6-4-7-7v-12c1-4 3-6 5-8 3-2 7-3 11-3 12-1 20 5 19 16H90l-1-6-5-1c-3 0-4 2-4 6s4 6 8 7c7 3 14 6 16 15"/>
            </G>
        </Svg>
    );
}

IconShop.defaultProps = {
    color: '#FFF',
};

export default IconShop;