import React, {
    useState,
    useRef,
    useCallback,
} from 'react';
import {
    Image,
    Linking,
    ScrollView,
    StyleSheet,
    View,
} from 'react-native';
import { Paragraph } from '../components/StyledText';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import MenuList from '../ui/MenuList';
import WebButton from '../ui/WebButton';
import { Icon } from 'react-native-elements';
import Overlay from '../ui/Overlay';
import GenericContactForm from '../components/GenericContactForm';

const MEITScreen = ({route, navigation, ...props}) => {
    const [isContactPersonWriteEmailVisible, setIsContactPersonWriteEmailVisible] = useState(false);
    const [overlayContactData, setOverlayContactData] = useState();
    const subPageId = route.params.subscreen;
    const scrollRef = useRef();

    const menuList = [
        {
            id: MEITScreen.PAGE_CONTACT,
            name: i18n._(t`menu.contactPerson`),
        },
        {
            id: MEITScreen.PAGE_ABOUT_US,
            name: i18n._(t`aboutUs.headline`),
        },
        {
            id: MEITScreen.PAGE_IMPRINT,
            name: i18n._(t`imprint`),
        },
        {
            id: MEITScreen.PAGE_PRIVACY_POLICY,
            name: i18n._(t`privacyPolicy`),
        },
    ];

    const contactPersonData = [
        {
            id: 0,
            photo: require('../assets/images/Jochen_Spicker.jpg'),
            name: 'Jochen Spicker',
            position: 'Geschäftsführender Gesellschafter',
            responsibilities: 'Vertrag & Finanzen',
            phone: '+49 2131-23454',
            fax: '+49 2131-23209',
            email: 'jochen.spicker@meit.de',
        },
        {
            id: 2,
            photo: require('../assets/images/Oliver_Tiede.jpg'),
            name: 'Diplom-Ingenieur Oliver Tiede',
            position: 'Technischer Leiter Medizintechnik',
            responsibilities: 'Ihr Ansprechpartner für Medizintechnik',
            phone: '+49 2131-23454',
            fax: '+49 2131-23209',
            email: 'oliver.tiede@meit.de',
        },
        {
            id: 1,
            photo: require('../assets/images/Thorsten_Buehlow.jpg'),
            name: 'Thosten Bühlow',
            position: 'Medizinprodukteberater/Außendienst',
            responsibilities: 'Termine & Support, sonstige Anfragen',
            phone: '+49 2131-23454',
            fax: '+49 2131-23209',
            email: 'thorsten.buehlow@meit.de',
        },
    ];

    const DialButton = (data) => {
        const handlePhonePress = useCallback(async () => {
            const phoneCmd    = 'tel:' + data.phoneNumber.replace(/[-\s]/g, '');
            const isSupported = await Linking.canOpenURL(phoneCmd);

            if (isSupported === true) {
                await Linking.openURL(phoneCmd);
            }
        },[data]);

        return (
            <WebButton
                size={'90%'}
                onPress={handlePhonePress}
                icon={
                    <Icon
                        name={'phone'}
                        type={'font-awesome'}
                        color={'#fff'}
                        underlayColor={'#ccc'}
                        size={30}
                        containerStyle={{marginLeft: 5}}
                    />
                }
            >{i18n._(t`callUs`)}</WebButton>
        )
    }

    const contactPerson = () => (
        <View style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginLeft: 15,
        }}>
            <View>
                <Paragraph style={styles.contactWelcome}><Trans id={'WeAreThere'} /></Paragraph>
                <Paragraph style={styles.contactGetIntoTouch}><Trans id={'GetIntoTouch'} /></Paragraph>
            </View>
            { contactPersonData.map((_contactPerson) => {
                return (
                    <View
                        key={_contactPerson.name}
                        style={styles.contactPersonContainer}
                    >
                        <Image
                            style={styles.contactPersonsPhoto}
                            // source={{uri: _contactPerson.photo}} // only for URL loaded images via BE
                            source={_contactPerson.photo}
                            resizeMode={'contain'}
                        />
                        <View style={styles.contactPersonDataContainer}>
                            <Paragraph style={styles.contactPersonsName}>{_contactPerson.name}</Paragraph>
                            <Paragraph style={styles.contactPersonsPosition}>{_contactPerson.position}</Paragraph>
                            <Paragraph style={styles.contactPersonsResponsibilities}>{_contactPerson.responsibilities}</Paragraph>
                            <View style={styles.contactPersonPhoneContainer}>
                                <Paragraph style={styles.contactPersonsPhoneText}><Trans id={'telephoneAbbreviation'} /></Paragraph>
                                <Paragraph style={styles.contactPersonsPhoneNumber}>{_contactPerson.phone}</Paragraph>
                            </View>
                            <View style={styles.contactPersonFacsimileContainer}>
                                <Paragraph style={styles.contactPersonsFaxText}><Trans id={'facsimileAbbreviation'} /></Paragraph>
                                <Paragraph style={styles.contactPersonsFaxNumber}>{_contactPerson.fax}</Paragraph>
                            </View>
                            <Paragraph style={styles.contactPersonsEmail}>{_contactPerson.email}</Paragraph>
                            <View style={styles.contactPersonActionContainer}>
                                <View style={{width: 200}}>
                                    <DialButton phoneNumber={_contactPerson.phone} />
                                </View>
                                <View style={{width: 200}}>
                                    <WebButton
                                        size={'90%'}
                                        onPress={() => {
                                            setOverlayContactData(_contactPerson);
                                            setIsContactPersonWriteEmailVisible(true);
                                        }}
                                        icon={
                                            <Icon
                                                name={'at'}
                                                type={'font-awesome'}
                                                color={'#fff'}
                                                underlayColor={'#ccc'}
                                                size={30}
                                                containerStyle={{marginLeft: 5}}
                                            />
                                        }
                                    >{i18n._(t`emailUs`)}</WebButton>
                                </View>
                            </View>
                        </View>
                    </View>
                );
            })}
        </View>
    );

    const aboutUs = () => (
        <View style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            marginLeft: 15,
        }}>
            <Paragraph style={styles.imageSubtext}><Trans id={'aboutUs.imageSubtext'}/></Paragraph>
            <Paragraph style={styles.headline}><Trans id={'aboutUs.headline'}/></Paragraph>
            <Paragraph style={styles.aboutUs}><Trans id={'aboutUs.content'}/></Paragraph>
            <View style={styles.quoteContainer}>
                <Paragraph style={styles.quote}><Trans id={'aboutUs.quote'}/></Paragraph>
                <Paragraph style={styles.quoted}><Trans id={'aboutUs.quoted'}/></Paragraph>
            </View>
        </View>
    );

    const imprint = () => (
        <View style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            marginLeft: 15,
        }}>
            <Paragraph style={styles.imprintContent}><Trans id={'imprintContent'}/></Paragraph>
        </View>
    );

    const privacyPolicy = () => (
        <View style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            marginLeft: 15,
        }}>
            <Paragraph style={styles.privacyPolicyContent}><Trans id={'privacyPolicyContent'}/></Paragraph>
        </View>
    );

    const menuListCallback = (item) => {
        scrollRef.current.scrollTo({
            x: 0,
            y: 0,
            animated: true,
        });

        switch (item.id) {
            case MEITScreen.PAGE_CONTACT:
                navigation.navigate('ContactUs');
                break;

            case MEITScreen.PAGE_ABOUT_US:
                navigation.navigate('Team');
                break;

            case MEITScreen.PAGE_IMPRINT:
                navigation.navigate('Imprint');
                break;

            case MEITScreen.PAGE_PRIVACY_POLICY:
                navigation.navigate('PrivacyPolicy');
                break;
        }
    }

    return (
        <View style={styles.container}>
            <MenuList
                list={menuList}
                onListElementPressed={menuListCallback}
                activeIndex={subPageId}
                disableState={true}
            />
            <Overlay
                isVisible={isContactPersonWriteEmailVisible}
                setVisibility={setIsContactPersonWriteEmailVisible}
                titleText={i18n._(/*i18n*/'getInTouchWith', { name: overlayContactData?.name})}
                width={900}
                height={420}
            >
                <GenericContactForm
                    // message={<Trans id={'serviceDescription'} />}
                    message={'Wenn Sie Fragen haben oder Hilfe benötigen, können Sie mir gerne eine E-Mail schreiben. Ich werde sie zeitnah beantworten.'}
                    type={GenericContactForm.TYPE_CONTACT}
                    onCloseCallback={setIsContactPersonWriteEmailVisible}
                    contactData={overlayContactData}
                />
            </Overlay>
            <ScrollView ref={scrollRef}>
                { subPageId === MEITScreen.PAGE_CONTACT        && contactPerson() }
                { subPageId === MEITScreen.PAGE_ABOUT_US       && aboutUs() }
                { subPageId === MEITScreen.PAGE_IMPRINT        && imprint() }
                { subPageId === MEITScreen.PAGE_PRIVACY_POLICY && privacyPolicy() }
            </ScrollView>
        </View>
    );
}

MEITScreen.PAGE_CONTACT        = 0;
MEITScreen.PAGE_ABOUT_US       = 1;
MEITScreen.PAGE_IMPRINT        = 2;
MEITScreen.PAGE_PRIVACY_POLICY = 3;

export default MEITScreen;

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 18,
    },

    //contact person section
    contactWelcome: {
        marginBottom: 10,
    },
    contactGetIntoTouch: {
        marginBottom: 50,
    },
    contactPersonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 50,
    },
    contactPersonsPhoto: {
        width: 150,
        height: 200,
    },
    contactPersonDataContainer: {
        marginLeft: 20,
        alignSelf: 'flex-start',
    },
    contactPersonsName: {

    },
    contactPersonsPosition: {
        fontSize: 14,
    },
    contactPersonsResponsibilities: {
        fontSize: 14,
    },
    contactPersonPhoneContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
    },
    contactPersonsPhoneText: {
        fontSize: 14,
        width: 50,
    },
    contactPersonsPhoneNumber: {
        fontSize: 14,
    },
    contactPersonFacsimileContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    contactPersonsFaxText: {
        fontSize: 14,
        width: 50,
    },
    contactPersonsFaxNumber: {
        fontSize: 14,
    },
    contactPersonsEmail: {
        marginTop: 10,
        fontSize: 14,
    },
    contactPersonActionContainer: {
        alignItems: 'flex-end',
        flex: 1,
        flexDirection: 'row',
    },

    // support section
    supportHeadline: {
        marginBottom: 20,
    },

    // about us section
    imageSubtext: {
        fontSize: 18,
        marginVertical: 10,
    },
    headline: {
        fontSize: 26,
        fontWeight: 'bold',
        marginVertical: 20,
    },
    aboutUs: {
        fontSize: 16,
        flex: 1,
    },
    quoteContainer: {
        width: 350,
        alignSelf: 'flex-end',
        marginBottom: 50,
    },
    quote: {
    },
    quoted: {
        fontSize: 16,
        textAlign: 'right',
    },

    // imprint section
    imprintContent: {
        fontSize: 16,
        marginVertical: 10,
    },

    // privacy policy section
    privacyPolicyContent: {
        fontSize: 16,
        marginVertical: 10,
    },
});