import * as colors from './colors';

export const modal = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 99,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)'
};

export const row = {
    display: 'flex',
    flexDirection: 'row'
};

export const backgroundImage = {
    opacity: 1.0,
    width: '100%',
    height: '100%',
    alignSelf: 'center',
};

export const inputContainer = {
    width: '100%',
    marginVertical: 10,
    borderWidth: 2,
    borderColor: colors.colorPrimary1,
    backgroundColor: colors.colorPrimary2,
}

export const input = {
    width: '100%',
    color: colors.colorText0,
    textAlign: 'center',
}

export const grayHeadlineBar = {
    color: '#fff',
    backgroundColor: colors.grayNormal,
    textAlign: 'center',
    paddingVertical: 10,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
}