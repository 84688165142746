import React, {
    useState,
    useContext,
} from 'react';
import {
    FlatList,
    StyleSheet,
    TouchableOpacity,
    View,
} from 'react-native';

import Requester from '../components/Requester';
import ContentLoader, {
    Rect
} from '../ui/ContentLoader';
import PdfView from '../components/PdfView';
import * as SVG from '../assets/images/svg/svg';
import { Paragraph } from '../components/StyledText';
import { Trans } from '@lingui/react';
import MenuList from '../ui/MenuList';
import { AuthContext } from '../components/AuthContext';

const DocumentsScreen = ({route, navigation, ...props}) => {
    const [authState] = useContext(AuthContext);

    const [isDocumentListLoaded,  setDocumentListLoaded]  = useState(false);
    const [isDocumentListLoading, setDocumentListLoading] = useState(false);
    const [sortedList, setList]                           = useState();
    const [categoryName, setCategoryName]                 = useState();

    const loadDocumentList = async () => {
        let networkError = false;
        const response   = await Requester.get(
            '/directories/' + authState.user.facilityId + '/' + route.params?.subscreen
        ).catch((e) => {
            networkError = true;
        });

        if (networkError) {
            setDocumentListLoaded(false);
            setDocumentListLoading(false);
            navigation.goBack();

            return false;
        }

        let data = await response.json();
        Requester.debug(response, data);

        data = Object.values(data).filter(list => {
            return list.hasOwnProperty('name');
        });

        const _sortedList = [...data].sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            let comparison = 0;

            if (nameA > nameB) {
                comparison = 1;
            } else if (nameA < nameB) {
                comparison = -1;
            }

            return comparison;
        });

        setList(_sortedList);
        setCategoryName(_sortedList.filter(item => item.isDir)[0]?.name)
        setDocumentListLoaded(true);
        setDocumentListLoading(false);
    }

    if (!isDocumentListLoaded && !isDocumentListLoading) {
        setDocumentListLoading(true);
        loadDocumentList();
    }

    const MyLoader = () => (
        <ContentLoader>
            <Rect x="20" y="15" rx="0" ry="0" width="280" height="30" />
            <Rect x="20" y="60" rx="0" ry="0" width="280" height="30" />
            <Rect x="20" y="105" rx="0" ry="0" width="280" height="30" />
            <Rect x="320" y="15" rx="0" ry="0" width="250" height="200" />
            <Rect x="590" y="15" rx="0" ry="0" width="250" height="200" />
            <Rect x="860" y="15" rx="0" ry="0" width="250" height="200" />
        </ContentLoader>
    );

    if (!isDocumentListLoaded) {
        return (
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <MyLoader />
            </View>
        )
    }

    const documents       = sortedList.filter(item => categoryName === item.name)[0]?.content;
    const sortedDocuments = documents ? [...documents].sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;

        if (nameA > nameB) {
            comparison = 1;
        } else if (nameA < nameB) {
            comparison = -1;
        }

        return comparison;
    }) : [];

    if (sortedList.length === 0) {
        return (
            <View style={[
                styles.scene,
                styles.empty
            ]}>
                <View>
                    <Paragraph style={styles.title}><Trans id={'noDocumentsAvailable'} /></Paragraph>
                </View>
            </View>
        )
    }

    const menuListCallback = (item) => {
        setCategoryName(item.name)
    }

    return (
        <View style={styles.scene}>
            <View style={[styles.listContainer]}>
                <MenuList
                    list={sortedList.filter(item => item.isDir)}
                    onListElementPressed={menuListCallback}
                />
                <View style={{flex: 1}} />
            </View>
            <View style={[styles.articleView]}>
                {sortedDocuments.length === 0 ?
                    <View style={[
                        styles.scene,
                        styles.empty
                    ]}>
                        <View>
                            <Paragraph style={styles.title}><Trans id={'noDocumentsAvailable'}/></Paragraph>
                        </View>
                    </View>
                    :
                    <FlatList
                        style={[{marginTop: 15, marginLeft: 10}]}
                        keyExtractor={(item, itemIndex) => `${itemIndex}`}
                        data={sortedDocuments}
                        numColumns={4}
                        renderItem={({item, index}) => {
                            if (item.isDir) {
                                return null;
                            }

                            const title      = item.name.substring(0, item.name.length - 4);
                            const shortTitle = title.substr(-4, 4);

                            return (
                                <TouchableOpacity
                                    style={[
                                        styles.gridItemContainer,
                                        {
                                            flex: 1 / 4,
                                        }
                                    ]}
                                    onPress={() => {
                                        const file = '/file' + item.path + '/' + item.name;
                                        PdfView.show(file);
                                    }}
                                >
                                    <View style={{alignItems: 'center'}}>
                                        <View style={{
                                            width: 120,
                                            height: 120,
                                        }}>
                                            <SVG.IconDocument
                                                color={"#1EA6DF"}
                                                { ...(!isNaN(parseInt(shortTitle)) ? {text: title.substr(-4, 4)} : undefined)}
                                            />
                                        </View>
                                        <Paragraph style={styles.name}>{title}</Paragraph>
                                    </View>
                                </TouchableOpacity>
                            )
                        }}
                    />
                }
            </View>
        </View>
    );
}

export default DocumentsScreen;

const styles = StyleSheet.create({
    scene: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginLeft: 15,
    },
    empty: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listContainer: {
        width: 250,
        marginTop: 18,
    },
    articleView: {
        flex: 1,
        marginLeft: 7,
        paddingLeft: 7,
    },
    gridItemContainer: {
        flex: 1,
        marginHorizontal: 7,
        paddingTop: 15,
        marginBottom: 15,
        alignItems: 'flex-start',
    },
    item: {
        padding: 5,
    },
    name: {
        alignSelf: 'center',
    }
});
