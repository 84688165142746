import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
    Text,
    Mask,
    Rect,
    TSpan,
} from 'react-native-svg';

const IconDocument = (propsParam) => {
    const { color, text, ...props } = propsParam;
    const mask = Math.random();

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs>
                <Mask id={"text" + mask}>
                    <Rect width={"100%"} height={"100%"} fill={"white"}/>
                    {text === ''
                        ? <Path fill="none" stroke="black" strokeWidth="8.5039" strokeMiterlimit="10" d="M45 197l4-1c8-5 17-9 25-15 13-10 25-22 34-36 3-4-5-4-10-1l-20 20c-3 3-8 8-8 12 5 2 14-2 18-3l34-14c-1 3-6 6-8 9l-9 10c-1 1-5 5-5 7l11-4c8-4 15-10 24-13l-16 16c-1 0-12 9-11 12 12 1 27-8 36-14 18-10 35-22 52-33 0 15-13 21-19 32 7-3 14-7 22-9-1 3-7 6-9 9 2 0 11 0 9 3 0 3 24 2 24 2h25c2 0 8 1 11-1"/>
                        : <Text
                            fontSize={"60px"}
                            fill={"black"}
                            fontWeight={600}
                            fontFamily="'openSans'"
                            textAnchor={'middle'}
                        >
                            <TSpan x={150} y={190}>{text}</TSpan>
                        </Text>
                    }
                </Mask>
            </Defs>
            <G fill={color} id="pictogram">
                <Path id="document" mask={"url(#text" + mask + ")"} d="m 73,256 c -13,0 -13,0 -13,-13 V 16.96241 C 60,3.90603 60,3.96057 73,3.96057 H 199.11488 L 241.9948,47.09064 242,243 c 0,13 0,13 -13,13 z M 229,243 V 52.461134 L 193.73296,17 H 73 v 226 z"/>
                <Path id="upperRightEdge" d="m 234.42159,48.602663 a 26,26 0 0 1 -36.66385,-0.3334 26,26 0 0 1 0.12591,-36.665146"/>
                <Path id="lineTop" d="M171 46v10H98V46h73z"/>
                <Path id="lineMiddle" d="M204 68v10H98V68h106"/>
                <Path id="lineBottom" d="M204 90v9H98v-9h106z"/>
                <Rect id="labelArea" x={37} y={115} width={225} height={104} mask={"url(#text" + mask + ")"}/>
            </G>
        </Svg>
    );
}

IconDocument.defaultProps = {
    color: '#FFF',
    text: '',
};

export default IconDocument;