import Constants from 'expo-constants';
import { debug } from './constants/Colors';
require('./config/settings');
require('./helpers/helper');
import {
    // Notifications,
} from 'expo';
import * as SplashScreen from 'expo-splash-screen';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import * as Updates from 'expo-updates';
import 'react-native-gesture-handler';
import React, {
    useState,
    useEffect,
} from 'react';
import {
    Platform,
    StatusBar,
    View,
    StyleSheet,
    Text,
    Image,
    ToastAndroid,
} from 'react-native';
import AsyncStorage from './interfaces/AsyncStorage';
import Log from './components/Log';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Ionicons } from '@expo/vector-icons';
import AppNavigator from './navigation/AppNavigator';
import {
    AuthProvider
} from './components/AuthContext';
import { MenuProvider } from './context/MenuContext';
import { CartProvider } from './components/CartContext';
import { CatalogProvider } from './components/CatalogContext';
import { ItemListProvider } from './components/ItemListContext';
import { InventoryProvider } from './components/InventoryContext';
import { StorageProvider } from './components/StorageContext';
import * as MediaLibrary from 'expo-media-library';
import { Camera } from 'expo-camera';
import * as Linking from 'expo-linking';
import Toast from './interfaces/Toast';
import KeepAlive from './components/KeepAlive';
import SessionLoader from './components/SessionLoader';
import PlatformBehavior from './components/PlatformBehavior';


import { I18nProvider } from '@lingui/react';
require('./helpers/l18nDate');
require('./helpers/i18n');
require('./helpers/l18nPrice');
import { t } from '@lingui/macro';
import 'intl';
require( 'intl/locale-data/jsonp/de' )
import {
    listenOrientationChange,
    removeOrientationListener
} from './libs/react-native-responsive-screen';

global.token = null;

const App = (props) => {
    const [orientation, setOrientation] = useState();
    const platformBehavior = new PlatformBehavior();
    platformBehavior.ignoreLogBoxLogs();
    global.MEIT.init();

    const [areResourcesReady, setResourcesReady]  = useState(false);

    useEffect(() =>
        {
            SplashScreen.preventAutoHideAsync();

            _cacheResourcesAsync() // ask for resources
                .then(() => setTimeout(
                    setResourcesReady,
                    2000,
                    true
                )) // mark resources as loaded
                .catch(error => console.error(`Unexpected error thrown when loading: ${error.stack}`));

            const logInit = new Log(debug.FgGreen, debug.BgBlack, 'Start up');
            logInit.add('GENLINK', Linking.makeUrl('MyDevice/1', {}));

            Linking.getInitialURL().then(url => {
                logInit.add('host URL', url);
                logInit.add('Linking URL', Linking.parse(url));

                if (url) {
                    AsyncStorage.setItem('initialRoute', JSON.stringify(url));
                }

                logInit.flush();
            });
            Linking.getInitialURL().then(url => {
                logInit.add('Linking URL', Linking.parse(url));

                if (url) {
                    AsyncStorage.setItem('initialRoute', JSON.stringify(url));
                }

                logInit.flush();
            });

            // Notifications.addListener(notification => {
            //     console.log('notification', notification);
            // });

            Updates.addListener(event => {
                console.log('Update status', event.type, event);
                if (event.type === Updates.UpdateEventType.UPDATE_AVAILABLE) {
                    //@TODO-Updates Use only Toast
                    Toast.showInfo(t`newUpdateIsInstalled`);
                    // ToastAndroid.showWithGravity(t`newUpdateIsInstalled`, ToastAndroid.LONG, ToastAndroid.TOP);
                    Updates.reloadAsync();
                }
            });
        },
    []
    );

    useEffect(
        () => {
            listenOrientationChange(setOrientation);

            return function cleanup() {
                removeOrientationListener();
            }
        },
        []
    );

    useEffect(
        () => {
            // dummy function for reacting on orientation
        },
        [orientation]
    );

    const _cacheResourcesAsync = async () => {
        await SplashScreen.hideAsync();

        const images = [
            require('./assets/images/placeholder.jpg'),
        ];

        const cacheImages = images.map(image => {
            return Asset.fromModule(image).downloadAsync();
        });

        await Promise.all([
            cacheImages,
            Font.loadAsync({
                // This is the font that we are using for our tab bar
                ...Ionicons.font,
                'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
                'neucha': require('./assets/fonts/Neucha-Regular.ttf'),
                'openSans': require('./assets/fonts/OpenSans-Regular.ttf'),
                'openSansSemiBold': require('./assets/fonts/OpenSans-SemiBold.ttf'),
                'openSansBold': require('./assets/fonts/OpenSans-Bold.ttf'),
                'openSansBoldItalic': require('./assets/fonts/OpenSans-BoldItalic.ttf'),
            }),
        ]);
    };

    if (!areResourcesReady && !props.skipLoadingScreen) {
        return (
            <View style={{ flex: 2 }}>
                <Image
                    source={require('./assets/images/splash.png')}
                    onLoadEnd={async () => {
                        // wait for image's content to fully load [`Image#onLoadEnd`] (https://facebook.github.io/react-native/docs/image#onloadend)
                        await SplashScreen.hideAsync(); // Image is fully presented, instruct SplashScreen to hide
                    }}
                    fadeDuration={0} // we need to adjust Android devices (https://facebook.github.io/react-native/docs/image#fadeduration) fadeDuration prop to `0` as it's default value is `300`
                    style={{
                        flex: 1,
                        resizeMode: 'contain',
                        width: undefined,
                        height: undefined,
                        // width: '100%',
                        // height: '100%',
                    }}
                />
                <View style={{
                    position: 'absolute',
                    bottom: 50,
                    right: 80,
                }}>
                    <Text style={{fontSize: 18}}>Version: {Constants.manifest.name}-{Constants.manifest.version}</Text>
                </View>
                {Toast.init()}
            </View>
        );
    } else {
        global.currentScreenIndex = 0;
        MEIT.getPermissions();

        return (
            <AuthProvider>
                {/*<SessionLoader/>*/}
                <I18nProvider i18n={i18n}>
                    <KeepAlive/>
                    {/*<CatalogProvider>*/}
                        <MenuProvider>
                            <ItemListProvider>
                                <CartProvider>
                                    <InventoryProvider>
                                        <StorageProvider>
                                            <SafeAreaProvider>
                                                <KeyboardAwareScrollView
                                                    resetScrollToCoords={{ x: 0, y: 0 }}
                                                    contentContainerStyle={styles.appContainer}
                                                    scrollEnabled={true}
                                                >
                                                    {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
                                                    <AppNavigator/>
                                                </KeyboardAwareScrollView>
                                            </SafeAreaProvider>
                                        </StorageProvider>
                                    </InventoryProvider>
                                </CartProvider>
                            </ItemListProvider>
                        </MenuProvider>
                     {/*</CatalogProvider>*/}
                </I18nProvider>
                {Toast.init()}
            </AuthProvider>
        );
    }
}

const styles = StyleSheet.create({
    appContainer: {
        flex: 1,
    },
});

export default App;
