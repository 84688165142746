import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
} from 'react-native-svg';

const IconInfo = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="info" fill={color}>
                <Path d="M150 4a125 125 0 100 251 125 125 0 000-251zm-2 31a28 28 0 110 56 28 28 0 010-56zm45 188h-85v-6l13-3c3-3 5-7 5-13v-76c0-5-1-9-4-11l-14-4v-6h68v98c0 6 1 10 4 11 2 2 6 3 13 4v6z"/>
            </G>
        </Svg>
    );
}

IconInfo.defaultProps = {
    color: '#FFF',
};

export default IconInfo;