import React, {
    useEffect,
    useState,
} from 'react';
import {
    Image,
    TouchableHighlight,
    View,
} from 'react-native';
import { grayNormal } from '../styles/colors';
import AuthImage from "./AuthImage";

const ImageGallery = (props) => {
    const [visibleImage, setVisibleImage] = useState();

    useEffect(() =>
        {
           if (props.imageList.length) {
               setVisibleImage('/file' + props.imageList[0].path + '/' + props.imageList[0].name);
           }
        },
        []
    );

    const horizontalThumbnails = () => {
        if (props.imageList.length === 0) {
            return null;
        }

        return (
            <View style={{
                marginTop: 3,
                width: 200,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'space-between',
                justifyContent: 'space-evenly',
            }}>
                {
                    (props.imageList.length &&
                        props.imageList.map((image, imageKey) => (
                            <TouchableHighlight
                                style={{borderWidth: 1, borderColor: grayNormal}}
                                key={imageKey + '_touch'}
                                onPress={() => {setVisibleImage('/file' + image.path + '/' + image.name)}}
                            >
                                <AuthImage
                                    key={imageKey + '_image'}
                                    style={{width: 50, height: 50}}
                                    source={'/file' + image.path + '/' + image.name}
                                    loadingIndicatorSource={require('../assets/images/placeholder.jpg')}
                                    resizeMode={'contain'}
                                />
                            </TouchableHighlight>
                        ))
                    )
                }
            </View>
        )
    }

    if (props.imageList.length === 0) {
        return (
            <Image
                style={{width: 200, height: 200, borderWidth: 1, borderColor: grayNormal}}
                source={require('../assets/images/placeholder.jpg')}
                resizeMode={'contain'}
            />
        )
    }

    return (
        <View>
            {
                (visibleImage === undefined ?
                    <Image
                        style={{width: 200, height: 200, borderWidth: 1, borderColor: grayNormal}}
                        source={require('../assets/images/placeholder.jpg')}
                    />
                    :
                    <AuthImage
                        style={{
                            width: 200,
                            height: 200,
                        }}
                        source={visibleImage}
                        loadingIndicatorSource={require('../assets/images/placeholder.jpg')}
                        resizeMode={'contain'}
                    />
                )
            }
            {horizontalThumbnails()}
        </View>
    )
}

ImageGallery.defaultProps = {
    imageList: [],
};

export default ImageGallery;