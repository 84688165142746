/**
 * reference: https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
 */
import React, { useState } from 'react';

const CatalogContext = React.createContext([{}, () => {}]);

const CatalogProvider = (props) => {
    const [state, setState] = useState({
        isLoading: false,
        isLoaded: false,
        articles: [],
    });

    return (
        <CatalogContext.Provider value={[state, setState]}>
            {props.children}
        </CatalogContext.Provider>
    );
};

export { CatalogContext, CatalogProvider };