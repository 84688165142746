import { debug } from '../constants/Colors';

class Log {
    constructor(foregroundColor, backgroundColor, title) {
        this.foregroundColor = foregroundColor;
        this.backgroundColor = backgroundColor;
        this.title           = '%c===' + new Date().toLocaleString() + ': ' + title + '==================================================================================';
        this.data            = [];
    }

    flush() {
        console.group(this.title, 'color: ' + this.foregroundColor + '; background-color: ' + this.backgroundColor + ';');
        console.log(...this.data);
        console.groupEnd();
    }

    add() {
        for (let i = 0; i < arguments.length; i++) {
            //deep copy
            this.data.push(JSON.stringify(arguments[i], null, 4));
        }

        this.data.push("\n");
    }

    highlightColor(foregroundColor = '', backgroundColor = '') {
        // if (foregroundColor) {
        //     this.data.push(foregroundColor);
        // }
        //
        // if (backgroundColor) {
        //     this.data.push(backgroundColor);
        // }
    }

    resetColor() {
        // this.data.push(debug.Reset);
        // this.data.push(this.foregroundColor);
        // this.data.push(this.backgroundColor);
    }
}

export default Log;