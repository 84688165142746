import React, {
    useContext,
    useState,
} from 'react';
import {
    View,
    StyleSheet,
    FlatList,
    TouchableOpacity,
    Image
} from 'react-native';
import {
    ButtonGroup,
    Icon
} from 'react-native-elements';
import DefaultCheckBox from '../ui/DefaultCheckBox';
import { useNavigation } from '@react-navigation/native';
import { Paragraph } from '../components/StyledText';
import * as Styles from '../styles/index';
import Requester from '../components/Requester';
import * as colors from '../styles/colors';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { InventoryContext } from '../components/InventoryContext';
import useInventory from '../components/useInventory';
import ContentLoader, {
    Rect
} from '../ui/ContentLoader';
import AuthImage from '../components/AuthImage';
import { AuthContext } from '../components/AuthContext';

const MyDevicesScreen = (props) => {
    const navigation = useNavigation();

    const { getInventoryByType, TYPE_DEVICES } = useInventory();

    const [authState]        = useContext(AuthContext);
    const [inventoryContent] = useContext(InventoryContext);

    const [maintenanceFilter, setMaintenanceFilter] = useState([1, 2, 3]);
    const [displayMode, setDisplayMode]             = useState(0);

    const MyLoader = () => (
        <ContentLoader>
            <Rect x="328" y="21" rx="0" ry="0" width="207" height="207" />
            <Rect x="83" y="62" rx="0" ry="0" width="128" height="12" />
            <Rect x="391" y="265" rx="0" ry="0" width="121" height="12" />
            <Rect x="396" y="244" rx="0" ry="0" width="115" height="12" />
            <Rect x="409" y="287" rx="0" ry="0" width="83" height="12" />
            <Rect x="83" y="120" rx="0" ry="0" width="128" height="12" />
            <Rect x="83" y="181" rx="0" ry="0" width="128" height="12" />
            <Rect x="40" y="14" rx="0" ry="0" width="128" height="12" />
            <Rect x="42" y="227" rx="0" ry="0" width="128" height="12" />
            <Rect x="65" y="260" rx="9" ry="9" width="60" height="40" />
            <Rect x="191" y="260" rx="9" ry="9" width="60" height="40" />
            <Rect x="255" y="55" rx="9" ry="9" width="26" height="26" />
            <Rect x="255" y="115" rx="9" ry="9" width="26" height="26" />
            <Rect x="255" y="173" rx="9" ry="9" width="26" height="26" />
            <Rect x="319" y="257" rx="9" ry="9" width="27" height="26" />
            <Rect x="43" y="55" rx="0" ry="0" width="26" height="26" />
            <Rect x="43" y="115" rx="0" ry="0" width="26" height="26" />
            <Rect x="43" y="173" rx="0" ry="0" width="26" height="26" />
            <Rect x="597" y="21" rx="0" ry="0" width="207" height="207" />
            <Rect x="660" y="265" rx="0" ry="0" width="121" height="12" />
            <Rect x="665" y="244" rx="0" ry="0" width="115" height="12" />
            <Rect x="678" y="287" rx="0" ry="0" width="83" height="12" />
            <Rect x="588" y="257" rx="9" ry="9" width="27" height="26" />
            <Rect x="858" y="21" rx="0" ry="0" width="207" height="207" />
            <Rect x="921" y="265" rx="0" ry="0" width="121" height="12" />
            <Rect x="926" y="244" rx="0" ry="0" width="115" height="12" />
            <Rect x="939" y="287" rx="0" ry="0" width="83" height="12" />
            <Rect x="849" y="257" rx="9" ry="9" width="27" height="26" />
            <Rect x="1118" y="21" rx="0" ry="0" width="207" height="207" />
            <Rect x="1181" y="265" rx="0" ry="0" width="121" height="12" />
            <Rect x="1186" y="244" rx="0" ry="0" width="115" height="12" />
            <Rect x="1199" y="287" rx="0" ry="0" width="83" height="12" />
            <Rect x="1109" y="257" rx="9" ry="9" width="27" height="26" />
            <Rect x="338" y="341" rx="0" ry="0" width="207" height="207" />
            <Rect x="401" y="585" rx="0" ry="0" width="121" height="12" />
            <Rect x="406" y="564" rx="0" ry="0" width="115" height="12" />
            <Rect x="419" y="607" rx="0" ry="0" width="83" height="12" />
            <Rect x="329" y="577" rx="9" ry="9" width="27" height="26" />
            <Rect x="607" y="341" rx="0" ry="0" width="207" height="207" />
            <Rect x="670" y="585" rx="0" ry="0" width="121" height="12" />
            <Rect x="675" y="564" rx="0" ry="0" width="115" height="12" />
            <Rect x="688" y="607" rx="0" ry="0" width="83" height="12" />
            <Rect x="598" y="577" rx="9" ry="9" width="27" height="26" />
            <Rect x="868" y="341" rx="0" ry="0" width="207" height="207" />
            <Rect x="931" y="585" rx="0" ry="0" width="121" height="12" />
            <Rect x="936" y="564" rx="0" ry="0" width="115" height="12" />
            <Rect x="949" y="607" rx="0" ry="0" width="83" height="12" />
            <Rect x="859" y="577" rx="9" ry="9" width="27" height="26" />
            <Rect x="1128" y="341" rx="0" ry="0" width="207" height="207" />
            <Rect x="1191" y="585" rx="0" ry="0" width="121" height="12" />
            <Rect x="1196" y="564" rx="0" ry="0" width="115" height="12" />
            <Rect x="1209" y="607" rx="0" ry="0" width="83" height="12" />
            <Rect x="1119" y="577" rx="9" ry="9" width="27" height="26" />
        </ContentLoader>
    );

    if (!inventoryContent.isLoaded) {
        return (
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <MyLoader />
            </View>
        )
    }

    const inventory = getInventoryByType(TYPE_DEVICES, authState.user.location?.locationId);
    const items     = inventory.items;

    const updateFilter = (filter) => {
        if (maintenanceFilter.includes(filter)) {
            const newFilterState = maintenanceFilter.filter(level => level !== filter);
            setMaintenanceFilter(newFilterState)
        } else {
            setMaintenanceFilter(maintenanceFilter.concat(filter));
        }
    }

    const retrieveImagePath = (item) => {
        const privateDeviceImages = inventoryContent.inventoryImages[item.inventoryItemId];
        const publicDeviceImages  = inventoryContent.productImages[item.productId];
        const deviceImages        = [].concat(privateDeviceImages, publicDeviceImages);
        const imagePath           =
            deviceImages === undefined ? undefined :
                deviceImages[0]?.thumbnail ? deviceImages[0].thumbnail :
                    deviceImages[0]?.name ? deviceImages[0].path + '/' + deviceImages[0].name : undefined;

        return imagePath;
    }

    const renderItemAsList = (item, index) => {
        const serviceDates = item.serviceDates.filter(service => !service.resolved)
        const imagePath    = retrieveImagePath(item);

        return (
            <TouchableOpacity
                style={styles.listItemContainer}
                onPress={() => {
                    navigation.navigate('MyDevice', {inventoryItemId: item.inventoryItemId});
                }}
            >
                <View style={[
                    Styles.Layouts.row,
                    // index % 2 === 0 ? Styles.Lists.listItemEven : Styles.Lists.listItemOdd,
                ]}>
                    <View style={{
                        height: 'auto',
                        width: 10,
                        marginHorizontal: 10,
                        backgroundColor: colors.getMaintenanceWarningColor(item.warningLevel),
                    }}/>
                    {
                        (imagePath === undefined ?
                                <Image
                                    style={styles.listItemImage}
                                    source={require('../assets/images/placeholder.jpg')}
                                    resizeMode={'contain'}
                                />
                                :
                                <AuthImage
                                    style={styles.listItemImage}
                                    source={'/file' + imagePath}
                                    loadingIndicatorSource={require('../assets/images/placeholder.jpg')}
                                    resizeMode={'contain'}
                                />
                        )
                    }
                    <View style={styles.item}>
                        <Paragraph style={styles.title}>{`${item.name}`}</Paragraph>
                        <Paragraph style={styles.additional}>{`${item.additional}`}</Paragraph>
                        <View style={{flexDirection: 'row', alignSelf: 'stretch' }} >
                            <Paragraph style={styles.additional}><Trans id={'inventoryId'} />: </Paragraph><Paragraph style={styles.additional}>{`${item.inventoryNumber}`}</Paragraph>
                        </View>
                        <Paragraph
                            style={[
                                styles.subtitle,
                                {
                                    color: colors.getMaintenanceWarningColor(item.warningLevel)
                                }
                            ]}
                        >{l18nDate(serviceDates[0]?.date)}</Paragraph>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }

    const renderItemAsGrid = (item, index) => {
        const serviceDates = item.serviceDates.filter(service => !service.resolved)
        const imagePath    = retrieveImagePath(item);

        return (
            <TouchableOpacity
                style={[
                    styles.gridItemContainer,
                    {
                        flex: 1 / 4,
                    }
                ]}
                onPress={() => {
                    navigation.navigate('MyDevice', {inventoryItemId: item.inventoryItemId});
                }}
            >
                {
                    (imagePath === undefined ?
                            <Image
                                style={styles.gridItemImage}
                                source={require('../assets/images/placeholder.jpg')}
                            />
                            :
                            <AuthImage
                                style={styles.gridItemImage}
                                source={'/file' + imagePath}
                                loadingIndicatorSource={require('../assets/images/placeholder.jpg')}
                                resizeMode={'contain'}
                            />
                    )
                }
                <View style={styles.gridItemDescriptionContainer} >
                    <View style={[baseStyle.filterElementColorIndicator, {
                        backgroundColor: colors.getMaintenanceWarningColor(item.warningLevel),
                        alignSelf: 'center',
                    }]} />
                    <View style={{
                        flexGrow: 1,
                        // alignItems: 'center',
                        paddingLeft: 5,
                    }}>
                        <Paragraph
                            style={styles.gridTitle}
                            numberOfLines={1}
                            ellipsizeMode={'middle'}
                        >{`${item.name}`}</Paragraph>
                        <Paragraph
                            style={styles.gridTitle}
                            numberOfLines={1}
                            ellipsizeMode={'tail'}
                        >{`${item.additional}`}</Paragraph>
                        <Paragraph
                            style={[
                                styles.subtitle,
                                {
                                    color: colors.getMaintenanceWarningColor(item.warningLevel)
                                }
                            ]}
                        >{l18nDate(serviceDates[0]?.date)}</Paragraph>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }

    const renderItem = ({ item, index }) =>
        displayMode === 0 ? renderItemAsGrid(item, index) : renderItemAsList(item, index);

    return (
        <View style={styles.scene}>
            <View style={styles.listContainer}>
                <View style={styles.filterBox}>
                    <View
                        style={[
                            baseStyle.filterBoxTitle,
                            styles.filterBoxTitleDueDate,
                        ]}
                    >
                        <Paragraph><Trans id={'dueDate'} /></Paragraph>
                    </View>
                    <View style={styles.filterElementContainer}>
                        <DefaultCheckBox
                            containerStyle={styles.filterElementCheckbox}
                            title={t`inspectionOverdue`}
                            checked={maintenanceFilter.includes(1)}
                            onPress={() => updateFilter(1)}
                        />
                        <View
                            style={[
                                baseStyle.filterElementColorIndicator,
                                styles.filterElementColorIndicatorOverDue,
                            ]}
                        />
                    </View>
                    <View style={styles.filterElementContainer}>
                        <DefaultCheckBox
                            containerStyle={styles.filterElementCheckbox}
                            title={t`inspectionDueSoon`}
                            checked={maintenanceFilter.includes(2)}
                            onPress={() => updateFilter(2)}
                        />
                        <View
                            style={[
                                baseStyle.filterElementColorIndicator,
                                styles.filterElementColorIndicatorWarning,
                            ]}
                        />
                    </View>
                    <View style={styles.filterElementContainer}>
                        <DefaultCheckBox
                            containerStyle={styles.filterElementCheckbox}
                            title={t`inspectionNotDue`}
                            checked={maintenanceFilter.includes(3)}
                            onPress={() => updateFilter(3)}
                        />
                        <View
                            style={[
                                baseStyle.filterElementColorIndicator,
                                styles.filterElementColorIndicatorNothingAmissColor,
                            ]}
                        />
                    </View>
                    <View
                        style={[
                            baseStyle.filterBoxTitle,
                            styles.filterBoxTitleDisplayMode,
                        ]}
                    >
                        <Paragraph><Trans id={'displayMode'} /></Paragraph>
                    </View>
                    <View style={styles.filterElementContainerDisplayMode}>
                        <ButtonGroup
                            onPress={setDisplayMode}
                            selectedIndex={displayMode}
                            disabledTextStyle={{color: '#000'}}
                            innerBorderStyle={{width: 1}}
                            selectedButtonStyle={{backgroundColor: 'transparent'}}
                            buttons={[
                                {
                                    element: () => <Icon
                                        name={'th'}
                                        type={'font-awesome'}
                                        color={displayMode === 0 ? colors.colorComplement0 : '#555'}
                                        underlayColor={'#ccc'}
                                        size={30}
                                    />
                                },
                                {
                                    element: () =><Icon
                                        name={'bars'}
                                        type={'font-awesome'}
                                        color={displayMode === 1 ? colors.colorComplement0 : '#555'}
                                        underlayColor={'#ccc'}
                                        size={30}
                                    />
                                }
                            ]}
                            containerStyle={styles.filterElementCheckbox}
                        />
                    </View>
                </View>
                <View style={{flex: 1}} />
            </View>
            <View style={[styles.articleView]}>
                {items === undefined || items.length === 0 ?
                    <View style={[
                        styles.scene,
                        styles.empty
                    ]}>
                        <View>
                            <Paragraph style={styles.title}><Trans id={'noDevicesAvailable'}/></Paragraph>
                        </View>
                    </View>
                    :
                    /*https://github.com/gitim/react-native-sortable-list/issues/88*/
                    <FlatList
                        keyExtractor={(item, index) => `${index}`}
                        data={
                            items.filter(item => maintenanceFilter.includes(item.warningLevel))
                        }
                        renderItem={renderItem}
                        numColumns={displayMode === 0 ? 4 : 1}
                        key={displayMode === 0 ? 4 : 1}
                        showsHorizontalScrollIndicator={true}
                    />
                }
            </View>
        </View>
    );
};

const baseStyle = StyleSheet.create({
    filterBoxTitle: {
        paddingLeft: 15,
        paddingVertical: 5,
        backgroundColor: '#ccc',
        borderColor: '#ccc',
    },
    filterElementColorIndicator: {
        height: 20,
        width: 20,
        borderRadius: 10,
        borderColor: '#ccc',
    },
});

const styles = StyleSheet.create({
    scene: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginLeft: 15,
        marginTop: 18,
    },
    empty: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    articleView: {
        flex: 1,
        marginLeft: 7,
        paddingLeft: 7,
    },

    sectionHeader: {
        paddingTop: 2,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 2,
        fontSize: 25,
        fontWeight: 'bold',
        color: colors.colorText2,
    },

    item: {
        marginLeft: 20,
        marginBottom: 10
    },
    title: {

    },
    gridTitle: {
        width: 190,
    },
    additional: {
        color: colors.colorText0,
    },
    subtitle: {
        color: colors.colorText0,
    },
    listContainer: {
        width: 250,
    },
    filterBox: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: '#ccc',
        width: 250,
        backgroundColor: '#fff',
        marginRight: 10,
    },
    filterBoxTitleDueDate: {
        ...baseStyle.filterBoxTitle,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    filterElementContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 10,
        borderTopWidth: 1,
        borderColor: '#ccc',
    },
    filterBoxTitleDisplayMode: {
        ...baseStyle.filterBoxTitle,
        borderTopWidth: 1,
        marginRight: 0.5,
    },
    filterElementContainerDisplayMode: {
        height: 45,
    },
    filterElementCheckbox: {
        flex: 1,
        backgroundColor: '#fff',
        borderWidth: 0,
    },
    filterElementButton: {
        marginHorizontal: 30,
        borderRadius: 10,
    },
    filterElementColorIndicatorOverDue: {
        ...baseStyle.filterElementColorIndicator,
        backgroundColor: colors.maintenanceOverDueColor,
    },
    filterElementColorIndicatorWarning: {
        ...baseStyle.filterElementColorIndicator,
        backgroundColor: colors.maintenanceWarningColor,
    },
    filterElementColorIndicatorNothingAmissColor: {
        ...baseStyle.filterElementColorIndicator,
        backgroundColor: colors.maintenanceNothingAmissColor,
    },
    listItemContainer: {
        flex: 1,
        paddingVertical: 10,
        marginBottom: 15,
        alignItems: 'flex-start',
        backgroundColor: '#ddd',
    },
    listItemImage: {
        width: 115,
        height: 115,
        borderWidth: 1,
        borderColor: '#ddd',
    },
    gridItemImage: {
        width: 200,
        height: 100,
        borderWidth: 1,
        borderColor: '#ddd',
    },
    gridItemContainer: {
        marginHorizontal: 7,
        paddingTop: 15,
        marginBottom: 15,
        alignItems: 'center',
        backgroundColor: '#ddd',
    },
    gridItemDescriptionContainer: {
        flexDirection: 'row',
        marginTop: 3,
        marginHorizontal: 10,
    },
});

export default MyDevicesScreen;