/**
 * reference: https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
 */
import React, { useState } from 'react';

const CartContext = React.createContext([{}, () => {}]);

const CartProvider = (props) => {
    const [state, setState] = useState({
        isLoading: false,
        isLoaded: false,
        cartId: null,
        locationId: null,
        articles: [],
    });

    return (
        <CartContext.Provider value={[state, setState]}>
            {props.children}
        </CartContext.Provider>
    );
};

export { CartContext, CartProvider };