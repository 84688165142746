/**
 * reference: https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
 */
import React, {
    useState
} from 'react';

const StorageContext = React.createContext([{}, () => {}]);

const StorageProvider = (props) => {
    const [state, setState] = useState({
        isLoading: false,
        isLoaded: false,
        storage: [],
    });

    return (
        <StorageContext.Provider value={[state, setState]}>
            {props.children}
        </StorageContext.Provider>
    );
};

export { StorageContext, StorageProvider };