import * as colors from './colors';

export const container = {
    borderWidth: 1,
    borderColor: colors.grayNormal,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    flexGrow: 0,
};

export const selected = {
    backgroundColor: colors.grayLight,
};

export const listItemEven = {
    backgroundColor: '#ddbb88',
};

export const listItemOdd = {
};

export  const imageList = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
};
