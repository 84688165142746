import React, {
    useContext,
    useEffect
}                                  from 'react';
import { MenuContext }             from '../context/MenuContext';
import {
    Animated,
    Easing,
    View
}                                  from 'react-native';
import Comb                        from '../components/Comb';
import * as SVG                    from '../assets/images/svg/svg';
import { useNavigation }           from '@react-navigation/native';
import Requester                   from '../components/Requester';
import AsyncStorage                from '../interfaces/AsyncStorage';
import { getProfileImageFallback } from './LoginScreen';
import Semaphore                   from 'semaphore-async-await';
import { AuthContext }             from '../components/AuthContext';
import useAuth                     from '../components/useAuth';
import moment                      from 'moment';
import useCart                     from '../components/useCart';
import useInventory                from '../components/useInventory';
import useStorage                  from '../components/useStorage';
import useItemList                 from '../components/useItemList';
import useMenu                     from '../context/useMenu';

const LoadingScreen = ({ navigation, route, props}) => {
    const {
        list: listCart,
    } = useCart();
    const {
        list: listInventory,
    } = useInventory();
    const {
        list: listStorage,
    } = useStorage();
    const {
        list: listItemLists,
    } = useItemList();

    const { load } = useMenu();

    const {
        updateUser,
        setUserReady,
    } = useAuth();

    const [authState]   = useContext(AuthContext);
    const [menuContent] = useContext(MenuContext);

    const lock = new Semaphore(1);

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key].userId === value.userId);
    }

    const addUserToQuickList = async (newUser) => {
        await lock.acquire();
        let _userQuickList;

        try {
            const result = await AsyncStorage.getJSONItem('userQuickList');

            if (result === null) {
                _userQuickList = [];
            } else if (result.length >= 0) {
                _userQuickList = result;
            } else {
                _userQuickList = [];
            }

            if (_userQuickList !== null) {
                const position = getKeyByValue(_userQuickList, newUser);

                if (position === undefined) {
                    _userQuickList.unshift(newUser);
                } else {
                    _userQuickList.splice(position, 1);
                    _userQuickList.unshift(newUser);
                }
            } else {
                _userQuickList.unshift(newUser);
            }
        } catch (error) {
            // Error retrieving data
        } finally {
            await AsyncStorage.setItem('userQuickList', JSON.stringify(_userQuickList));
            lock.release();
        }
    };

    const createProfileImageFallback = (user) => {
        switch (user.email.length % 4) {
            case 0:
                return 'Doc1.jpg';

            case 1:
                return 'Doc2.jpg';

            case 2:
                return 'NurseM1.jpg';

            case 3:
                return 'Sani1.jpg';

            default:
                return 'Doc1.jpg';
        }
    }

    const setInitialLocation = (_user, _locations) => {
        if (typeof _user.location !== 'object') {
            return _locations.length === 1 ? _locations[0] : undefined;
        } else {
            return _locations.find(_location => _location.locationId === _user.location.locationId);
        }
    }

    const loadFacilityData = async (_user) => {
        let networkError = false;
        const response   = await Requester.get(
            '/facility/' + _user.facilityId
        ).catch((e) => {
            networkError = true;
        });

        if (networkError) {
            return false;
        }

        const _data = await response.json();
        return _data.locations;
    }

    const fetchUserData = async () => {
        if (route.params?.token) {
            Requester.setToken(route.params?.token);

            let networkError = false;
            const response = await Requester.get(
                '/user/token',
            ).catch((e) => {
                networkError = true;
            });

            if (networkError) {
                return false;
            }

            if (response.ok) {
                const dataSource = await response.json();
                Requester.debug(response, dataSource);
                Requester.setToken(dataSource.token);
            } else {
                navigation.navigate('LoginScreen');
                return false;
            }
        }

        let networkError = false;
        const response = await Requester.get(
            '/user/me',
        ).catch((e) => {
            networkError = true;
        });

        if (networkError) {
            return false;
        }

        if (response.ok) {
            const dataSource = await response.json();
            Requester.debug(response, dataSource);

            let _user = await AsyncStorage.getJSONItem('userObject_' + dataSource.role + '_' + dataSource.userId);

            if (_user === null) {
                _user = dataSource;
            } else {
                _user.facilityId = dataSource.facilityId;
            }

            const _locations = await loadFacilityData(dataSource);
            _user.location = setInitialLocation(_user, _locations);

            if (route.params?.addToQuickLogin) {
                _user.profileImageFallback = createProfileImageFallback(_user);

                if (!('profileImage' in _user)) {
                    _user.profileImage = null;
                }

                await addUserToQuickList(_user);
            }

            const sideMenuPosition = await AsyncStorage.getItem('sideMenuPosition');

            if (sideMenuPosition === 'right') {
                _user.sideMenuPosition = 'right';
            } else {
                _user.sideMenuPosition = 'left';
            }

            _user.profileImageFallback = getProfileImageFallback(_user);
            updateUser(_user);
            await AsyncStorage.setItem('userObject_' + dataSource.role + '_' + dataSource.userId, JSON.stringify(_user));
        }
    }

    const fetchAdditionalData = async () => {
        const requestStartTime = moment();

        Promise.all(
            [
                (new Promise((resolve, reject) => {
                    load().then((result) => resolve(result));
                })),
                (new Promise((resolve, reject) => {
                    listCart(true).then((result) => resolve(result));
                })),
                (new Promise((resolve, reject) => {
                    listInventory(true).then((result) => resolve(result));
                })),
                (new Promise((resolve, reject) => {
                    listStorage(true).then((result) => resolve(result));
                })),
                (new Promise((resolve, reject) => {
                    listItemLists(true).then((result) => resolve(result));
                }))
            ]
        ).then((values) => {
            const requestReturnTime = moment();
            const runtime           = moment.duration(requestReturnTime.diff(moment(requestStartTime)));
            console.log('INIT CONTEXTS took: ', runtime);
        });
    }

    useEffect(() => {
            fetchUserData();
        },
        []
    );

    useEffect(() => {
            if (authState.user) {
                fetchAdditionalData();
                setUserReady();
            }
        },
        [authState.user]
    );

    useEffect(() => {
            if (!menuContent.isLoaded) {
                showLoadingAnimation();
            } else {
                rotation.stopAnimation();
            }

            return function cleanup() {
                rotation.stopAnimation();
            }
        },
        [menuContent.isLoaded]
    );

    let rotation = new Animated.Value(0);

    const rotate = rotation.interpolate({
        inputRange: [0, 360],
        outputRange: ['0deg', '360deg'],
    });

    const showLoadingAnimation = () => {
        rotation.setValue(0);
        Animated.loop(
            Animated.timing(rotation, {
                toValue: 360,
                duration: 2000,
                easing: Easing.inOut(Easing.cubic),
                useNativeDriver: true,
            }), {
            }
        ).start();
    };

    const MyLoader = () => (
        <Animated.View
            style={[{
                transform: [
                    { rotateY: rotate },
                    { perspective: 1000 } // without this line this Animation will not render on Android while working fine on iOS
                ],
            }]}
        >
            <Comb
                atomic
                item={{
                    icon: <SVG.IconMEIT/>,
                    scale: "80%",
                    backgroundColor: '#FFF',
                    opacity: 1,
                }}
            />
        </Animated.View>
    );

    if (!menuContent.isLoaded) {
        return (
            <View style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <MyLoader />
            </View>
        )
    }

    return null;
}

export default LoadingScreen;