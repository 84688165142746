import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
    Text,
    Mask,
    Rect,
    TSpan,
} from 'react-native-svg';

const IconLocation = (propsParam) => {
    const { color, text, ...props } = propsParam;
    const mask = Math.random();

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs>
                <Mask id={"pin" + mask}>
                    <Rect width={"100%"} height={"100%"} fill={"white"}/>
                    <Path fill={"black"} d="M149.3 133.28a57.76 57.76 0 100-115.52 57.76 57.76 0 000 115.52"/>
                </Mask>
            </Defs>
            <G fill={color} id="location">
                <Path d="M148.72 259c27.8 0 50.35-2.18 50.35-4.87s-22.54-4.87-50.35-4.87c-27.8 0-50.34 2.18-50.34 4.87s22.54 4.87 50.34 4.87"/>
                <Path
                    mask={"url(#pin" + mask + ")"}
                    d="M149.03 252.15s43.15-59.61 54.96-86.43c24.75-56.22 22.14-82.35 22.14-90.2S219.61 0 149.31 0 72.22 66.37 72.22 75.52s-2.9 33.98 21.85 90.2c11.8 26.82 54.96 86.43 54.96 86.43"/>
                <Text
                      fontFamily="'openSans'"
                      fontSize={"90px"}
                      textAnchor={'middle'}
                >
                    <TSpan x={150} y={110}>{text}</TSpan>
                </Text>
            </G>
        </Svg>
    );
}

IconLocation.defaultProps = {
    color: '#FFF',
    text: '',
};

export default IconLocation;