import React, {
    useMemo,
} from 'react';
import {
    View,
    StyleSheet,
    Image,
} from 'react-native';
import { Paragraph } from './StyledText';
import {
    Icon,
} from 'react-native-elements';
import * as Styles from '../styles/index';
import useCart from './useCart';
import TabbableInput from '../ui/TabbableInput';
import {
    t,
} from '@lingui/macro';
import * as colors from '../styles/colors';
import Toast from '../interfaces/Toast';
import AuthImage from './AuthImage';

const CartEntry = (propsParam) => {
    const { item } = propsParam;
    const {
        removeFromCart,
        updateProductInCart,
    } = useCart();

    const updateProductOnCartCallback = (_item, _quantity) => {
        updateProductInCart(_item, _quantity);
    };

    const RenderSeparator = () => {
        return (
            <View style={{
                height: 45,
                minHeight: '80%',
                width: 2,
                backgroundColor: '#fff',
                marginVertical: 0,
                marginHorizontal: 0,
            }}/>
        )
    };

    const tabbableInput = useMemo(() => {
        return (
            <TabbableInput
                value={item.quantity}
                item={item}
                min={1}
                onChangeText={updateProductOnCartCallback}
                inputContainerStyle={{width: 75, borderBottomWidth: 0}}
            />
        )
    }, [item]);

    const deviceImages = item.images.filter(image => !image.isPrivate);

    const imagePath =
        deviceImages[0]?.thumbnail ? deviceImages[0].thumbnail :
            deviceImages[0]?.name ? deviceImages[0].path + '/' + deviceImages[0].name : undefined;

    return (
        <View
            style={[
                Styles.Layouts.row,
                {
                    alignItems: 'center',
                    backgroundColor: colors.grayLight,
                    marginVertical: 5,
                }
            ]}
        >
            <View style={styles.action}>
                <Icon
                    name={'minus-circle'}
                    type={'font-awesome'}
                    color={colors.maintenanceOverDueColor}
                    underlayColor={'#ccc'}
                    size={30}
                    onPress={() => {
                        let response = removeFromCart(item);

                        if (response.status === 400) {
                            Toast.showError(t`error`);
                        }
                    }}
                />
            </View>
            <RenderSeparator/>
            <View style={{
                flex: 3,
                flexDirection: 'row',
                paddingHorizontal: 10,
                alignItems: 'center',
            }}>
                {
                    (imagePath === undefined ?
                            <Image
                                style={styles.gridItemImage}
                                source={require('../assets/images/placeholder.jpg')}
                            />
                            :
                            <AuthImage
                                style={styles.gridItemImage}
                                source={'/file' + imagePath}
                                loadingIndicatorSource={require('../assets/images/placeholder.jpg')}
                                resizeMode={'contain'}
                            />
                    )
                }
                <View style={styles.textContainer}>
                    {/*<View style={{   justifyContent: 'center', //Centered vertically*/}
                    {/*    // alignItems: 'center', // Centered horizontally*/}
                    {/*    flex:1*/}
                    {/*}}>*/}
                        <Paragraph style={styles.name}>{item.name}</Paragraph>
                    {/*</View>*/}
                    {/*<View style={{   justifyContent: 'space-around', //Centered vertically*/}
                    {/*    // alignItems: 'center', // Centered horizontally*/}
                    {/*    flex:1*/}
                    {/*}}>*/}
                        <Paragraph style={styles.manufacturer}>{item.manufacturer.name}</Paragraph>
                    {/*</View>*/}
                </View>
            </View>
            <RenderSeparator/>
            {/*<Paragraph style={styles.productionUnit}>{item.productionUnit ? item.productionUnit : <Trans id={'notAvailableShort'} />}</Paragraph>*/}
            {/*<RenderSeparator/>*/}
            {/*<Paragraph style={styles.factor}>{item.factor ? item.factor : <Trans id={'notAvailableShort'} />}</Paragraph>*/}
            {/*<RenderSeparator/>*/}
            <View style={styles.quantity}>
                {tabbableInput}
            </View>
            <RenderSeparator/>
        </View>
    );
};

const styles = StyleSheet.create({
    textContainer: {
        flexDirection: 'column',
        flex: 1,
    },
    gridItemImage: {
        width: 75,
        height: 50,
        paddingHorizontal: 10,
        backgroundColor: '#fff',
    },
    manufacturer: {
        flex: 3,
        textAlign: 'left',
        color: '#333',
        paddingHorizontal: 10,
        textAlignVertical: 'top',
    },
    name: {
        flex: 3,
        textAlign: 'left',
        marginBottom: 0,
        paddingHorizontal: 10,
        textAlignVertical: 'bottom',
    },
    quantity: {
        width: 200,
        textAlign: 'center',
        paddingLeft: 6,
        paddingHorizontal: 10,
    },
    productionUnit: {
        flex: 1,
        width: 50,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    factor: {
        flexGrow: 0,
        width: 70,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    action: {
        flexGrow: 0,
        width: 48,
        alignContent: 'center',
        paddingHorizontal: 10,
    },
});

export default CartEntry;