import React, {
    useEffect,
    useState,
} from 'react';
import * as colors from '../styles/colors';
import {
    CheckBox,
} from 'react-native-elements';
import * as UIStyles from './UIStyles'

const DefaultCheckBox = ({
    children,
    onPress, editable,
    title,
    ...props
}) => {
    const [isChecked, setChecked] = useState(props.checked);

    let containerStyle = props.containerStyle;
    let textStyle      = UIStyles.checkBoxText;
    let uncheckedColor = colors.colorComplement0;
    let checkedColor   = colors.colorComplement0;

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    const _onPress = () => {
        if (editable === false) {
            return;
        }

        if (typeof onPress === 'function') {
            onPress(!isChecked);
        }

        setChecked(!isChecked);
    }

    if (editable === false) {
        containerStyle = UIStyles.checkBoxContainerDisabled;
        textStyle      = UIStyles.checkBoxTextDisabled;
        uncheckedColor = colors.colorText2;
        checkedColor   = colors.colorText2;
    }

    return (
        <CheckBox
            checked={isChecked}
            onPress={_onPress}
            containerStyle={containerStyle}
            uncheckedColor={uncheckedColor}
            checkedColor={checkedColor}
            textStyle={[
                textStyle,
                {
                    fontWeight: 'normal',
                    fontFamily: 'openSans',
                }
            ]}
            title={title}
        />
    )
}

DefaultCheckBox.defaultProps = {
    checked: false,
    containerStyle: UIStyles.checkBoxContainer,
};

export default DefaultCheckBox;