import React from 'react';
import Svg, {
    G,
    Path,
    Defs,
    LinearGradient,
    Stop,
} from 'react-native-svg';

const IconStorageBar = (propsParam) => {
    const { setpoint, actualValue, lowerLimitValue, upperLimitValue, lowerLimitColor, upperLimitColor, ...props } = propsParam;
    const opacity    = 0.15;
    const fraction   = actualValue / setpoint;
    const percentage = fraction * 100;
    const colorStop  = actualValue % 10 / 10;
    let colors       = {};
    let color;

    for (let i = 0; i <= 90; i+=10) {
        colors[i] = {
            fillOpacity: 1,
            fill: '#fa0',
        };

        if (i >= percentage) {
            colors[i].fillOpacity = opacity;
        }

        if (i <= lowerLimitValue) {
            colors[i].fill = lowerLimitColor;
        }

        if (i >= upperLimitValue) {
            colors[i].fill = upperLimitColor;
        }

        if (Math.floor(percentage / 10) * 10 == i) {
            color          = colors[i].fill;
            colors[i].fill = 'url(#grad)';
        }
    }

    return (
        <Svg viewBox={"0 0 100 5"} width={"100%"} height={"100%"}>
            <Defs>
                <LinearGradient id="grad" x1="0" y1="0" x2="1" y2="0">
                    <Stop offset="0"         stopColor={color} stopOpacity="1" />
                    <Stop offset={colorStop} stopColor={color} stopOpacity="1" />
                    <Stop offset={colorStop} stopColor={color} stopOpacity={opacity} />
                    <Stop offset="1"         stopColor={color} stopOpacity={opacity} />
                </LinearGradient>
            </Defs>
            <G fillOpacity="1" stroke="#000c00" strokeWidth=".25">
                <Path id="progress10"  d="M0  0h10v5H0z"  fill={colors['0'].fill}  fillOpacity={colors['0'].fillOpacity}  />
                <Path id="progress20"  d="M10 0h10v5H10z" fill={colors['10'].fill} fillOpacity={colors['10'].fillOpacity} />
                <Path id="progress30"  d="M20 0h10v5H20z" fill={colors['20'].fill} fillOpacity={colors['20'].fillOpacity} />
                <Path id="progress40"  d="M30 0h10v5H30z" fill={colors['30'].fill} fillOpacity={colors['30'].fillOpacity} />
                <Path id="progress50"  d="M40 0h10v5H40z" fill={colors['40'].fill} fillOpacity={colors['40'].fillOpacity} />
                <Path id="progress60"  d="M50 0h10v5H50z" fill={colors['50'].fill} fillOpacity={colors['50'].fillOpacity} />
                <Path id="progress70"  d="M60 0h10v5H60z" fill={colors['60'].fill} fillOpacity={colors['60'].fillOpacity} />
                <Path id="progress80"  d="M70 0h10v5H70z" fill={colors['70'].fill} fillOpacity={colors['70'].fillOpacity} />
                <Path id="progress90"  d="M80 0h10v5H80z" fill={colors['80'].fill} fillOpacity={colors['80'].fillOpacity} />
                <Path id="progress100" d="M90 0h10v5H90z" fill={colors['90'].fill} fillOpacity={colors['90'].fillOpacity} />
            </G>
        </Svg>
    );
}

IconStorageBar.defaultProps = {
    setpoint: 0,
    actualValue: 0,
    lowerLimitValue: 10,
    upperLimitValue: 40,
    lowerLimitColor: '#e00',
    upperLimitColor: '#0e0',
};

export default IconStorageBar;