import * as Random from 'expo-random';
import { v4 as uuidv4 } from 'uuid';

const Uuid = () => {
    async function getUuid() {
        const seed = await Random.getRandomBytesAsync(16);
        const uuid = uuidv4({random: seed});
        return uuid;
    }

    return {
        getUuid,
    }
}

export default Uuid;