import React, {
    useContext,
} from 'react';
import {
    View,
    StyleSheet,
    FlatList,
    ScrollView,
} from 'react-native';
import * as Styles from '../styles/index';
import { Paragraph } from '../components/StyledText';
import { StorageContext } from '../components/StorageContext';
import {
    Icon,
} from 'react-native-elements';
import useStorage from '../components/useStorage';
import Requester from '../components/Requester';
import Layout from '../constants/Layout';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import TabbableInput from '../ui/TabbableInput';
import IconStorageBar from '../assets/images/svg/IconStorageBar';
import useCart from '../components/useCart';

const StorageScreen = (props) => {
    const { updateStorageItem } = useStorage();
    const { addToCart } = useCart();
    const [storageContent] = useContext(StorageContext);

    function restock(item) {
        addToCart(item, item.setpoint - item.actualValue);
    }

    const updateStorageQuantityCallback = (item, quantity) => {
        updateStorageItem(activeStorage, item, quantity);
    }

    if (!storageContent.isLoaded) {
        return null;
    }

    let activeStorage = storageContent.storage.findIndex((element) => element !== undefined && element !== null)

    const renderItem = ({ item }) => (
        <View style={[
            Styles.Layouts.row,
            // index % 2 === 0 ? Styles.Lists.listItemEven : Styles.Lists.listItemOdd,
            {
                alignItems: 'center',
            }
        ]}>
            <Paragraph style={{
                width: 120,
            }}>{item.catalogNumber}</Paragraph>
            <View style={{flex: 3, alignContent: 'flex-start'}}>
                <Paragraph style={styles.name}>{item.name}</Paragraph>
                <Paragraph style={styles.manufator}>{item.description}</Paragraph>
            </View>
            <Paragraph style={[{width: 85, textAlign: 'center' }]}>{item.setpoint}</Paragraph>
            <View
                style={{width: 200}}
            >
                <TabbableInput
                    value={item.quantity}
                    item={item}
                    onChangeText={updateStorageQuantityCallback}
                    inputStyle={styles.input}
                    inputContainerStyle={{width: 75, borderBottomWidth: 0}}
                />
                <View style={{
                    width: 185,
                    height: 10
                }}>
                    <IconStorageBar
                        setpoint={item.setpoint}
                        actualValue={item.quantity}
                        lowerLimitValue={item.lowerLimitValue}
                        upperLimitValue={item.upperLimitValue}
                    />
                </View>
            </View>
            <View style={styles.action}>
                <Icon
                    name={'shopping-cart'}
                    type={'font-awesome'}
                    color={'#555'}
                    underlayColor={'#ccc'}
                    size={30}
                    style={[styles.icon]}
                    onPress={() => {
                        restock(item);
                    }}
                />
            </View>
        </View>
    );

    return (
        <View style={styles.container}>
            <ScrollView>
                <FlatList
                    keyExtractor={(item, itemIndex) => `${itemIndex}`}
                    data={storageContent.storage[activeStorage].items}
                    persistentScrollbar={true}
                    renderItem={renderItem}
                    ListHeaderComponent={(
                        <View style={Styles.Layouts.row}>
                            <Paragraph style={[styles.tableHeader, {width: 120, textAlign: 'center', alignSelf: 'stretch'}]}><Trans id={'articleNumber'} /></Paragraph>
                            <Paragraph style={[styles.tableHeader, {flex: 3}]}><Trans id={'description'} /></Paragraph>
                            <Paragraph style={[styles.tableHeader, {width: 85, textAlign: 'center', alignSelf: 'stretch'}]}><Trans id={'setpoint'} /></Paragraph>
                            <Paragraph style={[styles.tableHeader, {width: 200, textAlign: 'center', alignSelf: 'stretch'}]}><Trans id={'actualValue'} /></Paragraph>
                            <Paragraph style={[styles.tableHeader, styles.action]}/>
                        </View>
                    )}
                    ItemSeparatorComponent={Layout.renderSeparator}
                />
            </ScrollView>
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    tableHeader: {
        fontWeight: 'bold'
    },
    input: {
    },
    textInput: {
        ...Styles.Layouts.input,
        width: 75,
    },
    location: {
        ...Styles.Layouts.row,
        alignItems: 'center',
    },
    locationPicker: {
        ...Styles.Layouts.input,
        width: 250,
        marginLeft: 5,
    },
    action: {
        flex: 0,
        width: 35,
        alignContent: 'center',
    },
});

export default StorageScreen;