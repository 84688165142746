import React from 'react';
import Svg, {
    G,
    Defs,
    Line,
} from 'react-native-svg';

const IconHamburger = (propsParam) => {
    const { color, ...props } = propsParam;

    return (
        <Svg viewBox={"0 0 300 259"} width={"100%"} height={"100%"}>
            <Defs/>
            <G id="Hamburger" fill="none" stroke={color}>
                <Line strokeWidth="34.0158" x1="19.443" y1="43.469" x2="283.443" y2="43.469"/>
                <Line strokeWidth="34.0158" x1="19.443" y1="128.742" x2="283.443" y2="128.742"/>
                <Line strokeWidth="34.0158" x1="19.443" y1="214" x2="283.443" y2="214"/>
            </G>
        </Svg>
    );
}

IconHamburger.defaultProps = {
    color: '#FFF',
};

export default IconHamburger;