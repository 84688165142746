import {
    useContext,
} from 'react';
import { CartContext } from './CartContext';
import { AuthContext } from './AuthContext';
import Requester from './Requester';
import Toast from '../interfaces/Toast';
import { t } from '@lingui/macro';

const useCart = () => {
    const [authState]         = useContext(AuthContext);
    const [state, setState]   = useContext(CartContext);
    let doubleClickDelayTimer = null;

    const addToCart = async (newArticle, quantity) => {
        const _quantity           = parseInt(quantity)
        let currentArticlesInCart = state.articles;
        const existingArticle     = currentArticlesInCart.find(article => article.shopItemId === newArticle.shopItemId);

        if (!existingArticle) {
            newArticle.quantity = _quantity;
            let networkError    = false;
            const response      = await Requester.put(
                '/cart/' + authState.user.userId + '/' + newArticle.shopItemId,
                newArticle
            ).catch(async () => {
                networkError = true;
            });

            if (networkError) {
                return false;
            }

            const data = await response.json();
            Requester.debug(response, data);

            currentArticlesInCart.push(newArticle);
        } else {
            await _updateProductInCart(existingArticle, existingArticle.quantity + _quantity);
        }

        setState(currentState => ({
            ...currentState,
            articles: currentArticlesInCart,
        }));
    }

    const removeFromCart = async (articleToRemove) => {
        let networkError = false;
        const response   = await Requester.delete(
            '/cart/' + authState.user.userId + '/' + articleToRemove.shopItemId
        ).catch(async (e) => {
            networkError = true;
        });

        if (networkError) {
            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);

        let cart = [];

        Object.values(data).filter(product => {
            if (!product.hasOwnProperty('shopItemId')) {
                return false;
            }

            cart.push(product);
        });

        setState(currentState => ({
            ...currentState,
            articles: cart,
        }));
    }

    function updateProductInCart(productToUpdate, newQuantity) {
        clearTimeout(doubleClickDelayTimer);
        doubleClickDelayTimer = setTimeout(_updateProductInCart, 450, productToUpdate, newQuantity);
    }

    const _updateProductInCart = async (productToUpdate, newQuantity) => {
        if (state.isPatching) {
            return false;
        }

        await setState(currentState => ({
            ...currentState,
            isPatching: true,
        }));

        const quantity = Math.max(1, parseInt(newQuantity));

        if (isNaN(quantity)) {
            return false;
        }

        productToUpdate.quantity = quantity;

        let networkError = false;
//@TODO-API request PATCH
        const response   = await Requester.put(
            '/cart/' + authState.user.userId + '/' + productToUpdate.shopItemId,
            productToUpdate
        ).catch(async (e) => {
            networkError = true;
        });

        if (networkError) {
            await setState(currentState => ({
                ...currentState,
                isPatching: false,
            }));

            return false;
        }

        const data = await response.json();
        Requester.debug(response, data);

        /* As this function is called via setTimeout, we cannot return the response and have to generate the Toast here */
        if (response.status === 400) {
            Toast.showError(t`error`);
            return false;
        }

        let cart = [];

        Object.values(data).filter(product => {
            if (!product.hasOwnProperty('shopItemId')) {
                return false;
            }

            cart.push(product);
        });

        setState(currentState => ({
            ...currentState,
            isPatching: false,
            articles: cart,
        }));

        return response;
    }

    const updateCart = async () => {
        await setState(currentState => ({
            ...currentState,
            isLoading: true,
            isLoaded: false
        }));

        let networkError = false;
        const response   = await Requester.get(
            '/cart/' + authState.user.userId
        ).catch(async (e) => {
            networkError = true;
        });

        if (networkError) {
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        const dataSource = await response.json();
        Requester.debug(response, dataSource);

        if (response.ok === false) {
            await setState(currentState => ({
                ...currentState,
                isLoading: false,
                isLoaded: false
            }));

            return false;
        }

        let cart = [];

        Object.values(dataSource).filter(product => {
            if (!product.hasOwnProperty('shopItemId')) {
                return false;
            }

            cart.push(product);
        });

        setState(currentState => ({
            ...currentState,
            isLoading: false,
            isLoaded: true,
            cartId: dataSource.cartId,
            locationId: dataSource.locationId,
            articles: cart,
        }));

        return cart;
    }

    const reset = async () => {
        await setState(currentState => ({
            ...currentState,
            isLoaded: false,
            cartId: null,
            locationId: null,
            articles: [],
        }));
    }

    const list = async (forceReload = false) => {
        if (forceReload || (!state.isLoading && !state.isLoaded)) {
            return await updateCart();
        }

        return state.articles;
    }

    const placeOrder = async (locationId) => {
        let networkError = false;
        const response   = await Requester.post(
            '/cart/' + authState.user.userId + '/' + locationId + '/offer'
        ).catch(() => {
            networkError = true;
        });

        if (networkError) {
            return false;
        }

        const dataSource = await response.json();
        Requester.debug(response, dataSource);

        return response.status === 200;
    }

    return {
        isLoaded: state.isLoaded,
        addToCart,
        removeFromCart,
        updateProductInCart,
        updateCart,
        list,
        reset,
        placeOrder,
        articlesInCart: 0,
    }
};

export default useCart;