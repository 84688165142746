/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=13N0u0kpZsUg7CLl9v4tgnUwpiX */

/* Feel free to copy&paste color codes to your application */


/* As hex codes */

export const colorPrimary0    = '#2B499B';	/* Main Primary color */
export const colorPrimary1    = '#6A81C0';
export const colorPrimary2    = '#455FA7';
export const colorPrimary3    = '#183480';
export const colorPrimary4    = '#0B2366';

export const colorSecondary10 = '#2B499B';	/* Main Primary color */
export const colorSecondary11 = '#6A81C0';
export const colorSecondary12 = '#455FA7';
export const colorSecondary13 = '#183480';
export const colorSecondary14 = '#0B2366';

export const colorSecondary20 = '#2B499B';	/* Main Primary color */
export const colorSecondary21 = '#6A81C0';
export const colorSecondary22 = '#455FA7';
export const colorSecondary23 = '#183480';
export const colorSecondary24 = '#0B2366';

export const colorComplement0 = '#2B499B';	/* Main Complement color */
export const colorComplement1 = '#6A81C0';
export const colorComplement2 = '#455FA7';
export const colorComplement3 = '#183480';
export const colorComplement4 = '#0B2366';



/* As RGBa codes */

export const rgbaPrimary0     = 'rgba( 43, 73,155,1)';	/* Main Primary color */
export const rgbaPrimary1     = 'rgba(106,129,192,1)';
export const rgbaPrimary2     = 'rgba( 69, 95,167,1)';
export const rgbaPrimary3     = 'rgba( 24, 52,128,1)';
export const rgbaPrimary4     = 'rgba( 11, 35,102,1)';

export const rgbaSecondary10  = 'rgba( 43, 73,155,1)';	/* Main Primary color */
export const rgbaSecondary11  = 'rgba(106,129,192,1)';
export const rgbaSecondary12  = 'rgba( 69, 95,167,1)';
export const rgbaSecondary13  = 'rgba( 24, 52,128,1)';
export const rgbaSecondary14  = 'rgba( 11, 35,102,1)';

export const rgbaSecondary20  = 'rgba( 43, 73,155,1)';	/* Main Primary color */
export const rgbaSecondary21  = 'rgba(106,129,192,1)';
export const rgbaSecondary22  = 'rgba( 69, 95,167,1)';
export const rgbaSecondary23  = 'rgba( 24, 52,128,1)';
export const rgbaSecondary24  = 'rgba( 11, 35,102,1)';

export const rgbaComplement0  = 'rgba( 43, 73,155,1)';	/* Main Complement color */
export const rgbaComplement1  = 'rgba(106,129,192,1)';
export const rgbaComplement2  = 'rgba( 69, 95,167,1)';
export const rgbaComplement3  = 'rgba( 24, 52,128,1)';
export const rgbaComplement4  = 'rgba( 11, 35,102,1)';




/* Generated by Paletton.com © 2002-2014 */
/* http://paletton.com */
