import {
    View,
    StyleSheet,
    ScrollView
} from "react-native";
import React from "react";
import { Paragraph } from "./StyledText";

export function NewsArticle(props) {
    return (
        <View>
            <ScrollView style={styles.container}>
                <Paragraph style={styles.date}>{props.news.date}</Paragraph>
                <Paragraph style={styles.headline}>{props.news.headline}</Paragraph>
                <Paragraph style={styles.content}>{props.news.content}</Paragraph>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        // fontStyle: 'italic',
    //     border: '1px double black',
    },
    date: {
        fontStyle: 'italic',
    },
    headline: {
        fontWeight: 'bold',
    },
    content: {

    }
});