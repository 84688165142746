import React, {
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    View,
    StyleSheet,
    ActivityIndicator,
} from 'react-native';
import { ItemListContext } from '../components/ItemListContext';
import useItemList from '../components/useItemList';
import ItemList from '../components/ItemList';
import MenuList from '../ui/MenuList';
import Overlay from '../ui/Overlay';
import DefaultInput from '../ui/DefaultInput';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Paragraph } from '../components/StyledText';
import WebButton from '../ui/WebButton';
import ContentLoader, {
    Rect
} from '../ui/ContentLoader';
import { AuthContext } from '../components/AuthContext';

const ItemListScreen = (props) => {
    const {
        isLoaded,
        hasActiveRequest,
        updateItemLists,
        createItemList,
        deleteItemList,
        updateItemList,
    } = useItemList();

    const [authState]                               = useContext(AuthContext);
    const [itemListContent, updateItemListContent]  = useContext(ItemListContext);

    const [itemListId, setItemListId]               = useState(0);
    const [itemListIndex, setItemListIndex]         = useState(0);
    const [isOverlayVisible, setOverlayVisible]     = useState(false);
    const [newItemListName, setNewItemListName]     = useState('');
    const [isCreationBlocked, setCreationBlocked]   = useState(true);
    const availableItemListsInLocation              = itemListContent.lists.filter(list => list.location?.locationId === authState.user.location?.locationId);

    useEffect(() => {
            if (isLoaded && itemListId === 0) {
                setItemListId(itemListContent.lists[0]?.itemListId);
            }

            if (availableItemListsInLocation === false) {
                setItemListId(undefined);
            } else {
                if (!availableItemListsInLocation.some(list => list.itemListId === itemListId)) {
                    setItemListId(availableItemListsInLocation[0]?.itemListId);
                    setItemListIndex(0);
                }
            }
        },
        [isLoaded, itemListContent, authState.user.location?.locationId]
    );

    const MyLoader = () => (
        <ContentLoader>
            <Rect x="20" y="15" rx="0" ry="0" width="280" height="30" />
            <Rect x="20" y="60" rx="0" ry="0" width="280" height="30" />
            <Rect x="20" y="105" rx="0" ry="0" width="280" height="30" />

            <Rect x="320" y="15" rx="0" ry="0" width="75%" height="30" />
            <Rect x="320" y="60" rx="0" ry="0" width="75%" height="30" />
            <Rect x="320" y="105" rx="0" ry="0" width="75%" height="30" />
            <Rect x="320" y="150" rx="0" ry="0" width="75%" height="30" />
            <Rect x="320" y="195" rx="0" ry="0" width="75%" height="30" />
            <Rect x="320" y="240" rx="0" ry="0" width="75%" height="30" />
        </ContentLoader>
    );

    if (!isLoaded || itemListId === 0) {
        return (
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <MyLoader />
            </View>
        )
    }

    const menuListCallback = (item, index) => {
        setItemListId(item.itemListId);
        setItemListIndex(index);
    }

    const _createItemList = (_text) => {
        setNewItemListName(_text);

        if (_text.length) {
            setCreationBlocked(false);
        } else {
            setCreationBlocked(true);
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.listContainer}>
                <Overlay
                    isVisible={isOverlayVisible}
                    setVisibility={setOverlayVisible}
                    titleText={t`createItemList`}
                    width={"50%"}
                >
                    <View style={{
                        marginHorizontal: 10,
                    }}>
                        <Paragraph><Trans id={'createItemListDescription'} /></Paragraph>
                        <View style={{
                            marginHorizontal: -10,
                            marginTop: 10,
                        }}>
                            <DefaultInput
                                mandatory
                                onChange={_createItemList}
                                placeholder={t`createItemListPlaceholder`}
                            />
                        </View>
                        <View
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                            }}
                        >
                            <WebButton
                                size={250}
                                disabled={isCreationBlocked}
                                onPress={async () => {
                                    await createItemList(authState.user.facilityId, newItemListName);
                                    setOverlayVisible(false);
                                }}
                            ><Trans id={'create'} /></WebButton>
                        </View>
                    </View>
                </Overlay>
                <MenuList
                    list={availableItemListsInLocation}
                    onListElementPressed={menuListCallback}
                    activeIndex={itemListIndex}
                    header={t`itemList`}
                    // footer={t`createItemList`}
                    // footerCallback={() => setOverlayVisible(true)}
                />
                <View style={{flex: 1}} />
            </View>
            <View style={[styles.articleView]}>
                {itemListId === undefined ?
                    <View style={[
                        styles.container,
                        styles.empty
                    ]}>
                        <View>
                            <Paragraph style={styles.title}><Trans id={'noEntriesAvailable'}/></Paragraph>
                        </View>
                    </View>
                    :
                    <ItemList
                        activeItemListId={itemListId}
                    />
                }
            </View>
            {hasActiveRequest &&
            <View style={styles.loading}>
                <ActivityIndicator
                    size={"large"}
                />
            </View>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 18,
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        opacity: 0.9,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center'
    },
    empty: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    articleView: {
        flex: 1,
        marginLeft: 7,
        paddingLeft: 7,
    },
    listContainer: {
        width: 250,
    },
});

export default ItemListScreen;