import AsyncStorage from '@react-native-async-storage/async-storage';

AsyncStorage.getJSONItem = async function(item:string) {
    try {
        const jsonValue = await AsyncStorage.getItem(item);
        return jsonValue !== null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        console.error(e);
        return null;
    }
}

export default AsyncStorage;