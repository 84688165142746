// https://logrocket.com/blog/pure-functional-components/
// https://medium.com/@shovonroy/how-to-properly-navigate-with-react-navigation-e38fe3bf7381
import React from 'react';
import HomeScreen from '../screens/HomeScreen';
import MEITScreen from '../screens/MEITScreen';
import MyDevicesScreen from '../screens/MyDevicesScreen';
import MyDeviceScreen from '../screens/MyDeviceScreen';
import CatalogScreen from '../screens/CatalogScreen';
import ItemListScreen from '../screens/ItemListScreen';
import CartScreen from '../screens/CartScreen';
import SettingsScreen from '../screens/SettingsScreen';
import NewsScreen from '../screens/NewsScreen';
import ArticleScreen from '../screens/ArticleScreen';
import DocumentsScreen from '../screens/DocumentsScreen';
import StorageScreen from '../screens/StorageScreen';
import {
    Platform,
    View
} from 'react-native';
import Header from '../components/Header';
import UserManagementScreen from '../screens/UserManagmentScreen';
import OfferBookScreen from '../screens/OfferBookScreen';
import { PageTitleText } from '../components/StyledText';
import { Trans } from '@lingui/react';
import UITestScreen from '../screens/UITestScreen';
import HeaderLeft from '../components/HeaderLeft';
import CustomSidebarMenu from '../components/CustomSidebarMenu';
import { createDrawerNavigator } from '@react-navigation/drawer';

const headerMode = 'screen';

const headerTitle = (navigation, title) => {
    return {
        headerShown: true,
        headerTitle: () => {
            return (
                <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <HeaderLeft navigation={navigation} />
                    <View style={{
                        display: 'flex',
                        flexGrow: 1,
                        height: 52,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <View>
                            <PageTitleText><Trans id={title}/></PageTitleText>
                        </View>
                    </View>
                    <Header/>
                </View>
            );
        }
    }
}

const Drawer = createDrawerNavigator();

const Mainmenu = () => {
    return (
        <Drawer.Navigator
            drawerContent={(props) => <CustomSidebarMenu {...props} />}
            minSwipeDistance={20}
            drawerType={Platform.OS === 'web' ? 'permanent' : 'front'}
            drawerLockMode={'unlocked'}
            drawerPosition={'left'}
            hideStatusBar={false}
            overlayColor={'rgba(5, 44, 73, 0.9)'}
            headerMode={headerMode}
            screenOptions={() => ({
                headerLeft: () => null,
                headerTintColor: '#333',
                headerStyle: {
                    backgroundColor: 'lightgray'
                },
            })}
        >
            <Drawer.Screen
                name={'Home'}
                component={HomeScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'welcomeBack'),
                })}
            />
            <Drawer.Screen
                name={'ItemList'}
                component={ItemListScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'itemList'),
                })}
            />
            <Drawer.Screen
                name={'Users'}
                component={UserManagementScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'userManagement'),
                })}
            />
            <Drawer.Screen
                name={'MasterData'}
                component={SettingsScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'baseData'),
                })}
            />
            <Drawer.Screen
                name={'News'}
                component={NewsScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'news'),
                })}
            />
            <Drawer.Screen
                name={'OfferBook'}
                component={OfferBookScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'offerBook'),
                })}
            />
            <Drawer.Screen
                name={'Storage'}
                component={StorageScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'storage'),
                })}
            />
            <Drawer.Screen
                name={'UITest'}
                component={UITestScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, 'UI TEST SCREEN'),
                })}
            />
            <Drawer.Screen
                name={'MPG'}
                component={DocumentsScreen}
                initialParams={{
                    subscreen: 'MPG'
                }}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'MPG'),
                })}
            />
            <Drawer.Screen
                name={'DGUV'}
                component={DocumentsScreen}
                initialParams={{
                    subscreen: 'DGUV'
                }}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'DGUV'),
                })}
            />
            <Drawer.Screen
                name={'MyDevices'}
                component={MyDevicesScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'myDevices'),
                })}
            />
            <Drawer.Screen
                name={'MyDevice'}
                component={MyDeviceScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'myDevices'),
                })}
            />
            {/* TODO check for deep linking: path: 'MyDevice/:itemId',*/}
            <Drawer.Screen
                name={'Catalog'}
                component={CatalogScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'shop'),
                })}
            />
            <Drawer.Screen
                name={'Article'}
                component={ArticleScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'shop'),
                })}
            />
            <Drawer.Screen
                name={'Cart'}
                component={CartScreen}
                options={({navigation}) => ({
                    ...headerTitle(navigation, /*i18n*/'cart'),
                })}
            />
            <Drawer.Screen
                name={'MEIT'}
                component={MEITScreen}
                initialParams={{
                    subscreen: MEITScreen.PAGE_ABOUT_US
                }}
                options={({navigation}) => ({
                    ...headerTitle(navigation, 'MEIT'),
                })}
            />
            <Drawer.Screen
                name={'ContactUs'}
                component={MEITScreen}
                initialParams={{
                    subscreen: MEITScreen.PAGE_CONTACT
                }}
                options={({navigation}) => ({
                    ...headerTitle(navigation, 'MEIT'),
                })}
            />
            <Drawer.Screen
                name={'Team'}
                component={MEITScreen}
                initialParams={{
                    subscreen: MEITScreen.PAGE_ABOUT_US
                }}
                options={({navigation}) => ({
                    ...headerTitle(navigation, 'MEIT'),
                })}
            />
            <Drawer.Screen
                name={'Imprint'}
                component={MEITScreen}
                initialParams={{
                    subscreen: MEITScreen.PAGE_IMPRINT
                }}
                options={({navigation}) => ({
                    ...headerTitle(navigation, 'MEIT'),
                })}
            />
            <Drawer.Screen
                name={'PrivacyPolicy'}
                component={MEITScreen}
                initialParams={{
                    subscreen: MEITScreen.PAGE_PRIVACY_POLICY
                }}
                options={({navigation}) => ({
                    ...headerTitle(navigation, 'MEIT'),
                })}
            />
        </Drawer.Navigator>
    );
}

export default Mainmenu;