import React from 'react';
import {
    StyleSheet,
    View
} from 'react-native';
import { Avatar } from 'react-native-elements';
import { Paragraph } from '../components/StyledText';

const AvatarWithName = ({
    children,
    source, name, location, right,
    onPress, onEditPress,
    showEditButton, editButton,
    ...props
}) => {
    return (
        <View
            style={[
                props.style,
                {
                    display: 'flex',
                    flexDirection: right ? 'row' : 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginHorizontal: 15,
                    marginVertical: 5,
                }
            ]}
        >
            <Avatar
                source={source}
                size={80}
                imageProps={StyleSheet.flatten(styles.image)}
                activeOpacity={0.7}
                onPress={onPress}
                overlayContainerStyle={{backgroundColor: 'transparent'}}
            >
                {showEditButton &&
                <Avatar.Accessory
                    {...editButton}
                    size={32}
                    style={{
                        backgroundColor: '#fff',
                        height: 25,
                        width: 25,
                        bottom: -2,
                        right: -2,
                    }}
                    onPress={onEditPress}
                />
                }
            </Avatar>
            <View style={styles.quickLoginTextContainer}>
                <Paragraph
                    style={[
                        styles.quickLoginText,
                        {
                            textAlign: (right ? 'left' : 'center'),
                        }
                    ]}
                >{name}</Paragraph>
                <Paragraph
                    style={[
                        styles.quickLoginText,
                        {
                            textAlign: (right ? 'left' : 'center'),
                        }
                    ]}
                >{location?.name}</Paragraph>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    quickLoginTextContainer: {
        marginTop: 3,
        paddingHorizontal: 6,
        width: 200,
    },
    quickLoginText: {
        fontSize: 16,
        lineHeight: 18,
    },
    image: {
        borderRadius: 20,
        height: 80,
        width: 80,
        borderColor: 'black',
        borderWidth: 1,
        backgroundColor: 'white'
    },
});

export default AvatarWithName;