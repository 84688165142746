import React, {
    useRef
} from 'react';
import DefaultInput from './DefaultInput';
import * as Styles from '../styles';

const NumericInput = React.forwardRef((
    {
        children,
        min, max, value, onChange, onBlur,
        label, placeholder, editable, disabled,
        inputStyle, inputContainerStyle,
        ...props
    },
    ref
) => {
    const self = (ref !== null ? ref : useRef());

    return (
        <DefaultInput
            ref={self}
            value={/^(0|[1-9]\d*)$/.test(value) ? value.toString() : ''}
            min={min}
            max={max}
            keyboardType='numeric'
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder ?? '1'}
            label={label ?? ''}
            editable={editable}
            disabled={disabled}
            inputContainerStyle={inputContainerStyle}
            inputStyle={[
                inputStyle,
                Styles.Layouts.input,
            ]}
        />
    )
});

NumericInput.defaultProps = {
    editable: true,
    disabled: false,
}
export default NumericInput;