import React, {
    useMemo,
} from 'react';
import {
    View,
    StyleSheet,
    Image,
} from 'react-native';
import { Paragraph } from './StyledText';
import * as Styles from '../styles/index';
import useItemList from './useItemList';
import TabbableInput from '../ui/TabbableInput';
import * as colors from '../styles/colors';
import AuthImage from './AuthImage';

const ItemListEntry = (propsParam) => {
    const { item, activeItemListId, ...props } = propsParam;
    const {
        updateProductOnItemList,
    } = useItemList();

    const updateProductOnItemListCallback = (item, quantity) => {
        updateProductOnItemList(activeItemListId, item, quantity);
    };

    const RenderSeparator = () => {
        return (
            <View style={{
                height: '80%',
                width: 2,
                backgroundColor: '#fff',
                marginVertical: 0,
                marginHorizontal: 0,
            }}/>
        )
    };

    const tabbableInput = useMemo(() => {
        return (
            <TabbableInput
                value={item.quantity}
                item={item}
                min={0}
                onChangeText={updateProductOnItemListCallback}
                inputContainerStyle={{width: 75, borderBottomWidth: 0}}
            />
        )
    }, [item]);

    const deviceImages = item.images.filter(image => !image.isPrivate);

    const imagePath =
        deviceImages[0]?.thumbnail ? deviceImages[0].thumbnail :
            deviceImages[0]?.name ? deviceImages[0].path + '/' + deviceImages[0].name : undefined;

    return (
        <View
            style={[
                Styles.Layouts.row,
                {
                    alignItems: 'center',
                    backgroundColor: colors.grayLight,
                    marginVertical: 5,
                }
            ]}
        >
            <View>
            {/* @TODO re-enable to allow users to remove entries on the item list   */}
            {/*<View style={styles.action}>*/}
                {/*<Icon*/}
                {/*    name={'minus-circle'}*/}
                {/*    type={'font-awesome'}*/}
                {/*    color={colors.maintenanceOverDueColor}*/}
                {/*    underlayColor={'#ccc'}*/}
                {/*    size={30}*/}
                {/*    onPress={() => {*/}
                {/* @TODO check response must be bool for web! */}
                {/*        let response = removeProductFromItemList(activeItemListId, item);*/}

                {/*        if (response.status === 400) {*/}
                {/*            Toast.showError(t`error`);*/}
                {/*        }*/}
                {/*    }}*/}
                {/*/>*/}
            </View>
            {/*<RenderSeparator/>*/}
            <View style={{
                flex: 3,
                flexDirection: 'row',
                paddingHorizontal: 10,
                alignItems: 'center',
            }}>
                {
                    (imagePath === undefined ?
                            <Image
                                style={styles.gridItemImage}
                                source={require('../assets/images/placeholder.jpg')}
                            />
                            :
                            <AuthImage
                                style={styles.gridItemImage}
                                source={'/file' + imagePath}
                                loadingIndicatorSource={require('../assets/images/placeholder.jpg')}
                                resizeMode={'contain'}
                            />
                    )
                }
                <View style={styles.textContainer}>
                    <Paragraph style={styles.name}>{item.name}</Paragraph>
                    <Paragraph style={styles.manufacturer}>{item.manufacturer.name}</Paragraph>
                </View>
            </View>
            <RenderSeparator/>
            <Paragraph style={styles.productionUnit}>{item.unit.unitQuantity} {item.unit.shortcut}</Paragraph>
            <RenderSeparator/>
            <View style={styles.quantity}>
                {/*<TabbableInput*/}
                {/*    value={item.quantity}*/}
                {/*    item={item}*/}
                {/*    onChangeText={updateProductOnItemListCallback}*/}
                {/*    inputContainerStyle={{width: 75, borderBottomWidth: 0}}*/}
                {/*/>*/}
                {tabbableInput}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    textContainer: {
        flexDirection: 'column',
        flex: 1,
    },
    gridItemImage: {
        width: 75,
        height: 50,
        paddingHorizontal: 10,
        backgroundColor: '#fff',
    },
    manufacturer: {
        flex: 3,
        textAlign: 'left',
        color: '#333',
        paddingHorizontal: 10,
    },
    name: {
        flex: 3,
        textAlign: 'left',
        marginBottom: 0,
        paddingHorizontal: 10,
    },
    quantity: {
        width: 205,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    productionUnit: {
        flexGrow: 0,
        width: 150,
        textAlign: 'right',
        paddingHorizontal: 10,
    },
    factor: {
        flexGrow: 0,
        width: 70,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    automatedOrder: {
        flex: 1,
        width: 50,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    minimalStock: {
        flex: 1,
        width: 50,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    action: {
        flexGrow: 0,
        width: 48,
        alignContent: 'center',
        paddingHorizontal: 10,
    },
});

export default ItemListEntry;