import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    ScrollView,
    StyleSheet,
    View,
    ImageBackground,
    Animated,
    Easing,
    TouchableHighlight,
    FlatList,
} from 'react-native';
import AsyncStorage from '../interfaces/AsyncStorage';
import * as Styles from '../styles/index';
import Requester from '../components/Requester';
import {
    t,
    Trans,
} from '@lingui/macro';
import WebButton from '../ui/WebButton'
import EmailInput from '../ui/EmailInput';
import PasswordInput from '../ui/PasswordInput';
import * as SVG from '../assets/images/svg/svg';
import Comb from '../components/Comb';
import DefaultCheckBox from '../ui/DefaultCheckBox';
import AvatarWithName from '../ui/AvatarWithName';
import useAuth from '../components/useAuth';
import { ButtonGroup } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import Toast from '../interfaces/Toast';
import { Paragraph } from '../components/StyledText';
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp,
} from '../libs/react-native-responsive-screen';

export const getProfileImageFallback = (user) => {
    let profileImageFallback;

    switch (user.profileImageFallback) {
        case 'Doc1.jpg':
            profileImageFallback = require('../assets/images/profiles/Doc1.jpg');
            break;

        case 'Doc2.jpg':
            profileImageFallback = require('../assets/images/profiles/Doc2.jpg');
            break;

        case 'Doc3.jpg':
            profileImageFallback = require('../assets/images/profiles/Doc3.jpg');
            break;

        case 'DocF1.jpg':
            profileImageFallback = require('../assets/images/profiles/DocF1.jpg');
            break;

        case 'NurseF1.jpg':
            profileImageFallback = require('../assets/images/profiles/NurseF1.jpg');
            break;

        case 'NurseF2.jpg':
            profileImageFallback = require('../assets/images/profiles/NurseF2.jpg');
            break;

        case 'NurseM1.jpg':
            profileImageFallback = require('../assets/images/profiles/NurseM1.jpg');
            break;

        case 'OP1.jpg':
            profileImageFallback = require('../assets/images/profiles/OP1.jpg');
            break;

        case 'Receptionist1.jpg':
            profileImageFallback = require('../assets/images/profiles/Receptionist1.jpg');
            break;

        case 'Sani1.jpg':
            profileImageFallback = require('../assets/images/profiles/Sani1.jpg');
            break;

        case 'Schwester_Sara.jpg':
            profileImageFallback = require('../assets/images/profiles/Schwester_Sara.jpg');
            break;

        case 'StationF1.jpg':
            profileImageFallback = require('../assets/images/profiles/StationF1.jpg');
            break;

        case 'StationM1.jpg':
            profileImageFallback = require('../assets/images/profiles/StationM1.jpg');
            break;

        default:
            profileImageFallback = require('../assets/images/profiles/Doc1.jpg');
    }

    return profileImageFallback;
}

const LoginScreen = (props) => {
    const {
        ERROR_NETWORK,
        ERROR_CREDENTIALS,
        signIn,
    } = useAuth();
    const navigation = useNavigation();

    const [userQuickList, setUserQuickList]             = useState([]);
    const [isUserQuickListLoaded, userQuickListLoaded]  = useState(false);
    const [email, setEmail]                             = useState('');
    const [addToQuickLogin, setAddToQuickLogin]         = useState(true);
    const [password, setPassword]                       = useState('');
    const [errorMessage, setErrorMessage]               = useState('');
    const [isLoggingIn, setIsLoggingIn]                 = useState(false);

    const emailInput    = useRef();
    const passwordInput = useRef();

    useEffect(() => {
            let mounted = true;

            if (mounted && isLoggingIn) {
                showLoggingInAnimation();
            } else {
                rotation.stopAnimation();
            }

            return function cleanup() {
                mounted = false;
            }
        },
        [isLoggingIn]
    );

    let rotation = new Animated.Value(0);

    const rotate = rotation.interpolate({
        inputRange: [0, 360],
        outputRange: ['0deg', '360deg'],
    });

    const showLoggingInAnimation = () => {
        rotation.setValue(0);
        Animated.loop(
            Animated.timing(rotation, {
                toValue: 360,
                duration: 2000,
                easing: Easing.inOut(Easing.cubic),
                useNativeDriver: true,
            }),
            {
            }
        ).start();
    };

    const handleNetworkError = () => {
        setIsLoggingIn(false);
        Toast.showError(t`networkError`);
    };

    const removeUserFromQuickList = async (userId) => {
        const newUserQuickList = userQuickList.filter(user => {
            return user.userId !== userId
        });

        await AsyncStorage.setItem('userQuickList', JSON.stringify(newUserQuickList));
        await setUserQuickList(newUserQuickList);
    };

    const renderUserQuickList = (data) => {
        const user = data.item;

        return (
            <AvatarWithName
                name={user.name}
                source={user.profileImage === null
                    ? getProfileImageFallback(user)
                    : {uri: Requester.getImageURL(user.profileImage)}
                }
                onPress={() => {
                    setEmail(user.email);
                    setPassword('');
                    passwordInput.current.shake();
                    passwordInput.current.focus();
                }}
                onEditPress={() => {
                    removeUserFromQuickList(user.userId)
                }}
                showEditButton={true}
                editButton={{
                    name: 'minus-circle',
                    type: 'font-awesome',
                    color: '#f00',
                    underlayColor: '#fff'
                }}
            />
        )
    }

    if (!isUserQuickListLoaded) {
        AsyncStorage.getJSONItem('userQuickList').then((result) => {
            let _userQuickList;

            if (result === null || result.length === undefined) {
                _userQuickList = [];
            } else {
                _userQuickList = result;
            }

            setUserQuickList(_userQuickList);
            userQuickListLoaded(true);
        });
    }

    const _signInAsync = async () => {
        setErrorMessage();
        setIsLoggingIn(true);

        signIn(email, password)
            .then((data) => {
                // AppNavigator redirects on successful login
                setIsLoggingIn(false);
                navigation.navigate(
                    'Loading',
                    {
                        token: data.token,
                        addToQuickLogin: addToQuickLogin,
                    }
                );
                return null;
            })
            .catch((error) => {
                if (error === ERROR_NETWORK) {
                    handleNetworkError();
                }

                if (error === ERROR_CREDENTIALS) {
                    Toast.showError(t`authenticationFailed`);
                    setErrorMessage(t`authenticationFailed`);
                    passwordInput.current.shake();
                    passwordInput.current.focus();
                }

                setIsLoggingIn(false);
            });
    };

    function handleKeyUp(e) {
        e.which = e.which || e.keyCode;

        // If the key press is Enter
        if (e.which === 13) {
            switch (e.target.id) {
                case 'loginEmailInput':
                    passwordInput.current.focus();
                    break;

                case 'loginPasswordInput':
                    _signInAsync();
                    break;

                default:
                    break;
            }
        }
    }
    const userQuickListKeyExtractor = (user, index) => user.userId.toString();
    const initialScrollIndex        = userQuickList.length > 3 ? Math.floor(userQuickList.length / 2) : 0;

    return (
        <ScrollView>
            {/*<View*/}
            {/*    style={{*/}
            {/*        position: 'absolute',*/}
            {/*        top: 0,*/}
            {/*        right: 10,*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <ButtonGroup*/}
            {/*        buttonStyle={{backgroundColor: '#333', opacity: 0.7 }}*/}
            {/*        buttonContainerStyle={{}}*/}
            {/*        buttons={[*/}
            {/*            {*/}
            {/*                element: () => <SVG.FlagDE />*/}
            {/*            },*/}
            {/*            {*/}
            {/*                element: () => <SVG.FlagEN />*/}
            {/*            }*/}
            {/*        ]}*/}
            {/*        containerStyle={{ width: 80, height: 25 }}*/}
            {/*        disabled={[]}*/}
            {/*        disabledStyle={{}}*/}
            {/*        disabledTextStyle={{}}*/}
            {/*        disabledSelectedStyle={{}}*/}
            {/*        disabledSelectedTextStyle={{}}*/}
            {/*        innerBorderStyle={{}}*/}
            {/*        onPress={(index) => i18n.activate(index === 0 ? 'de' : 'en')}*/}
            {/*        selectedButtonStyle={{opacity: 1.0, backgroundColor: '#fff'}}*/}
            {/*        selectedIndex={i18n.locale === 'de' ? 0 : 1}*/}
            {/*        selectedTextStyle={{}}*/}
            {/*        textStyle={{}}*/}
            {/*        underlayColor={'#ccc'}*/}
            {/*    />*/}
            {/*</View>*/}
            <View style={{
                marginTop: 40
            }}>
                <ImageBackground
                    style={styles.background}
                    source={require('../assets/images/background.jpg')}
                    imageStyle={{opacity:0.02}}
                >
                    <Animated.View
                        style={[{
                            height: 'auto',
                            alignItems: 'center',
                            transform: [
                                { rotateY: rotate },
                                { perspective: 1000 } // without this line this Animation will not render on Android while working fine on iOS
                            ],
                        }]}
                    >
                        <Comb
                            atomic
                            scale={'80'}
                            item={{
                                icon: <SVG.IconMEIT/>,
                                scale: "80%",
                                backgroundColor: '#FFF',
                                opacity: 1,
                            }}
                        />
                    </Animated.View>
                    <View
                        style={{
                            width: wp(55),
                            alignSelf: 'center',
                            alignItems: 'center',
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        <FlatList
                            keyExtractor={userQuickListKeyExtractor}
                            data={userQuickList}
                            horizontal={true}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                            renderItem={renderUserQuickList}
                            persistentScrollbar={true}
                            initialScrollIndex={initialScrollIndex}
                            getItemLayout={(data, index) => {
                                const _width = 175;
                                return {
                                    length: _width,
                                    offset: _width * index,
                                    index
                                }
                            }}
                        />
                    </View>
                    <View
                        style={{
                            width: 400,
                            alignSelf: 'center',
                        }}
                    >
                        <EmailInput
                            nativeID={'loginEmailInput'}
                            ref={emailInput}
                            mandatory
                            email={email}
                            onChange={(_email) => setEmail(_email)}
                            onKeyPress={handleKeyUp}
                            noLabel
                        />
                        <PasswordInput
                            nativeID={'loginPasswordInput'}
                            ref={passwordInput}
                            mandatory
                            password={password}
                            onChange={(_password) => setPassword(_password)}
                            onKeyPress={handleKeyUp}
                            noLabel
                            errorMessage={errorMessage}
                        />
                        <DefaultCheckBox
                            title={t`addToQuickLoginOptIn`}
                            checked={addToQuickLogin}
                            onPress={() => setAddToQuickLogin(!addToQuickLogin)}
                        />
                        <View style={{
                            width: 380,
                            marginTop: 10,
                            alignSelf: 'center',
                        }}>
                            <WebButton
                                disabled={isLoggingIn || email.length === 0 || password.length === 0}
                                onPress={_signInAsync}
                            >{t`login`}</WebButton>
                            <TouchableHighlight
                                onPress={() => navigation.navigate('PasswordResetRequest')}
                                style={{alignItems: 'center'}}
                            >
                                <Paragraph style={styles.passwordForgotten}><Trans id={'forgotPassword'} /></Paragraph>
                            </TouchableHighlight>
                        </View>
                    </View>
                </ImageBackground>
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    background: {
        ...Styles.Layouts.backgroundImage,
    },
    passwordForgotten: {
        color: '#666',
    }
});

export default LoginScreen;