import React from 'react';
import {
    Text,
    View
} from 'react-native';
import * as Texts from '../styles/text';

export function MonoText(props) {
    return (
        <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />
    );
}

export function PageTitleText(props) {
    return (
        <View
            style={[
                {
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: 2,
                    paddingBottom: 2,
                }
            ]}
        >
            <Text
                style={[
                    Texts.pageTitle,
                    props.style,
                    {
                        flexGrow: 1,
                    }
                ]}
            >
                {props.children}
            </Text>
        </View>
    );
}

export function Subtitle(props) {
    return (
        <Text {...props} style={[Texts.subtitle, props.style]} />
    );
}

export function Paragraph(props) {
    return (
        <Text {...props} style={[Texts.content, props.style]} />
    );
}
