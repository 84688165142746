import React, {
    useContext,
} from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    TouchableHighlight
} from 'react-native';
import {
    Paragraph,
    Subtitle
} from './StyledText';
import Comb from './Comb';
import Requester from './Requester';
import { Trans } from '@lingui/react';
import * as colors from '../styles/colors';
import * as SVG from '../assets/images/svg/svg';
import AvatarWithName from '../ui/AvatarWithName';
// import { CartContext } from './CartContext'; //unused until side menu gets its entries back
import useAuth from './useAuth';
import { ButtonGroup } from 'react-native-elements';
import { AuthContext } from './AuthContext';
import useMenu from '../context/useMenu';
import { MenuContext } from '../context/MenuContext';

const CustomSidebarMenu = ({ navigation, ...props}) => {
    const [authState]   = useContext(AuthContext);
    const [menuContent] = useContext(MenuContext);
    // const [cartState]   = useContext(CartContext); //unused until side menu gets its entries back

    const { signOut }     = useAuth();
    const { getSideMenu } = useMenu();

    if (!authState.isUserLoaded) {
        return null;
    }

    if (!menuContent.isLoaded) {
        return null;
    }

    const items = getSideMenu();

    if (items === null) {
        return null;
    }

    return (
        <View style={styles.sideMenuContainer}>
            <View style={{width: '100%'}}>
                <AvatarWithName
                    style={styles.menuHead}
                    name={authState.user.name}
                    location={authState.user.location}
                    right
                    source={authState.user.profileImage === null
                        ? authState.user.profileImageFallback
                        : {uri: Requester.getImageURL(authState.user.profileImage)}
                    }
                    onEditPress={() => {
                        setIsProfileOverlayVisible(true)
                    }}
                    showEditButton={false}
                    editButton={{
                        name: 'mode-edit',
                        type: 'material',
                        color: '#fff',
                        underlayColor: '#bbb'
                    }}
                />
            </View>
            {/* @TODO for dev in at the moment, needs better UI */}
            {/*<ButtonGroup*/}
            {/*    buttonStyle={{backgroundColor: '#333', opacity: 0.7 }}*/}
            {/*    buttonContainerStyle={{}}*/}
            {/*    buttons={[*/}
            {/*        {*/}
            {/*            element: () => <SVG.FlagDE />*/}
            {/*        },*/}
            {/*        {*/}
            {/*            element: () => <SVG.FlagEN />*/}
            {/*        }*/}
            {/*    ]}*/}
            {/*    containerStyle={{ width: 80, height: 25 }}*/}
            {/*    disabled={[]}*/}
            {/*    disabledStyle={{}}*/}
            {/*    disabledTextStyle={{}}*/}
            {/*    disabledSelectedStyle={{}}*/}
            {/*    disabledSelectedTextStyle={{}}*/}
            {/*    innerBorderStyle={{}}*/}
            {/*    onPress={(index) => i18n.activate(index === 0 ? 'de' : 'en')}*/}
            {/*    selectedButtonStyle={{opacity: 1.0, backgroundColor: '#fff'}}*/}
            {/*    selectedIndex={i18n.locale === 'de' ? 0 : 1}*/}
            {/*    selectedTextStyle={{}}*/}
            {/*    textStyle={{}}*/}
            {/*    underlayColor={'#ccc'}*/}
            {/*/>*/}
            {/*Divider between Top Image and Sidebar Option*/}
            <View
                style={styles.menuSectionSpacer}
            />
            <ScrollView style={{ width: '100%' }}>
                {items.map((section, sectionKey) => {
                    return (
                        <View
                            key={sectionKey}
                        >
                            {section.groupText &&
                            <React.Fragment>
                                <View
                                    style={styles.menuSectionSpacer}
                                />
                                <View
                                    style={styles.menuHead}
                                >
                                    <Paragraph><Trans id={section.groupText} /></Paragraph>
                                </View>
                            </React.Fragment>
                            }
                            {section.entries.map((item, itemKey) => (
                                <View
                                    key={itemKey}
                                    style={styles.menuEntry}
                                >
                                    <Comb
                                        section={sectionKey}
                                        index={itemKey}
                                        item={item}
                                        style={{paddingLeft: 5}}
                                        underlayColor={'#eee'}
                                    />
                                </View>
                            ))}
                        </View>
                    );
                })}
                {/*Divider between Sidebar Option and log out*/}
                <View
                    style={styles.menuSectionSpacer}
                />
                <TouchableHighlight
                    style={styles.menuEntry}
                    underlayColor={'#eee'}
                    onPress={() => signOut()}
                >
                    <View
                        style={styles.logoutContainer}
                    >
                        <View
                            style={styles.logoutIconContainer}
                        >
                            <SVG.IconLogout/>
                        </View>
                        <Subtitle
                            style={styles.logoutText}
                        >
                            <Trans id={'logout'} />
                        </Subtitle>
                    </View>
                </TouchableHighlight>
            </ScrollView>
        </View>
    );
};

export default CustomSidebarMenu;

const styles = StyleSheet.create({
    sideMenuContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: 'lightgray', //colors.grayLight,
        alignItems: 'center',
        paddingTop: 15,
    },
    menuHead: {
        marginHorizontal: 15,
        marginVertical: 0,
        paddingLeft: 5,
        paddingVertical: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.grayNormal,
        backgroundColor: '#fff'
    },
    menuSectionSpacer: {
        width: '100%',
        height: 1,
        marginTop: 9,
    },
    menuEntry: {
        height: 44,
        marginHorizontal: 15,
        marginVertical: 2,
        paddingLeft: 0,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.grayNormal,
        backgroundColor: '#fff'
    },
    logoutContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent',
    },
    logoutIconContainer: {
        width: 40,
        height: 44,
        marginRight: 15,
        paddingLeft: 10,
    },
    logoutText: {
        fontSize: 15,
    }
});